$transistion: 0.3s;

$footer-font-size: 17px;
$footer-mobile-font-size: 14px;

$footer-label-font-size: 20px;
$footer-label--mobile-font-size: 16px;

$footer-bottom-bar-font-size: 12px;

$green04: $black01;
$link-hover-color: $black01;

// Spacing
$gutter-padding: 12px;

.Footer {
    font-size: $footer-font-size;
    width: 100%;

    a {
        text-decoration: none;
    }

    &__row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        margin-right: -$gutter-padding;
        margin-left: -$gutter-padding;

        @include for-tablet {
            display: block;
            margin: 0 0 2 * $gutter-padding;
        }
    }

    &__column {
        position: relative;
        padding-right: $gutter-padding;
        padding-left: $gutter-padding;

        @include for-tablet {
            width: 100%;
        }
    }

    &__logo-container {
        display: flex;
        margin: 60px 0;

        @include for-tablet {
            margin: 30px 0 15px;
        }
    }

    &__logo {
        max-width: 200px;

        @include for-tablet {
            max-width: 120px;
        }
    }

    &__contact {
        padding: 60px 0;

        &__details {
            &__heading {
                font-weight: $font-weight-bold;
                margin-bottom: $gutter-padding;

                a {
                    color: $black01;

                    &:hover {
                        color: $black01;
                        text-decoration: none;
                        border-bottom: 2px solid $orange01;
                        transform: translateX(-50%);
                    }
                }
            }

            &__email {
                a {
                    color: $black01;

                    &:hover {
                        color: $black01;
                        text-decoration: none;
                        border-bottom: 1px solid $orange01;
                        transform: translateX(-50%);
                    }
                }
            }

            &__phone {
                a {
                    color: $black01;

                    &:hover {
                        color: $black01;
                        text-decoration: none;
                        border-bottom: 1px solid $orange01;
                    }
                }
            }

            @include for-tablet {
                width: 50%;
                padding-right: $gutter-padding;
                word-break: break-word;
            }
        }

        &__social-media {
            &__signup {
                margin-top: 27px;
                margin-bottom: 18px;
                font-weight: $font-weight-bold;

                a {
                    color: $green01;

                    &:hover {
                        color: $green01;
                        text-decoration: none;
                        border-bottom: 2px solid $orange01;
                    }
                }

                @include for-tablet {
                    margin-top: 0;
                    margin-bottom: 11px;
                }
            }

            &__links {
                &--facebook {
                    width: 37px;
                    height: 37px;
                    display: inline-block;
                    background-color: $orange01;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-top: 27px;
                    position: relative;

                    &:after {
                        content: "";
                        background: url(#{$eCommerceMediaLibraryURL}/MF4S4) no-repeat center center;
                        display: inline-block;
                        height: 21px;
                        width: 11px;
                        background-size: 11px 21px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &--linkedin {
                    width: 37px;
                    height: 37px;
                    display: inline-block;
                    background-color: $orange01;
                    border-radius: 50%;
                    position: relative;

                    &:after {
                        content: "";
                        background: url(#{$eCommerceMediaLibraryURL}/MF4RM) no-repeat top left;
                        display: inline-block;
                        height: 18px;
                        width: 18px;
                        background-size: 18px 18px;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            @include for-tablet {
                width: 50%;
                padding-left: $gutter-padding;
            }
        }

        @include for-tablet {
            padding: 15px 0 25px;
            display: flex;
        }
    }

    &__top-container {
        background-color: $white01;
        padding: 15px 2 * $gutter-padding 0;

        @include for-tablet {
            padding: 15px $gutter-padding 0;
        }
    }

    &__link-group {
        padding: 60px 0;

        &__label {
            font-size: $footer-label-font-size;
            font-weight: $font-weight-bold;
            position: relative;

            span {
                border-bottom: 2px solid $orange01;
                padding-bottom: 15px;
                display: inline-block;

                @include for-tablet {
                    border: 0;
                    padding-bottom: 8px;
                }
            }

            &__dropdown {
                vertical-align: middle;
                position: absolute;
                right: 0;
                top: 0;
                transition: $transistion all;
                transition-delay: $transistion;
            }

            @include for-tablet {
                border-bottom: 2px solid $orange01;
                font-size: $footer-label--mobile-font-size;

                &:hover {
                    cursor: pointer;
                }

                &.active .Footer__link-group__label__dropdown {
                    transform: rotate(180deg);
                }
            }
        }

        @include for-tablet {
            padding: 16px 0;
        }
    }

    &__link-list {
        overflow: hidden;
        transition: $transistion all;
        transition-delay: $transistion;

        a {
            color: $green04;
            border: 0;
            border-color: $orange01;
            border-bottom-width: 0;

            &:hover {
                color: $link-hover-color;
                text-decoration: none;
                border-bottom: 1px solid $orange01;
                transform: translateX(-50%);
            }
        }

        ul {
            padding: 16px 0 0 0;
            margin: 0;

            li {
                padding: 4px 0;
                list-style: none;
                margin: 0;

                a {
                    color: $green04;
                }

                @include for-tablet {
                    padding: 8px 0;
                }
            }
        }

        @include for-tablet {
            &--collapsed {
                opacity: 0;
            }
        }
    }

    &__bottom-container {
        background-color: $green01;
        color: $white01;
        font-size: $footer-bottom-bar-font-size;
        line-height: 1;
        padding: 14px 0;

        a {
            color: $white01;
        }

        @include for-tablet {
            padding: 14px 24px;
        }
    }

    &__bottom-bar {
        display: flex;

        .pr-5{
            text-align: center;
        }
        
        ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0;
            padding-left: 10px;

            li {
                margin: 0;
                padding: 0 15px;
                list-style: none;
                display: inline;

                @include for-tablet {
                    &:first-child {
                        padding-left: 0;
                    }
                }

                @include for-mobile {
                    &:last-child {
                        border-left: none;
                        padding-left: 0;
                        padding-top: 5px;
                    }
                }
            }

            a:hover {
                text-decoration: none;
                color: $white01;
            }

            @include for-tablet {
                padding-left: 0;
                margin-top: 10px;
            }
        }

        @include for-tablet {
            display: block;
        }
    }

    @include for-tablet {
        font-size: $footer-mobile-font-size;
    }
}

.farmlands-top {
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 9000;
    font-size: 60px;
    border-radius: 50%;
    color: $green01;
    opacity: 0.9;
    box-shadow: 0px 5px 5px $gray05;
    border: 2px solid $white01;
    background-color: $white01;
    width: 64px;
    transition: all 0.4s;

    &--show {
        opacity: 1;
    }

    &--hide {
        visibility: hidden;
        opacity: 0;
    }

    @include for-tablet {
        right: 5%;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}
