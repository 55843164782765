$max-height-m: 732px;
$max-height-l: 772px;
$gutter-width: 40px;
$carousel-padding-left: 20px;
$thumbnail-height: 100px;
$thumbnail-flipper-height: 30px;
$carousel-tooltip-padding:4px;
$carousel-indicators-border: 1px;

//style presets
:root {
    --msv-media-gallery-bg: #{$black01};

    // Flipper
    --msv-media-gallery-thumbnail-flipper-bg: $white01;
    --msv-media-gallery-thumbnail-flipper-border: #{$white01};
    --msv-media-gallery-thumbnail-flipper-font-color: #{$gray03};
 }

.ms-media-gallery {
    display: inline-flex;

    .ms-media-gallery__thumbnails-container {
        display: none;
        order: 0;
        flex-grow: 0;
    }

    .ms-media-gallery__carousel {
        align-self: flex-start;
        order: 1;
        flex-grow: 1;
    }

    .msc-ss-carousel-vert__flipper {
        background: var(--msv-media-gallery-thumbnail-flipper-bg);
        border: 1px solid var(--msv-media-gallery-thumbnail-flipper-border);
        border-radius: 0;
        color: var(--msv-media-gallery-thumbnail-flipper-font-color);
        position: relative;
        height: $thumbnail-flipper-height;
        width: 100%;
        border-radius: 0;
        top: auto;
        left: auto;
        transform: none;
        flex: 0 1 $thumbnail-flipper-height;

        &.disabled {
            display: none;
        }
    }

    .msc-ss-carousel-slide {
        width: 100%;
        height: auto;
    }

    .msc-ss-carousel-vert__flipper--next {
        bottom: auto;
    }

    .msc-ss-carousel__flipper {
        display: none;
    }

    .msc-tooltip-inner {
        background-color:  var(--msv-media-gallery-bg);
        color: $white01;
        padding: $carousel-tooltip-padding $carousel-tooltip-padding;
    }

    .msc-carousel__indicators li{
        &.active{
            background-color: $gray08;
        }
        border: $carousel-indicators-border solid $gray08;
        background-color: $white01;
    }
}

@include for-desktop {
    .ms-media-gallery {
        .msc-carousel__item {
            max-height: $max-height-m;
        }

        .ms-media-gallery__carousel {
            margin-left: $carousel-padding-left;
            width: 80%;
        }

        .ms-media-gallery__thumbnails-container {
            display: flex;
            width: 20%;
        }
    
        .ms-media-gallery__thumbnail-item {
            border: 1.5px solid $gray09;
            border-radius: 2px;
            height: 100%;
            display: flex;
            justify-content: center;
        }

        .ms-media-gallery__thumbnail-item-active {
            border: 1.5px solid $green01;
            border-radius: 2px;
            height: 100%;
        }
    } 
}

.ms-containerZoom {   

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        cursor: zoom-in;
    } 

    &__result {
        overflow: hidden; 
        position: fixed; 
        background-color: #fff; 
        background-repeat: no-repeat;
        z-index: 1140; 
        box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px; 
        border: 1px solid rgb(148, 148, 148);
        width: 580px;
        height: 772px;
        right: 10px;

        @include for-tablet {
            display: none;
        }

        @media (max-width: $site-max-width) {
            width: 390px;
            height: 400px;    
        }
    }

    &__image {
        border: none;
        left: 0;
        max-width: none;
        max-height: none;
        position: absolute;
        top: 0;
    }

    &__zoom-lens {
        position: absolute; 
        cursor: zoom-out;
        width: 200px;
        height: 180px;
        background-position: 0px 0px;
        top: -200px;
        right: -200px;
        float: right;
        overflow: hidden;
        zoom: 1;
        background-color: white;
        border: 1px solid #000;          
        opacity: 0;
        
        &__opacity {
            opacity: 0.4;
        }

        @include for-tablet {
            display: none;
        }
    }   
}

.ms-inline-zoom {
	float: left;
	overflow: hidden;
	cursor: zoom-in;
	display: block;
    position: relative;
    
    &.zoomed {
        cursor: zoom-out;
    }
    
    &__zoomedImg {
        position: absolute;		
        top: 0;
        left: 0;
        opacity: 0;
        border: none;
        max-width: none;
        max-height: none;
        width: 0;
        height: 0;

        @include for-tablet {
            display: none;
            opacity: 0;
        }
    }
}
