$msv-order-summary-padding: 38px 52px;
$msv-order-summary-padding-mobile: 38px 26px;
$msv-order-summary-primary-bg-color: $gray06;
$msv-order-summary-heading-font-size: 20px;
$msv-order-summary-heading-font-weight: 700;
$msv-order-summary-heading-line-height: 22px;
$msv-order-summary-heading-margin-bottom: 16px;
$msv-order-summary-label-font-size: 14px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

.msc-order-summary {
  color: $black01;

  &-wrapper {
    background-color: $msv-order-summary-primary-bg-color;
    margin: 0 auto;
    min-width: 460px;
    padding: $msv-order-summary-padding-mobile;

    @include for-desktop {
      align-items: center;
      display: flex;
      flex: 0 0 368px;
      flex-direction: column;
      padding: $msv-order-summary-padding;
    }

    @include for-tablet {
      width: 100%;
      min-width: auto;
    }
  }

  &__heading {
    color: $green01;
    font-size: $msv-order-summary-heading-font-size;
    font-weight: $msv-order-summary-heading-font-weight;
    line-height: $msv-order-summary-heading-line-height;
    margin-bottom: $msv-order-summary-heading-margin-bottom;
    width: 100%;
  }

  &__items {
    border-radius: 2px;
    color: $gray03;
    font-size: $msv-order-summary-label-font-size;
    line-height: $msv-order-summary-label-line-height;
    width: 100%;

    .msc-alert-danger {
      padding: 20px;
    }

    .msc-promo-code {
      &-heading {
        font-weight: $msv-order-summary-heading-font-weight;
      }

      &__group {
        display: flex;
        padding: 10px 0;
      }

      &__input-box {
        border: none;
        flex: auto;
        margin-right: 20px;
        padding-left: 8px;
      }

      &__apply-btn {
        color: $green01;
        text-transform: capitalize;
        border: 1px solid $green01;
        background-color: transparent;
        border-radius: 20px;
      }
    }
  }

  &__line {

    &-net-price,
    &-tax-amount,
    &-shipping,
    &-loyalty,
    &-gift-card,
    &-total-discounts {
      padding: 10px 0 10px;
    }

    &-total {
      border-top: 1px solid $gray03;
      font-weight: 700;
      margin-top: 20px;
      padding: 10px 0 10px;
    }
  }

  &__value {
    float: right;
    text-align: right;
  }

  &__account {
    color: $green01;
    font-weight: 600;
    width: 100%;
    padding: 15px 0;
  }

  &__switch-account {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}

.msc-order-summary-wrapper {
  margin-bottom: 25px;
}