@import "./visibility";

body {
    margin: 0;
}

.container {
    margin: 0 auto;
    max-width: $site-max-width;
    width: 100%;

    &-heading {
        font-family: $msv-primary-font-family;
        font-size: 30px;
        font-weight: normal;
        display: block;
        line-height: 33px;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 34px;
        color: $green01;
        text-align: center;
    }
}

.mobile {
    .ms-header__account-selector {
        //background-color: $green01;
    }

    .Header__side-btn-ctn--ecom .ms-header__account-info {
        margin-right: 6px;
    }

    .secondary-dropdown-nav {
        .ms-header__account-name {
            display: block;
        }
        .ms-header__profile-button {
            display: none;
        }
        .ms-header__account-info-content {
            display: none !important;
        }
    }

    #main {
        margin-top: 63px;
    }
}
