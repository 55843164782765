$search-result-container-nav-height: 20px;
$search-result-container-nav-margin-bottom: 20px;
$search-result-container-nav-margin-top: 32px;
$search-result-container-nav-title-margin: 0px;
$search-result-container-nav-font-size: 13px;
$search-result-container-refine-product-margin-bottom: 80px;
$search-result-container-refiner-section-padding-right: 40px;
$search-result-container-refiner-section-width: 25%;
$search-result-container-refiner-button-submenu-height: 40px;
$search-result-container-refiner-button-submenu-padding-left: 0px;
$search-result-container-refiner-button-submenu-padding-right: 0px;
$search-result-container-refiner-submenu-list-group-border-color: $gray02;
$search-result-container-refiner-submenu-list-group-margin-bottom: 0px;
$search-result-container-refiner-submenu-list-group-margin-left: 0px;
$search-result-container-refiner-submenu-item-label-padding-left: 12px;
$search-result-container-refiner-submenu-list-group-item-padding-left: 0px;
$search-result-container-refiner-list-group-item-border: 1px solid rgba(0, 0, 0, 0);
$search-result-container-refiner-list-group-item-outline-offset: 2px;
$search-result-container-refiner-list-group-item-padding: 21px 12px 0px 12px;
$search-result-container-refiner-slider-horizontal-background-color: $gray05;
$search-result-container-refiner-slider-horizontal-height: 4px;
$search-result-container-refiner-slider-padding: 0px;
$search-result-container-refiner-slider-track-background-color: $black01;
$search-result-container-refiner-slider--track-top: 0px;
$search-result-container-refiner-slider-thumb-background: $black01;
$search-result-container-refiner-slider-thumb-background-size: 12px 12px;
$search-result-container-refiner-slider-thumb-border-radius: 6px;
$search-result-container-refiner-slider-thumb-height: 12px;
$search-result-container-refiner-slider-thumb-outline-offset: 2px;
$search-result-container-refiner-slider-thumb-top: 50%;
$search-result-container-refiner-slider-thumb-width: 12px;
$search-result-container-refiner-slider-thumb-before-bottom: 0px;
$search-result-container-refiner-slider-thumb-before-left: 0px;
$search-result-container-refiner-slider-thumb-before-right: 0px;
$search-result-container-refiner-slider-thumb-before-top: 0px;
$search-result-container-refiner-slider-labels-item-font-size: 14px;
$search-result-container-refiner-slider-labels-item-margin-top: 25px;
$search-result-container-refiner-slider-labels-item-padding-bottom: 20px;
$search-result-container-pagination-padding-left: 0px;
$search-result-container-product-section-width: 75%;
$search-result-container-sort-by-category-padding-left: 8px;
$search-result-container-sort-by-category-right: 0px;
$search-result-container-sort-by-category-dropdown-label-margin-right: 5px;
$search-result-container-sort-by-category-dropdown-height: 32px;
$search-result-container-products-margin-bottom: 80px;
$search-result-container-products-margin-top: 32px;
$search-result-container-product-search-result-item-grid-column-gap: 20px;
$search-result-container-product-search-result-item-width: 249px;
$search-result-container-product-search-result-item-padding: 0px 0px 33px 0px;
$search-result-container-product-search-item-title-margin-bottom: 4px;
$search-result-container-product-search-item-title-margin-top: 4px;
$search-result-container-product-search-item-rating-margin-top: 4px;
$search-result-container-page-item-previous-padding-right: 10px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-page-item-next-padding-right: 4px;
$search-result-container-margin-top-margin-top-mobile: 24px;
$search-result-container-pagination-margin-bottom-mobile: 16px;
$search-result-container-products-margin-bottom-mobile: 40px;
$search-result-container-choice-summary-padding-inline-start: 0px;
$search-result-container-choice-summary-margin-top: 20px;
$search-result-container-choice-summary-margin-bottom: 20px;
$search-result-container-choice-summary-background-color: $orange01;
$search-result-container-choice-summary-border: 1px $gray03 solid;
$search-result-container-choice-summary-margin-right: 12px;
$search-result-container-choice-summary-border-radius: 11px;
$search-result-container-choice-summary-padding-left: 8px;
$search-result-container-choice-summary-outline-offset: 2px;
$search-result-container-choice-summary-glypg-margin-left: 8px;
$search-result-container-choice-summary-glypg-margin-right: 8px;
$search-result-modal-header-close-button-padding: 20px;
$search-result-modal-header-close-button-margin: -20px -20px -20px auto;
$search-result-modal-body-height: 450px;
$search-result-modal-body-padding: 40px 60px 10px 60px;
$search-result-modal-footer-button-background-color: $black01;
$search-result-modal-footer-button-border-color: $black01;
$search-result-modal-footer-button-padding: 12px 20px;
$search-result-modal-footer-button-min-width: 160px;
$search-result-container-refiner-submenu-list-group-border-width: 1px;
$search-result-modal-refiner-section-padding-right: 0px;
$search-result-container-padding-left-mobile: 16px;
$search-result-container-padding-right-mobile: 16px;
$search-result-container-sort-by-category-top-mobile: 0px;
$product-search-result_link__item-outline-width: 1px;
$product-search-result_link__item-outline-style: dashed;
$product-search-result_link__item-outline-offset: 2px;
$search-result-prev-next-padding: 0 4px;
$product-item-tile-height: 400px;

.ms-search-result-container__category-nav-section {
    display: flex;
    height: $search-result-container-nav-height;
    justify-content: space-between;
    margin-bottom: $search-result-container-nav-margin-bottom;
    margin-top: $search-result-container-nav-margin-top;

    .ms-search-result-container__category-hierarchy {
        font-size: $search-result-container-nav-font-size;
    }

    .ms-search-result-container__title {
        color: $black01;
        margin: $search-result-container-nav-title-margin;
    }
}

.ms-search-result__collection-title-count {
    color: $gray08;
    font-size: $search-result-container-nav-font-size;
    font-weight: normal;

    &--product-section {
        margin-bottom: 0;

        @include for-desktop {
            display: none;
        }
    }
}

.ms-search-result-wrapper-title-choice-summary,
.ms-search-result-wrapper-sort-by-category,
.ms-search-result-container__title {
    display: inline-block;
}

.ms-search-result-container {
    .reviews-list-sort-by {
        color: $gray08;
        font-size: 14px;
        font-weight: 600;
        padding: 0 40px;
        text-transform: uppercase;
    }

    .msc-product {
        $opacity: 0.1;
        border: 1px solid rgba($black01, $opacity);
        overflow: hidden;

        &__details {
            padding: 0 20px 10px;
        }

        &:hover {
            $horizontal: 0px;
            $vertical: 2px;
            $blur-radius: 10px;
            $spread-radius: 5px;

            -webkit-box-shadow: $horizontal $vertical $blur-radius $spread-radius rgba($black01, $opacity);
            -moz-box-shadow: $horizontal $vertical $blur-radius $spread-radius rgba($black01, $opacity);
            box-shadow: $horizontal $vertical $blur-radius $spread-radius rgba($black01, $opacity);
        }
    }
}

.ms-search-result-container__refine-product-section {
    display: inline-flex;
    margin-bottom: $search-result-container-refine-product-margin-bottom;
    width: 100%;
}

// refiner section
.ms-search-result-container__refiner-section {
    display: block;
    padding-right: $search-result-container-refiner-section-padding-right;
    width: $search-result-container-refiner-section-width;

    .collapse:not(.show) {
        display: none;
    }

    button.ms-refine-submenu__toggle_collapsed,
    .ms-refine-submenu__toggle_expanded {
        color: $black01;
        background: transparent;
        display: flex;
        height: $search-result-container-refiner-button-submenu-height;
        justify-content: space-between;
        padding-left: $search-result-container-refiner-button-submenu-padding-left;
        padding-right: $search-result-container-refiner-button-submenu-padding-right;
        width: 100%;
        margin-top: 2px;
    }

    .ms-refine-submenu.list-group {
        border-bottom-style: solid;
        border-bottom-width: $search-result-container-refiner-submenu-list-group-border-width;
        border-color: $search-result-container-refiner-submenu-list-group-border-color;
        display: flex;
        flex-direction: column;
        margin-bottom: $search-result-container-refiner-submenu-list-group-margin-bottom;
        padding-left: $search-result-container-refiner-submenu-list-group-margin-left;

        &:first-child {
            border-top-style: solid;
            border-top-width: $search-result-container-refiner-submenu-list-group-border-width;
            border-color: $search-result-container-refiner-submenu-list-group-border-color;
        }

        .ms-refine-submenu-item {
            padding-left: $search-result-container-refiner-submenu-list-group-item-padding-left;
            margin-bottom: 2px;

            .msc-rating {
                display: inline-block;
            }
        }

        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            display: block;
            border: 1px solid transparent;

            span.ms-refine-submenu-item__label {
                padding-left: $search-result-container-refiner-submenu-item-label-padding-left;
                vertical-align: top;
            }
        }
    }

    .list-group-item {
        border: $search-result-container-refiner-list-group-item-border;
        display: block;
        outline-offset: $search-result-container-refiner-list-group-item-outline-offset;
        padding: $search-result-container-refiner-list-group-item-padding;
        position: relative;
    }

    .slider-horizontal {
        background-color: $search-result-container-refiner-slider-horizontal-background-color;
        height: $search-result-container-refiner-slider-horizontal-height;
    }

    .slider {
        box-shadow: inset 0 0 2px;
        cursor: pointer;
        display: block;
        padding: $search-result-container-refiner-slider-padding;
        position: relative;
        touch-action: none;

        .slider__track {
            background-color: $search-result-container-refiner-slider-track-background-color;
            height: 100%;
            position: absolute;
            top: $search-result-container-refiner-slider--track-top;
        }

        .slider__thumb {
            background: $search-result-container-refiner-slider-thumb-background;
            background-size: $search-result-container-refiner-slider-thumb-background-size;
            border-radius: $search-result-container-refiner-slider-thumb-border-radius;
            display: inline-block;
            height: $search-result-container-refiner-slider-thumb-height;
            outline-offset: $search-result-container-refiner-slider-thumb-outline-offset;
            position: absolute;
            top: $search-result-container-refiner-slider-thumb-top;
            transform: translate3d(-50%, -50%, 0);
            width: $search-result-container-refiner-slider-thumb-width;
        }

        .slider__thumb:before {
            bottom: $search-result-container-refiner-slider-thumb-before-bottom;
            content: "";
            display: block;
            left: $search-result-container-refiner-slider-thumb-before-left;
            outline-offset: calc(0px + 0.0625rem);
            position: absolute;
            right: $search-result-container-refiner-slider-thumb-before-right;
            top: $search-result-container-refiner-slider-thumb-before-top;
        }

        .slider__labels-item {
            color: $black01;
            cursor: pointer;
            display: inline-block;
            font-size: $search-result-container-refiner-slider-labels-item-font-size;
            margin-top: $search-result-container-refiner-slider-labels-item-margin-top;
            padding-bottom: $search-result-container-refiner-slider-labels-item-padding-bottom;
        }

        .slider__labels-item.start {
            float: left;
        }

        .slider__labels-item.end {
            float: right;
        }
    }
}

// product section
.ms-search-result-container__product-section {
    width: $search-result-container-product-section-width;

    &__title {
        color: $black01;
    }
}

.msc-choice-summary {
    list-style-type: none;
    padding-inline-start: $search-result-container-choice-summary-padding-inline-start;
    margin-top: $search-result-container-choice-summary-margin-top;
    margin-bottom: 10px;

    .msc-choice-summary__list {
        display: inline-block;
    }

    .msc-choice-summary__list-item {
        display: inline-block;
    }

    .msc-choice-summary__item {
        display: flex;
        background-color: $search-result-container-choice-summary-background-color;
        color: $white01;
        margin-right: $search-result-container-choice-summary-margin-right;
        border-radius: $search-result-container-choice-summary-border-radius;
        padding-left: $search-result-container-choice-summary-padding-left;
        outline-offset: $search-result-container-choice-summary-outline-offset;
        position: relative;

        @include for-mobile {
            margin-bottom: 12px;
        }
    }

    .msc-choice-summary__glyph {
        margin-left: $search-result-container-choice-summary-glypg-margin-left;
        margin-right: $search-result-container-choice-summary-glypg-margin-right;
        vertical-align: text-top;

        &:after {
            @include msv-icon();
            content: $msv-Cancel;
        }
    }

    .msc-choice-summary__clear-all:after {
        display: none;
    }
}

.ms-search-result-container__Sort-by-category {
    .msc-l-dropdown {
        color: $gray08;
        background: transparent;
        border: 1px solid $white03;
        height: $search-result-container-sort-by-category-dropdown-height;

        &__label {
            margin-right: $search-result-container-sort-by-category-dropdown-label-margin-right;
        }

        // Assumes ratings option is last in select list.
        // Removing it as ratings have not yet been implemented.
        &__option:last-child {
            display: none;
        }
    }
}

.msc-product__text {
    display: none;
}

.ms-search-result-container__Products {
    margin-bottom: $search-result-container-products-margin-bottom;
    margin-top: $search-result-container-products-margin-top;

    .list-unstyled {
        display: grid;
        grid-column-gap: $search-result-container-product-search-result-item-grid-column-gap;
        grid-template-columns: repeat(
            auto-fill,
            minmax($search-result-container-product-search-result-item-width, 1fr)
        );

    }
    .msc-product {
        min-height: $product-item-tile-height;
    }
}

.ms-search-result-container__no-results-message {
    display: inline-block;
    max-width: 700px;

    .error-text {
        @include font-content-m();
        margin-bottom: 40px;

        &--bold {
            font-weight: 600;
        }
    }

    .contact-us-blurb {
        margin-bottom: 20px;
    }

    .contact-us-link {
        @include primary-button();
        font-size: 13px;
        font-weight: 600;
        margin: 15px auto;
        padding: 6px 16px;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.ms-product-search-result__item {
    padding: $search-result-container-product-search-result-item-padding;

    .msc-product {
        display: block;

        &:hover {
            text-decoration: none;
        }
    }

    .msc-product__image img {
        width: 100%;
    }
}

.ms-product-search-result__item a {
    .msc-product__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: $search-result-container-product-search-item-title-margin-bottom;
        margin-top: $search-result-container-product-search-item-title-margin-top;
    }

    .msc-rating {
        display: block;
        margin-top: $search-result-container-product-search-item-rating-margin-top;

        &__star {
            color: $green01;
        }

        &__empty-star {
            color: transparent;
            -webkit-text-stroke: 1px $black01;
        }
    }

    .msc-price {
        &__actual,
        &__strikethrough,
        &__savings {
            font-size: 11px;
            font-weight: 600;
        }

        &__actual,
        &__strikethrough {
            color: $gray10;
        }

        &__savings {
            color: $orange01;
        }
    }

    &:focus {
        @include basic-outline-offset(
            $product-search-result_link__item-outline-width,
            $product-search-result_link__item-outline-style,
            $black01,
            $product-search-result_link__item-outline-offset
        );
        .msc-product__title {
            text-decoration: underline;
        }
    }
}

.ms-search-result-container__pagination-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .msc-page-item a,
    .msc-page-item {
        color: $gray10;
        padding: 0 5px;
    }

    .previous.msc-page-item,
    .next.msc-page-item {
        color: $black01;
        flex-grow: unset;
    }

    .prev-text,
    .next-text {
        display: none;
    }
}

.msc-pagination {
    display: flex;
    list-style: none;
    padding-left: $search-result-container-pagination-padding-left;
    width: 100%;
}

.previous.msc-page-item {
    display: list-item;
    flex-grow: 1;
    padding-right: $search-result-container-page-item-previous-padding-right;
    text-align: left;

    .msc-pagination__prev {
        padding-left: 4px;
        display: inline-block;
    }

    .msc-page-link {
        color: $black01;
        &:before {
            @include msv-icon();
            content: "";
        }

        .ms-search-result__pagination-left {
            @include add-icon($msv-ChevronLeft, before);
        }
    }
}

.previous.msc-page-item,
.next.msc-page-item {
    &.disabled {
        visibility: hidden;
    }
}

.msc-page-item {
    padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
    display: list-item;
    flex-grow: 1;
    padding-right: $search-result-container-page-item-next-padding-right;
    text-align: right;

    .msc-pagination__next {
        padding-right: 4px;
        display: inline-block;
    }

    .msc-page-link {
        color: $black01;
        padding-right: 4px;

        &:after {
            @include msv-icon();
            content: "";
        }

        .ms-search-result__pagination-right {
            @include add-icon($msv-ChevronRight, after);
        }
    }
}

.active {
    text-decoration: underline;
}

.prev-text {
    padding: $search-result-prev-next-padding;
}
.next-text {
    padding: $search-result-prev-next-padding;
}

@include for-tablet {
    .ms-search-result-container {
        margin-top: $search-result-container-margin-top-margin-top-mobile;

        .ms-search-result-container__category-hierarchy {
            display: none;
        }

        .msc-button {
            background: none;
            border-style: none;
            color: $gray10;
            float: left;
            height: 36px;
            font-weight: bold;
        }

        .msc-button:hover {
            text-decoration: underline;
        }

        .ms-product-search-result__item {
            display: inline-block;
            vertical-align: top;
        }
    }

    .search-filter-wrapper{
     display: flex;
     align-items: center;
    }

    .ms-search-result-container__pagination {
        margin-bottom: $search-result-container-pagination-margin-bottom-mobile;
    }

    .ms-search-result-container__Products {
        margin-bottom: $search-result-container-products-margin-bottom-mobile;
    }

    .prev-text {
        display: none;
    }

    .next-text {
        display: none;
    }
}

// modal
.msc-search-result-modal {
    .msc-modal__content {
        padding: 20px;
    }

    .msc-review-modal-body {
        height: $search-result-modal-body-height;
        overflow-y: auto;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        @include primary-button();
        font-size: 13px;
        font-weight: 600;
        min-width: $search-result-modal-footer-button-min-width;
        padding: 6px 16px;
        text-transform: uppercase;
    }

    .ms-search-result-container__refiner-section {
        display: block;
        padding-right: $search-result-modal-refiner-section-padding-right;
        width: 100%;
    }
}

@include for-tablet {
    .ms-search-result-container__Sort-by-category {
        margin-bottom: 10px;
        position: relative;
        top: $search-result-container-sort-by-category-top-mobile;
    }

    .ms-search-result-container {
        padding-left: $search-result-container-padding-left-mobile;
        padding-right: $search-result-container-padding-right-mobile;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        width: 100%;
    }
}
