@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/common";

.fl-stacked-images {
  @include for-tablet {
    .row {
      justify-content: space-around;
      .col-md {
        width:auto;
      }
    }
  }

  @include for-sm-mobile {
    .row {
      justify-content: space-between;
      .col-md {
        width:48%;
      }
    }
  }
}
