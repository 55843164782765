$msv-hero-content-block-details-padding-m: 60px;

.hero {
    &.ms-content-block {
        &[data-m-layout='full-width'] {
            .ms-content-block__cta {
                padding: 0;                
            }
            .ms-content-block__details {
                .msc-cta__primary {
                    margin: 0;                   
                }
            }
        }
    }

    .ms-content-block__cta {
    .msc-cta__primary {
      width: 100%;
      text-align: center;
      padding: 8px 24px;
    }
  }
}

@include for-tablet  {
    .hero {
        .ms-content-block {
            &__details {
                background-color: transparent;
                padding: $msv-hero-content-block-details-padding-m;
                position: absolute;
                top: 25%;
                right: 15%;
                left: 15%;
                text-decoration: none;
            }
        }
    }
}