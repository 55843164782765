$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-danger-background-color: $red01;
$msv-alert-font-family: $msv-primary-font-family;
$msv-alert-font-weight: $msv-font-weight-bold;
$msv-alert-font-size: 16px;
$msv-alert-line-height: 21px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;
$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 40px;
$msv-address-input-border-color: $white02;
$msv-address-input-font-size: 13px;
$msv-address-input-line-height: 26px;
$msv-address-button-save-margin-right: 10px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-border-color: $gray05;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $gray05;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right: 0px;
$msv-account-management-address-list-primary-list-button-edit-background-color: transparent;
$msv-account-management-address-list-primary-list-button-edit-border-color: transparent;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-checkout-input-dimensions: 18px;

.msc-address-form {
  &__item {
    margin-bottom: $msv-address-form-item-margin-bottom;
  }

  &__item-county,
  &__item-postbox,
  &__item-threeletterisoregionname {
    display: none;
  }

  &__item-checkbox {
    display: flex;
  }

  &__input-checkbox {
    min-width: $msv-checkout-input-dimensions;
    min-height: $msv-checkout-input-dimensions;
    margin-right: 10px;
  }

  &__street-suggestions {
    background-color: $white01;
    border: 1px solid $white02;
    border-top: none;
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow-y: scroll;
    width: 100%;

    &-item {
      cursor: pointer;
      padding: 5px;

      &:hover {
        background-color: $gray06;
      }
    }

    &-feedback {
      padding: 5px;
    }
  }

  &__label {
    color: $gray08;
    display: block;
    font-size: $msv-address-input-font-size;
    line-height: $msv-address-input-line-height;
    text-transform: uppercase;

    &[for*="addressname"]::after,
    &[for*="addressstreet"]::after,
    &[for*="addresscity"]::after,
    &[for*="addresszipcode"]::after,
    &[for*="addressthreeletterisoregionname"]::after,
    &[for*="addressstate"]::after {
      content: "*";
      padding-left: 4px;
      color: #f86c1d;
    }
  }

  &__input-text {
    padding: 6px 8px;
  }

  &__input,
  &__dropdown {
    color: $gray08;
    display: block;
    width: 100%;
    height: $msv-address-input-min-height;
    border: 1px solid $msv-address-input-border-color;
    box-sizing: border-box;
    border-radius: 7px;
    font-size: $msv-address-input-font-size;
    line-height: $msv-address-input-line-height;
    font-weight: normal;
    padding-left: 25px;
  }

  &__input:read-only {
    background-color: $gray07;
  }

  &__alert {
    display: block;
  }

  &__button-save {
    @include primary-button($orange01, $white01, $orange01);
    border-radius: 17px;
    font-size: 13px;
    margin-right: $msv-address-button-save-margin-right;
    padding: 0 20px;
    text-transform: uppercase;
  }

  &__button-cancel {
    @include secondary-button($white01, $green01, $green01);
    border-radius: 17px;
    font-size: 13px;
    margin-right: $msv-address-button-save-margin-right;
    padding: 0 20px;
    text-transform: uppercase;
  }

  .address-form__item-invalid &__alert {
    font-family: $msv-alert-font-family;
    font-weight: $msv-font-weight-bold;
    font-size: $msv-alert-font-size;
    line-height: $msv-alert-line-height;
    text-transform: none;
    border-radius: $msv-alert-border-radius;
    position: relative;
    padding: $msv-alert-padding;
    margin-bottom: $msv-alert-margin-bottom;
    border: $msv-alert-border;
    color: $white01;
    background-color: $msv-alert-danger-background-color;
    border-color: $msv-alert-danger-background-color;
    margin-top: $msv-alert-line-margin-top;
    &:before {
      margin-right: $msv-alert-icon-margin;
      @include msv-icon();
      content: $msv-IncidentTriangle;
    }
  }
}

// Below nested rules remove extra asterisk for address management module.
// Avoids having to clone that module.
.ms-account-management-address {
  .msc-address-form__label {
    &[for*="addressname"]::after,
    &[for*="addressstreet"]::after,
    &[for*="addresscity"]::after,
    &[for*="addresszipcode"]::after,
    &[for*="addressthreeletterisoregionname"]::after,
    &[for*="addressstate"]::after {
      content: "";
    }
  }
}

.msc-address-detail {
  color: $gray08;

  &__item {
    margin-right: $msv-address-detail-item-margin-right;

    &.msc-address-detail__item-empty {
      display: none;
    }
  }

  &__item-newline {
    margin-right: 0px;

    &:after {
      content: "\A";
      white-space: pre;
    }
  }

  &__item-name {
    margin-bottom: 16px;
  }

  &__item-address-detail_Phone {
    margin-top: 16px;
    display: block;
  }

  &__item-phone {
    @include add-icon($msv-Phone);

    &-label {
      @include visually-hidden();
    }

    &:before {
      color: $black01;
      margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
    }
  }
}

.msc-address-select {
  &__item {
    display: flex;
    align-items: center;
    margin-top: $msv-address-select-item-margin-top;
    margin-bottom: $msv-address-select-item-margin-bottom;
  }

  &__input {
    @include form-input-checkbox();
  }

  &__button-add {
    @include primary-button();
  }
}

.ms-account-management-address {
  margin-bottom: $msv-account-management-address-margin-bottom;

  .ms-address-list {
    margin-bottom: $msv-account-management-address-margin-bottom;
  }

  .msc-address-list {
    &__heading {
      @include font-content-xl();
      color: $green01;
      font-family: $msv-primary-font-family;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 28px;
    }

    &__add-empty {
      margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
    }

    &__button-add {
      @include primary-button($orange01, $white01, $orange01);
      border-radius: 17px;
      font-size: 13px;
      padding: 0 20px;
      text-transform: uppercase;
    }

    &__primary {
      border-top: 1px solid $msv-account-management-address-list-primary-border-color;
      margin-top: $msv-account-management-address-list-primary-margin-top;
      padding: 20px 0px;
    }

    &__primary-heading {
      @include font-content-m($msv-font-weight-bold);
      color: $green01;
      font-family: $msv-primary-font-family;
      margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;
    }

    &__primary-list {
      padding: 20px 0px;
      border-bottom: 1px solid $msv-account-management-address-list-primary-list-border-color;

      &::nth-child(2) {
        padding-top: 0px;
      }

      &::last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .msc-address-detail {
        &__item {
          margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
        }

        &__item-newline {
          margin-right: $msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right;

          &::after {
            content: "\A";
            white-space: pre;
          }
        }

        &__item-phone {
          &::before {
            color: $green01;
          }
        }
      }
    }

    &__button-primary {
      @include primary-button();
      background-color: $orange01;
      border-radius: 17px;
      padding: 5px 10px;
      margin-right: 12px;
      margin-top: $msv-account-management-address-list-button-primary-margin-top;
      min-height: 32px;
      height: auto;
      cursor: pointer;
    }

    &__button-edit,
    &__button-remove {
      @include font-content-m();
      background-color: $msv-account-management-address-list-primary-list-button-edit-background-color;
      border: 1px $msv-account-management-address-list-primary-list-button-edit-border-color;
      border-radius: 0px;
      color: $black02;
      cursor: pointer;
      font-family: $msv-primary-font-family;
      font-weight: 600;
      margin-top: 10px;
      padding: 6px 12px 6px 0px;
      text-decoration: underline;
      text-transform: none;
    }

    &__primary-list:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
