$msv-alert-success-color: $green01;
$msv-alert-success-background-color: $white01;
$msv-alert-danger-color: $red01;
$msv-alert-danger-background-color: $red01;
$msv-alert-close-button-right: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: .75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;
$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;
$msv-alert-line-margin-top: 8px;

.msc-alert {
    @include font-content-m(bold);
    border: $msv-alert-border;
    border-radius: $msv-alert-border-radius;
    margin-bottom: $msv-alert-margin-bottom;
    padding: $msv-alert-padding;
    position: relative;
    text-transform: none;

    &:before {
        color: $black01;
        position: absolute;
        right: $msv-alert-close-button-right;
    }

    .msi-exclamation-triangle {
        margin-right: $msv-alert-icon-margin;
        @include add-icon($msv-alert-icon, before)
    }

    .msc-alert__line {
        margin-top: $msv-alert-line-margin-top;
    }

    .close {
        @include add-icon($msv-x-shape);
    }
}

.msc-alert-success {
    color: $msv-alert-success-color;
    background-color: $msv-alert-success-background-color;
    border-color: $msv-alert-success-color;
    border-radius: 5px;
}

.msc-alert-danger {
    color: $white01;
    background-color: $msv-alert-danger-background-color;
    border-color: $msv-alert-danger-color;
}

.msc-alert-noborder {
    background-color: transparent;
    color: $msv-alert-danger-color;
    border-color: transparent;
    margin-bottom: 0;
    padding: 0;
    max-width: 160px;
}