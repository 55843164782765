$green01: #00553d;
$green02: #1a6650;
$green03: #017654;
$green04: #00834e;
$green05: #008000;
$green06: #00e376;

$blue01: #2266e3;

$red01: #a80000;
$red02: #ff0000;

$orange01: #f8971d;
$orange02: #f08080;
$orange03: #fdf0e4;
$orange04: #f86c1d;

$gray01: #1d1d1d;
$gray02: #323130;
$gray03: #6e726e;
$gray04: #a19f9d;
$gray05: #c8c6c4;
$gray06: #f3f2f1;
$gray07: #e6e6e6;
$gray08: #6a6a6a;
$gray09: #c4c4c4;
$gray10: #939598;
$gray11: #eeeeee;

$brown01: #a52a2a;

$white01: #ffffff;
$white02: #e0e0e0;
$white03: #f5f5f5;

$black01: #000000;
$black02: #01291e;

// Semantic colors
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
