$msv-btn-min-width: 165px;
$msv-btn-height: 44px;
$msv-btn-border-radius: 100px;
$msv-btn-side-padding: 48px;
$msv-btn-vertical-padding: 12px;
$msv-toggle-button-font-size: 36px;
$msv-toggle-button-padding: 0 5px;

$msv-link-height-l: 44px;
$msv-link-height-m: 24px;
$msv-link-height-s: 20px;

$msv-link-text-decoration-bottom-l: -20px;
$msv-link-text-decoration-bottom-m: -8px;
$msv-link-text-decoration-bottom-s: -8px;
$msv-link-text-decoration-bottom-hover-l: -12px;
$msv-link-text-decoration-bottom-hover-m: -4px;
$msv-link-text-decoration-bottom-hover-s: -4px;
$msv-link-text-decoration-height: 4px;

$msv-card-button-height: 48px;
$msv-card-button-width: 48px;

@mixin button-link($color: $blue01) {
  border-color: transparent;
  background-color: transparent;
  color: $color;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin primary-button($bg-color: $orange01, $color: $white01, $border: $orange01) {
  background-color: $bg-color;
  border: 1px solid $border;
  color: $color;
  border-radius: 20px;
  font-size: 13px;
  text-transform: uppercase;

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: #c8c6c4;
    background-color: #c8c6c4;
    color: #323130;
  }
  &:hover {
    text-decoration: none;
  }
}

@mixin secondary-button($bg-color: $white01, $color: $green01, $border: $green01) {
  background-color: $bg-color;
  border: 1px solid $border;
  color: $color;
  border-radius: 20px;
  font-size: 13px;
  text-transform: uppercase;

  &:disabled,
  &[disabled] {
    cursor: default;
    border-color: $gray05;
    background-color: $gray05;
    color: $gray02;
  }
  &:hover {
    text-decoration: none;
  }
}

@mixin button-common-decoration {
  align-items: center;
  border-radius: $msv-btn-border-radius;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  height: $msv-btn-height;
  justify-content: center;
  letter-spacing: 0.1em;
  min-width: $msv-btn-min-width;
  outline: none;
  overflow: hidden;
  padding: 12px $msv-btn-side-padding;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@mixin button-focus-decoration {
  content: " ";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: $msv-btn-border-radius;
}

@mixin secondary-button-dark() {
  @include msv-rubik-regular(
          $font-weight:$msv-font-weight-400,
          $font-style: normal,
          $font-size: $msv-font-size-s,
          $line-height: 20px);
  @include button-common-decoration();

  position: relative;
  background: #2c262d;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 100px;
  height:44px;

  &:hover {
    background-color: #201c21;
    box-shadow: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
  }

  &:focus {
    outline: none;
    background: var(--msv-secondary-btn-dark-focus-bg-color);

    &::before {
      @include button-focus-decoration();
      border: 1px dashed var(--msv-secondary-btn-dark-border-color);
    }
  }

  &:active {
    background-color: var(--msv-secondary-btn-dark-pressed-bg-color);
  }

  &:disabled,
  &[disabled] {
    background-color: var(--msv-secondary-btn-dark-disabled-bg-color);
    color: var(--msv-secondary-btn-dark-disabled-font-color);
  }
}

@mixin secondary-button-light() {
  @include msv-rubik-regular(
          $font-weight:$msv-font-weight-400,
          $font-style: normal,
          $font-size: $msv-font-size-s,
          $line-height: 20px);
  @include button-common-decoration();

  position: relative;
  background: var(--msv-secondary-btn-light-bg-color);
  border: 1px solid var(--msv-secondary-btn-light-border-color);
  color: var(--msv-secondary-btn-light-font-color);

  &:hover {
    background-color: var(--msv-secondary-btn-light-hover-bg-color);
    box-shadow: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
  }

  &:focus {
    outline: none;
    background: var(--msv-secondary-btn-light-focus-bg-color);

    &::before {
      @include button-focus-decoration();
      border: 1px dashed var(--msv-secondary-btn-light-border-color);
    }
  }

  &:active {
    background-color: var(--msv-secondary-btn-light-pressed-bg-color);
  }

  &:disabled,
  &[disabled] {
    background-color: var(--msv-secondary-btn-light-disabled-bg-color);
    border: none;
    color: var(--msv-secondary-btn-light-disabled-font-color);
  }
}

@mixin card-button-with-icon-only() {
  background-color: var(--msv-card-button-bg-color);
  border: none;
  border-radius: 4px;
  color: var(--msv-card-button-font-color);
  height: $msv-card-button-height;
  font-size: 0;
  opacity: 0.41;
  outline: none;
  width: $msv-card-button-width;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.41;
  }

  &:focus {
    border: 1px solid var(--msv-card-button-focus-border-color);
    box-sizing: border-box;
    opacity: 0.8;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.1;
  }

  &::after,
  &::before {
    font-size: 20px;
    margin: auto;
    vertical-align: middle;
  }
}
