.farmlands-delivery-options {
    &__content-block {
        color: $gray08;
        margin: 55px 55px 40px 55px;

        &__details {
            font-size: 16px;

            strong {
                @include font-content(600, 18px, 28px, none);
            }
        }
    }

    &__table {
        width: 100%;
        max-width: 700px;
        margin: 20px auto 0;
        border: 1px solid $gray08;
        border-collapse: separate;
        border-left: 0;
        border-radius: 4px;
        border-spacing: 0px;

        thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
            border-collapse: separate;
        }
        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
        }
        th, td {
            padding: 5px 4px 6px 4px; 
            text-align: left;
            vertical-align: top;
            border-left: 1px solid $gray08;    
        }
        td {
            border-top: 1px solid $gray08;    
        }
        thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
            border-radius: 4px 0 0 0;
        }
        thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
            border-radius: 0 0 0 4px;
        }
        &-header, th {
            color: $green01;
        }
        th {
            @include font-content(600, 18px, 28px, none);
        }
    }
}