.ms-tab {
    &-header-section {
        margin: 5px 5px 20px 5px;

        &__heading {
            @include font-content-xl();
        }
    }
    
    @include for-desktop {
        &-header-section {
            display: flex;
            margin: 5px 5px 20px 5px;
        }
    }

    &-tabs-section {
        border-bottom: 1.3px solid lightgray;
        display: flex;
        font-family: $msv-primary-font-family;
        font-weight: 600;

        &-li {
            cursor: pointer;
            padding: 10px;
            margin-right: 10px;
            position: relative;
            display: flex;
            width: 200px;
            justify-content: center;

            :hover {
                text-decoration: none;
            }

            &[aria-selected="true"] {
                color: $green01;

                ::before {
                    background-color: $orange01;
                }
            }

            ::before {
                background-color: transparent;
                bottom: -1px;
                content: "";
                height: 2px;
                left: 8px;
                position: absolute;
                right: 8px;
                transition: background-color .267s cubic-bezier(.1,.25,.75,.9);
            }
        } 
        li:last-of-type {
            margin-right: 0;
        }
    }

    &-content-section {
        margin-top: 20px;
    }
    
    &-item-content__content {
        &[aria-hidden="true"] {
            display: none;
        }
    }
}  