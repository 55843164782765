$buybox-font-size: 16px;
$buybox-content-items-padding-top: 20px;
$buybox-content-items-padding-top-small: 12px;
$buybox-margin-top: 36px;
$buybox-quantity-width:40px;
$buybox-find-in-store-description-padding-bottom:7px;
$buybox-product-title-line-height:32px;
$buybox-quantity-label-line-height:20px;
$msv-buybox-text-line-height: 21px;
$buybox-label-margin-bottom: 5px;
$buybox-title-margin-bottom: 5px;
$buybox-dropdown-height:32px;
$buybox-ratings-margin-top:10px;
$gutter-width: 40px;
$media-gallery-width: 50%;
$content-width: 50%;
$msv-dialog-width: 400px;
$msv-dialog-button-margin: 15px auto;
$msv-dialog-button-padding: 6px 16px 7px;
$msv-dialog-button-width: 100%;

//style presets
:root {
  //title
  --msv-buybox-title-font-size: var(--msv-body-font-size-xl);

  --msv-buybox-btn-wishlist-color: $blue01;

  //text
  --msv-buybox-text-font-size: var(--msv-body-font-size-m);
  --msv-buybox-text-font-color: $black01;

  // primary button
  --msv-buybox-primary-btn-bg: $orange01;
  --msv-buybox-primary-btn-font-color: $white01;
  --msv-buybox-primary-btn-border: $blue01;

   // secondary button
   --msv-buybox-secondary-btn-bg: $white01;
   --msv-buybox-secondary-btn-font-color: $black01;
   --msv-buybox-secondary-btn-border: $blue01;
}

.ms-buybox {
  margin-top: $buybox-margin-top;
  display: flex;
  flex-wrap: wrap;
  font-family: $msv-primary-font-family;

  // content styles
  &__content {
    color: $black01;
    display: flex;
    flex-direction: column;
    font-size: $buybox-font-size;
    position: relative;

    @include for-desktop {
      width: $content-width;
    }
  }

  &__product-title {
    font-family: $msv-primary-font-family;
    font-size: 35px;
    font-weight: 600;
    color: $black02;
    margin-bottom: $buybox-title-margin-bottom;
  }

  &__product-description {
    font-family: $msv-primary-font-family;
    color: var(--msv-buybox-text-font-color);
  }

  &__delivery-methods {
    font-weight: bold;

    &__heading {
      margin-bottom: 25px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;

      & > .item {
        flex: 50%;
        margin-bottom: 30px;
      }

      .item {
        width: 100%;
        display: flex;

        img {
          align-self: flex-start;
          height: 40px;
          object-fit: contain;
          margin-right: 20px;

          @include for-tablet {
            height: 27px;
            margin-right: 12px;
          }
        }

        p {
          flex: 1 1 auto;
          line-height: 35px;

          @include for-tablet {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .check-stock-button {
        border: none;
        color: $orange01;

        @include for-tablet {
          font-size: 14px;
        }

        &::after {
          content: '>';
          padding-left: 25px;
        }
      }
    }

  }

  &__promo-wrapper {
    margin-top: 25px;
  }

  &__divider {
    border-bottom: none;
    border-top: 1px solid $gray04;
    margin: 20px 0;
  }
  &__sku {
    width: 126px;
    height: 10px;
    margin: 10px 0 2px 0px;
    font-family: $msv-primary-font-family;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: $gray08;
  }

  &__short-description {
    color: $gray08;
    margin-top: 10px;
  }

  &__long-description {
    margin-top: 20px;
    color: $gray08;
    font-size: 16px;
  }

  &__price {
    font-family: $msv-primary-font-family;
    font-weight: 600;
    font-size: 18px;
    padding: 15px 0;
  }

  &__price-original {
    color: $gray08;

    &--strikethrough {
      text-decoration: line-through;
    }
  }

  &__price-discounted {
    color: $orange01;
    margin-left: 20px;
  }

  &__selection {
    display: flex;
    flex-flow: wrap;
  }

  &__configure {
    display: flex;
  }

  &__dropdown,
  &__quantity {
    padding-top: $buybox-content-items-padding-top;

    // override quantity style to be used in buy-box
    & .quantity {
      height: 35px;
    }

    & .quantity__controls {
      height: 35px;
    }

    & input {
      height: 33px;
    }
  }

  &__dropdown {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }

  &__find-in-store-heading,
  &__find-in-store-description {
    display: none;
  }

  &__ratings-link {
    display: inline-block;
  }

  .msc-price__actual,
  .ms-buybox__dropdown-quantity-label,
  .ms-buybox__product-quantity-label-heading {
    color: $gray08;
    font-weight: $msv-font-weight-600;
    line-height: $buybox-quantity-label-line-height;
  }
  .msc-price__actual {
    font-size: var(--msv-buybox-text-font-size);;
  }

  &__media-gallery {
    padding-right: $gutter-width;

    .msc-carousel__inner {
      border: 2px solid $gray06;
    }

    @include for-desktop {
      width: $media-gallery-width;
    }
  }

  .msc-no-ratings {
    margin-top: $buybox-ratings-margin-top;
  }
  .msc-dropdown__select {
    background-color: $white01;
    border-radius: 5px;
    border: 1px solid $black01;
    color: $gray08;
    height: 35px;
  }

  .msc-quantity-input {
    height: $buybox-dropdown-height;
    text-align: center;
    width: $buybox-quantity-width;
  }

  &__addto {
    display: flex;
  }

  &__add-to-cart-container {
    padding-top: $buybox-content-items-padding-top;
    width: 100%;
    margin-bottom: 70px;
    .msc-add-to-cart {
      @include primary-button($orange01, $white01, var(--msv-buybox-primary-btn-border));
      border-radius: 15px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      height: 34px;
      width: 90%;
    }

    .msc-alert-danger {
      margin-top: 10px;
    }
  }

  &__add-to-wishlist-container {
    padding-top: $buybox-content-items-padding-top-small;

    .msc-add-to-wishlist {
      &:after {
        color: var(--msv-buybox-btn-wishlist-color);
      }
      @include secondary-button();
      border-color: transparent;
      background-color: transparent;
      width: 50px;
      height: 50px;
    }
  }

  &__find-in-store-description {
    padding-bottom: $buybox-find-in-store-description-padding-bottom;
  }

  &__find-in-store {
    padding-top: 0;
    .ms-buybox__find-in-store-button {
      @include secondary-button(var(--msv-buybox-secondary-btn-bg), var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));

      &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
      }
    }
  }
  //mobile vp
  @include for-tablet {
    flex-flow: column;

    &__media-gallery {
      padding-right: 0;
    }
  }
  label {
    display: block;
    margin-bottom: $buybox-label-margin-bottom;
  }
}

.msc-modal {
  .msc-modal__dialog.ms-buybox__dialog {
      max-width: $msv-dialog-width;

      .msc-modal__content {
          max-width: $msv-dialog-width;
      }

      .ms-buybox__dialog__body {
          text-align: center;
      }

      .ms-buybox__dialog__image {
          margin-right: auto;
          margin-left: auto;
      }

      .ms-buybox__dialog__go-to-cart.msc-btn {
        @include primary-button(var(--msv-buybox-primary-btn-bg), var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
          display: block;
          margin: $msv-dialog-button-margin;
          padding: $msv-dialog-button-padding;
          width: $msv-dialog-button-width;

      }

      .ms-buybox__dialog__back-to-shopping.msc-btn {
        @include secondary-button(var(--msv-buybox-secondary-btn-bg), var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
          display: block;
          margin: $msv-dialog-button-margin;
          padding: $msv-dialog-button-padding;
          width: $msv-dialog-button-width;
      }
  }
}
