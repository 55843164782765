$quantity-height: 35px;

.quantity {
    border: 1px solid $black01;
    border-radius: 5px;
    display: flex;

    @include for-tablet{
        max-width: 130px;
    }

    &__input { 
        border: 0;
        color: $gray08;
        height: $quantity-height;
        text-align: center;
        width: 25px;

        // Rules for removing spinner buttons on input
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type="number"] {
            -moz-appearance: textfield;
        }
    }

    &__controls {
        align-items: center;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        height: $quantity-height;
        justify-content: center;
        width: 30px;
    }

    &__controls-glyph {
        font-size: 16px;
    }
}
