.category-img-row-grey {
  background-color: #f5f5f5;
  >div.row {
    max-width: 1440px;
    margin: 0 auto !important;
    padding-top: 0.75rem;
    padding-bottom: 1.5rem;

    @include for-mobile {
      padding-bottom: 0.75rem;
      > div.col-12 {
        width:48%;
      }
    }
  }
}
.category-img-row-title-grey {
  background-color: #f5f5f5;
  >div.row {
    max-width: 1440px;
    margin: 0 auto !important;
    padding-top: 1.75rem;
    padding-bottom: 1.5rem;

    @include for-mobile {
      h3.ms-content-block__title {
        text-align: center;
      }
    }
  }
}
.category-img-row-green {
  background-color: #e5eeeb;
  >div.row {
    max-width: 1440px;
    margin: 0 auto !important;
    padding-top: 0.75rem;
    padding-bottom: 1.5rem;

    @include for-mobile {
      padding-bottom: 0.75rem;
      > div.col-12 {
        width:48%;
      }
    }
  }
}
.category-img-row-title-green {
  background-color: #e5eeeb;
  >div.row {
    max-width: 1440px;
    margin: 0 auto !important;
    padding-top: 1.75rem;
    padding-bottom: 1.5rem;

    @include for-mobile {
      h3.ms-content-block__title {
        text-align: center;
      }
    }
  }
}
.category-subcat-img-square {
  >a {
    >div.ms-content-block__image {
      >picture {
        >img {
          width: 148px;
        }
      }
    }
  }
  >div.ms-content-block__details {
    >div.ms-content-block__text {
      >p {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
.category-subcat-img-rounded {
  >a {
    >div.ms-content-block__image {
      >picture {
        >img {
          border-radius: 50% !important;
          width: 148px;
        }
      }
    }
  }
  padding-top: 10px;
  padding-bottom: 10px;
}
.category-title {
  >div.ms-content-block__details {
    >h1 {
      font-weight: bold !important;
    }
  }

  @include for-mobile {
    padding-top:100px
  }
}
.category-banner-right {
  height: 100%;
  background-color: rgba(232, 223, 201, 0.5);
  >div.ms-content-block__details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;

    @include for-mobile {
      padding:20px 0 20px 0;
    }
  }
}
.category-hero-row {
  padding-top: 25px;
}
.category-yml-heading {
  padding-top: 55px;
}
.ms-product-collection .msc-ss-carousel .msc-ss-carousel__flipper {
  @include for-mobile {
    right:unset;
  }
}
.msc-ss-carousel__flipper--next {
  @include for-mobile {
    right:0px!important;
  }
}
