$msc-account-welcome-title-margin: 40px;
$msc-account-welcome-title-border-bottom-color: $gray03;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-tile-heading-icon-padding: 10px;
$msc-account-tile-heading-icon-size: 20px;
$msc-generic-tile-heading-margin-bottom: 5px;

.ms-account {
  &-welcome-tile {
    display: block;
    border-bottom: $msc-account-welcome-title-border-bottom-thickness $msc-account-welcome-title-border-bottom-style
      $msc-account-welcome-title-border-bottom-color;
    margin: $msc-account-welcome-title-margin 0;

    &__heading {
      @include font-content-xl(600);
      color: $green01;
    }

    @include for-desktop {
      &__heading {
        @include font-content-xxl(600);
      }
    }
  }

  &-generic-tile {
    &.order-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-DeliveryTruck, before);
        &:before {
          color: $green01;
          font-size: $msc-account-tile-heading-icon-size;
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }

    &.profile-tile {
      .ms-account-generic-tile__heading {
        @include add-icon($msv-Contact, before);
        &:before {
          color: $green01;
          font-size: $msc-account-tile-heading-icon-size;
          padding-right: $msc-account-tile-heading-icon-padding;
        }
      }
    }
  }

  &-wishlist-tile__heading {
    @include add-icon($msv-HeartFill, before);
    &:before {
      color: $green01;
      font-size: $msc-account-tile-heading-icon-size;
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-address-tile__heading {
    @include add-icon($msv-MapPin, before);
    &:before {
      color: $green01;
      font-size: $msc-account-tile-heading-icon-size;
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-loyalty-tile__heading {
    @include add-icon($msv-IdCard, before);
    &:before {
      color: $green01;
      font-size: $msc-account-tile-heading-icon-size;
      padding-right: $msc-account-tile-heading-icon-padding;
    }
  }

  &-generic-tile,
  &-wishlist-tile,
  &-address-tile,
  &-loyalty-tile {
    margin-bottom: $msc-account-welcome-title-margin;

    &__heading {
      @include font-content-l($msv-font-weight-bold);
      align-items: center;
      display: flex;
      margin-bottom: $msc-generic-tile-heading-margin-bottom;

      @include for-desktop {
        @include font-content-xl($msv-font-weight-bold);
      }
    }

    &__links {
      @include font-content-m-underline();
      margin-top: 10px;
    }

    &__link {
      font-weight: 600;
    }
  }
}
