@mixin for-tablet {
    @media (max-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin for-desktop {
    @media (min-width: $breakpoint-tablet + 1) {
        @content;
    }
}

@mixin for-mobile {
    @media (max-width: $breakpoint-mobile) {
        @content;
    }
}

@mixin for-sm-mobile {
    @media (max-width: $breakpoint-mobile-small) {
        @content;
    }
}

.show-for-tablet-only {
    @include for-desktop {
        display: none;
    }
}

.show-for-desktop {
    display: none;

    @include for-desktop {
        display: block;
    }
}
