.fl-checkbox {
    display: flex;
    flex-direction: column;
    margin-bottom: 19px;
    width: 100%;

    &__option {
        align-items: center;
        border: 1px solid $green01;
        border-radius: 5px;
        color: $green01;
        cursor: pointer;
        display: flex;
        font-size: 13px;
        font-weight: 600;
        height: 50px;
        padding: 16px 20px;
        width: 100%;

        &.isSelected {
            background-color: $green01;
            color: $white01;

            .fl-checkbox__radio-inner {
                background-color: $white01;
            }

            .fl-checkbox__radio-outer {
                border: solid 1px $white01;
            }
        }
    }

    &__input {
        display: none;
    }

    &__radio-outer {
        align-items: center;
        border: solid 1px $green01;
        border-radius: 9px;
        display: flex;
        height: 18px;
        justify-content: center;
        width: 18px;
        margin-right: 28px;
    }

    &__radio-inner {
        background-color: $green01;
        border-radius: 4px;
        display: none;
        height: 8px;
        width: 8px;
    }

    // Rule for 'toggling' visual radio element when actual adjacent input element is checked.
    &__input:checked + &__radio-outer > &__radio-inner {
        display: block;
    }
}
