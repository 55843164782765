.msc-price {
    &.discount-value {
        .msc-price__strikethrough {
            display: block;
            margin-right: 0px;;
        }
    }

    &__strikethrough {
        color: $gray01;
        margin-right: 5px;
        text-decoration: line-through;
    }
}