$msv-write-review-msc-btn-margin: 10px 0;

.ms-write-review {
    .msc-btn {
        @include secondary-button();
        margin: $msv-write-review-msc-btn-margin;
    }
}

.ms-review-modal {
    &-submit {
        @include primary-button();
        margin-right: 16px;
    }

    &-cancel {
        @include secondary-button();
    }

    .msc-rating {
        display: inline-block;
        margin-right: 8px;
    }

    &-text,
    &-title {
        width: 100%;
    }

    &-title-label {
        display: block;
    }

    &-text-label {
        display: block;
    }

    &-row {
        margin-bottom: 20px;
    }

    &-error {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .msc-link {
        text-decoration: underline;
    }
}