.fl-sizeguide-span {
  padding-top:5px;

  .fl-sizeguide-link {
    font-size:14px;
    color:#f8971d;
  }

}
.fl-sizeguide-table {

  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  thead {
    tr {
      th {
        background-color:#efefef;
        border-color:#9b9b9b;
        border-style:solid;
        border-width:1px;
        font-family:Arial, sans-serif;
        font-size:14px;
        font-weight:bold;
        overflow:hidden;
        padding:10px 5px;
        vertical-align:top;
        word-break:normal;
        width: 90px;
        min-width:90px;
        text-align: center;

        @include for-mobile {
          width:auto;
          min-width: 0;
          font-size: 12px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
          background-color:#efefef;
        }

        border-color:#9b9b9b;
        border-style:solid;
        border-width:1px;
        font-family:Arial, sans-serif;
        font-size:14px;
        overflow:hidden;
        padding:10px 5px;
        text-align:center;
        vertical-align:top;
        word-break:normal;

        @include for-mobile {
          font-size: 12px;
        }
      }
    }
  }
}
