.msc-promo-code {

    &__applied-code-header {
        @include font-content-l()
    }

    &__discount {
        background-color: $white01;
        padding: 20px 20px 0 20px;
    }

    &__line{

        &-container {
            background-color: $white01;
            color: $green01;
            font-weight: bold;
            justify-content: space-between;
            padding: 20px;
            margin-bottom: 20px;
        }

        &-discount-value {
            color: $gray03;
            float: right;
        }

        &__btn-remove-container {
            display:flex;
            justify-content:flex-end;
        }

        &__btn-remove {
            @include button-link();
            color: $gray03;
            padding: 0;
            text-decoration: underline;
        }

    }
}