.Footer__bottom-bar__links--mobile {
  margin-top:15px !important;
}

.ms-header__account-selector--hide {
  background-color: #00553d !important;

  @include for-mobile {
    padding-top:5px;
  }
}

.ms-header__account-info .ms-header__account-info-content {
  @include for-mobile {
    top: 66px !important;
  }
}
