$cart-cartline-item-padding: 43px 0 43px 10px;
$cart-cartline-item-padding-mobile: 30px 0 30px 10px;
$cart-cartline-product-title-font-size: 18px;
$cart-cartline-product-title-line-height: 21px;
$cart-cartline-product-title-font-weight: bold;

$cart-cartline-product-price-width: auto;
$cart-cartline-remove-button-margin: 20px;
$cart-cart-line-bopis-container-line-height: 20px;

$cart-compact-image-size: 120px;
$cart-compact-left-margin: 128px;

$cart-empty-image-width: 90px;

$msv-compact-line-font-size: 14px;
$msv-compact-line-line-height: 20px;
$msv-compact-cart-line-quantity-margin-left: 0;
$msv-compact-cart-line-quantity-label-margin-right: 5px;
$msv-compact-cart-line-price-strikethrough-margin-right: 10px;
$msv-compact-empty-image-width: 100%;

.msc-cart-lines-item {
    color: $black01;

    &:not(:first-child) {
        border-top: 1px solid $white03;
    }

    .has-stock,
    .low-stock,
    .not-has-stock,
    .not-avaliable {
        align-items: center;
        display: flex;
        font-weight: bold;
        width: 200px;
        font-size: 13px;
    }

    .has-stock {
        color: $green05;
    }

    .low-stock {
        color: $orange01;
    }

    .not-has-stock {
        color: $red02;
    }

    .bi {
        width: 2.5em;
        height: 2.5em;
    }
}

// Default styles for cart items
.msc-cart-line {
    &--error {
        border-left: 3px solid $orange04;
    }

    &__validation-error {
        align-items: center;
        background-color: $orange03;
        color: $orange04;
        display: flex;
        font-size: 13px;
        font-weight: 600;
        left: 0;
        padding: 5px 20px;
        top: 0;
        width: 100%;

        @include for-mobile {
            padding: 5px 10px;
        }

        &-icon {
            font-size: 20px;
            margin-right: 20px;

            @include for-mobile {
                margin-right: 10px;
            }
        }

        &-action {
            background: transparent;
            border: 0;
            color: $orange04;
            cursor: pointer;
            padding: 0;
            text-decoration: underline;
        }
    }

    &__content-wrapper {
        color: $black01;
        display: flex;
        padding: $cart-cartline-item-padding;

        @include for-tablet {
            padding: $cart-cartline-item-padding-mobile;
        }
    }

    &__center-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 10px;

        @include for-tablet {
            width: 100%;
        }
    }

    &__end-content {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 280px;

        @include for-tablet {
            max-width: none;
            width: 100%;
            flex-grow: 1;
            margin-left: 0;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;

        &-image {
            height: 96px;
            margin-right: 30px;
            min-width: 96px;
            padding: 5px;
            width: 100px;

            .mobile-inventory-label-container {
                height: 100%;
                position: relative;
                display: inline-flex;

                .bi {
                    width: 2em;
                    height: 2em;
                }
            }

            .has-stock,
            .low-stock,
            .not-has-stock,
            .not-avaliable {
                font-size: 12px;
                width: max-content;
            }
        }

        &-details {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;

            @include for-tablet {
                flex-direction: column;
            }
        }

        &-sku {
            color: $gray08;
            font-size: 11px;
            font-weight: 600;
            line-height: 1.27;
        }

        &-variant {
            &-size,
            &-color,
            &-style,
            &-config {
                .name {
                    padding-left: 8px;
                }
            }

            &-item {
                color: $gray03;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }

    &__promo-codes {
        display: inline-block;
    }

    &__quantity-label {
        color: $gray08;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__quantity-price-wrapper {
        display: flex;
        @include for-tablet{
            flex-direction: column;
        }
    }

    &__quantity {
        &__select-menu {
            width: 64px;
            height: 32px;
        }

        .quantity-label {
            font-size: 18px;
            line-height: 24px;
        }

        .quantity-value {
            display: block;
            text-align: center;
        }
    }

    &__product-price {
        text-align: right;
        margin-left: auto;
        width: $cart-cartline-product-price-width;

        @include for-tablet {
            margin-left: auto;
        }

        .msc-price {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.23;

            &__savings {
                color: $orange01;
            }

            &__strikethrough {
                text-decoration-line: line-through;
                color: $gray03;
            }
        }
    }

    &__product-title {
        color: $black02;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.23;

        &:hover {
            text-decoration: none;
        }
    }

    &__product-variants {
        color: $gray08;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        font-weight: 600;
        margin-top: 2px;
    }

    &__remove-buttom-wrapper {
        display: flex;
        margin-top: 10px;
    }

    &__remove-item,
    &__add-to-wishlist {
        @include font-content-s();
        @include button-link();
        margin: 0;
        padding: 0;
    }

    &__remove-item {
        color: $black02;
        font-size: 11px;
        font-weight: 600;
        height: auto;
        text-decoration: underline;
    }

    &__bopis-container {
        @include font-content-s();
        display: flex;
        flex-direction: column;
        margin: 12px 0;
        line-height: $cart-cart-line-bopis-container-line-height;
    }

    &__bopis-shipping {
        margin-bottom: 10px;
    }

    &__bopis-changestore {
        @include button-link();
        display: block;
        padding: 5px 0;
        font-size: 12px;
        line-height: 16px;
    }

    &__bopis__fullfilment {
        &-store {
            margin-left: 20px;
            margin-top: 3px;
            font-size: 12px;
            line-height: 16px;
            color: $gray03;
        }
    }

    .msc-empty_image {
        @include image-placeholder($cart-empty-image-width);
    }
}

// Compact styles for cart item
.msc-cart-line__compact {
    font-size: 14px;

    &.msc-cart-line {
        display: flex;
        flex-direction: column;
    }

    .msc-cart-line {
        &__product-image {
            border: 1px solid $gray09;
            height: 50px;
            margin-right: 0;
            min-width: 50px;
            padding: unset;
            position: relative;
            width: 50px;

            img {
                height: 48px;
                max-width: unset;
                width: 48px;
            }
        }

        &__product-variant-item:not(:last-child) {
            margin-right: 25px;
        }

        &__content {
            display: block;
            margin-left: 15px;
            width: 100%;
        }

        &__content-wrapper {
            padding: 0;
        }

        &__product-title {
            color: $black02;
            font-size: $msv-compact-line-font-size;
            font-weight: 600;
            line-height: $msv-compact-line-line-height;
        }

        &__quantity {
            margin-left: $msv-compact-cart-line-quantity-margin-left;

            .quantity-label {
                display: inline-block;
                font-size: 13px;
                line-height: $msv-compact-line-line-height;
                margin-right: $msv-compact-cart-line-quantity-label-margin-right;
            }

            .quantity-value {
                display: inline;
                text-align: left;
            }
        }

        &__product-price {
            margin-left: $msv-compact-cart-line-quantity-margin-left;
            text-align: left;

            .msc-price__strikethrough {
                display: inline;
                font-size: $msv-compact-line-font-size;
                line-height: $msv-compact-line-line-height;
                margin-right: $msv-compact-cart-line-price-strikethrough-margin-right;
            }

            .msc-price__actual {
                font-size: $msv-compact-line-font-size;
                line-height: $msv-compact-line-line-height;
            }
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-compact-empty-image-width);
    }
}
