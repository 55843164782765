// Styles below specificatlly used for farmlands-cart component
$msv-checkout-button-font-size: 14px;
$msv-checkout-button-font-style: $msv-primary-font-family;
$msv-checkout-button-height: auto;
$msv-checkout-button-line-height: 20px;
$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-padding: 6px 16px 7px;
$msv-checkout-button-text-align: center;
$msv-checkout-button-width: 100%;

$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$summary-min-width: 320px;
$summary-max-width: 480px;

$cart-heading-bottom: 23px;
$cart-heading-top: 32px;

$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;

$cart-cartline-line-padding-right: 20px;

$cart-content-margin-left: 200px;
$cart-line-card-height: 220px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0px;
$cart-cartline-line-remove-link-margin-left: 200px;
$cart-cartline-line-add-to-wishlist-link-margin-left: 200px;

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
    @include primary-button();
    display: block;
    font-size: $msv-checkout-button-font-size;
    font-style: $msv-checkout-button-font-style;
    height: $msv-checkout-button-height;
    line-height: $msv-checkout-button-line-height;
    padding: $msv-checkout-button-padding;
    text-align: $msv-checkout-button-text-align;
}

.msc-cart__btn-checkout:hover,
.msc-cart__btn-guestcheckout:hover {
    text-decoration: none;
}

.msc-cart__btn-backtoshopping {
    color: $green01;
    text-decoration: underline;
}

.msc-cart__btn-continue-shopping {
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 0;
    position: relative;

    &-icon {
        position: absolute;
        left: -10px;
    }

    &:hover {
        text-decoration: none;
    }
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout {
    background-color: $orange01;
    border: 1px solid $orange01;
    border-radius: 20px;
}

.msc-cart__empty-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;

    .msc-cart__btn-backtoshopping {
        width: 170px;
    }
}

.msc-cart__empty-header {
    color: $green01;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    font-family: $msv-primary-font-family;
    margin-bottom: 10px;
    margin-top: 10px;
}
.msc-cart__empty-text {
    text-align: center;
    color: $gray08;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 45px;
    margin-top: 10px;
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
    @include primary-button();
    margin: $msv-checkout-button-margin;
    width: $msv-checkout-button-width;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
}

.ms-cart {
    display: flex;
    flex-direction: column;
    margin: 20px 0 100px;

    @media (max-width: 768px) {
        display: block;
    }
}

.msc-cart-section {
    flex: 1;
    margin-bottom: $cart-cartline-wrapper-margin-bottom;
}

.msc-cartline-wrapper {
    border-top: 1px solid $gray10;

    .cart-message {
        font-weight: bold;
        font-size: 1.25em;
        margin: 1em 0 1em 25px;

        @include for-tablet {
            font-size: 1em;
            margin-left: 0;
        }
    }
}

.msc-cart__waiting-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.msc-waiting-circular-cart {
    border-top-color: $green01;
}

.ms-cart .msc-cart-line {
    &__bopis-container {
        margin-left: $cart-content-margin-left;
    }
}

.msc-cart {
    &__heading {
        color: $green01;
        flex: 0 0 100%;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.16;
        margin-bottom: $cart-heading-bottom;
    }

    &__loading-spinner-mask {
        align-items: center;
        background-color: rgba($black01, 0.5);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 10000;
    }

    &__loading-spinner-wrapper {
        align-items: center;
        background-color: $white01;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        padding: 25px;
        width: 250px;
        height: 250px;
    }

    &__loading-spinner {
        border-top-color: $green01;
        border-width: 5px;
    }
}

@include for-desktop {
    .ms-cart {
        align-items: flex-start;
        flex-direction: row;
    }

    .msc-cartline-wrapper,
    .msc-promotion-wrapper {
        margin-right: $cart-cartline-wrapper-margin-right;
    }
}

@include for-tablet {
    .msc-cart-lines-item {
        .msc-cart-line {
            &__product {
                padding-bottom: $cart-cartline-line-product-padding-bottom;
            }
            &__product-price {
                margin-left: 0px;
                margin-top: 10px;
                text-align: left;
            }
            &__add-to-wishlist {
                display: block;
                margin-left: $cart-cartline-line-add-to-wishlist-link-margin-left;
            }
        }
    }
}
