$msv-wishlist-items-width: 298px;
$msv-wishlist-items-width-m: 298px;
$msv-wishlist-items-empty-image-height: 298px;
$msv-wishlist-items-empty-image-height-m: 278px;
$msv-wishlist-items-heading-spacing: 20px;
$msv-wishlist-item-margin: 0 20px 20px 0;
$msv-wishlist-item-height: 460px;
$msv-wishlist-item-image-margin-bottom: 4px;
$msv-wishlist-item-image-size: $msv-wishlist-items-width;
$msv-wishlist-item-image-width-m: $msv-wishlist-items-width-m;
$msv-wishlist-item-price-original-font-color: $gray03;
$msv-wishlist-item-price-original-line-height: 16px;
$msv-wishlist-item-price-margin-top: 4px;
$msv-wishlist-item-dimension-font-size: 13px;
$msv-wishlist-item-dimension-line-height: 16px;
$msv-wishlist-item-dimension-margin-right: 10px;
$msv-wishlist-item-dimension-label-margin: 5px;
$msv-wishlist-item-icon-font-size: 20px;
$msv-wishlist-item-add-button-icon-margin-right: 8px;
$msv-wishlist-item-remove-button-width: 32px;
$msv-wishlist-item-remove-button-top: 8px;
$msv-wishlist-item-remove-button-right: 8px;
$msv-wishlist-item-message-font-size: 18px;
$msv-wishlist-item-message-padding: 10px;
$msv-wishlist-item-error-bg-color: $red01;
$msv-wishlist-item-error-border-color: $red01;
$msv-wishlist-item-product-status-max-width: 298px;
$msv-wishlist-items-message-empty: 32px;

.ms-wishlist-items {
    @include font-content-m();

    &__heading {
        @include font-content-xl();
        color: $green01;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: $msv-wishlist-items-heading-spacing;
    }

    &__message {
        @include font-content-l();
        padding: $msv-wishlist-item-message-padding;

        &-failed {
            color: $white01;
            background-color: $msv-wishlist-item-error-bg-color;
            border-color: $msv-wishlist-item-error-border-color;
        }
    }

    &__products {
        display: flex;
        flex-wrap: wrap;
        padding-top: $msv-wishlist-items-heading-spacing;
    }

    &__product {
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        margin: $msv-wishlist-item-margin;
        min-height: $msv-wishlist-item-height;
        padding: 10px;
        position: relative;

        &-image {
            margin-bottom: $msv-wishlist-item-image-margin-bottom;
            width: $msv-wishlist-item-image-size;

            &.msc-empty_image {
                @include image-placeholder($msv-wishlist-item-image-size);
            }
        }

        &-link {
            color: $black02;
            font-weight: 600;
            width: $msv-wishlist-items-width;

            &:hover {
                text-decoration: none;
            }
        }

        &-price {
            margin-top: $msv-wishlist-item-price-margin-top;
            font-size: 14px;
            font-weight: 600;

            .msc-price__strikethrough {
                color: $msv-wishlist-item-price-original-font-color;
                line-height: $msv-wishlist-item-price-original-line-height;
            }

            .msc-price__actual {
                color: $orange01;
            }

            // not needed when Price component is moved to utilities folder
            .price-text-screen-reader {
                display: none;
            }
        }

        &-dimensions {
            color: $gray03;
            display: flex;
            font-size: $msv-wishlist-item-dimension-font-size;
            font-weight: 600;
            line-height: $msv-wishlist-item-dimension-line-height;
        }

        &-dimension {
            margin-right: $msv-wishlist-item-dimension-margin-right;
            .msc-wishlist-dimension {
                &__label {
                    margin-right: $msv-wishlist-item-dimension-label-margin;
                }
            }
        }

        &-add-button {
            @include add-icon($msv-ShoppingCart);
            background-color: $orange01;
            border-radius: 16px;
            color: $white01;
            font-size: 13px;
            margin-top: 1rem;
            text-transform: uppercase;

            &:before {
                color: $white01;
                font-size: $msv-wishlist-item-icon-font-size;
                margin-right: $msv-wishlist-item-add-button-icon-margin-right;
            }
        }

        &-remove-button {
            @include add-icon($msv-x-shape);
            background-color: $white01;
            border: none;
            color: $black01;
            cursor: pointer;
            font-size: $msv-wishlist-item-icon-font-size;
            position: absolute;
            right: $msv-wishlist-item-remove-button-right;
            top: $msv-wishlist-item-remove-button-top;
            width: $msv-wishlist-item-remove-button-width;
        }

        &-status {
            position: relative;
            margin-top: 1rem;
            max-width: $msv-wishlist-item-product-status-max-width;

            @include for-sm-mobile {
                position:absolute;
                left:0;
                right:0;
                width:100%;
                margin-top:0;
                margin:auto;
            }

            .msc-alert {
                text-align: center;

                @include for-sm-mobile {
                    width:100%;
                }

                button.close {
                    background-color: transparent;
                    border: none;
                    float: right;
                }
            }
        }

        .entire-product-link:hover {
            text-decoration: none;

            .ms-wishlist-items__product-title {
                text-decoration: underline;
            }
        }
    }

    &__message-empty,
    &__message-waiting,
    &__message-failed {
        margin-bottom: $msv-wishlist-items-message-empty;
    }

    @include for-tablet {
        &__product {
            flex: 0 1 $msv-wishlist-items-width-m;
            width: $msv-wishlist-items-width;

            &-image {
                width: $msv-wishlist-item-image-width-m;
            }
        }

        .msc-empty_image {
            @include image-placeholder($msv-wishlist-items-empty-image-height-m);
        }
    }

    @include for-mobile {
        &__product {
            min-height: 0px;

            &-add-button {
                margin-top: 12px;
            }
        }
    }
}
