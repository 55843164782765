@mixin slate-font(
  $font-weight: $msv-font-weight-normal,
  $font-size: 14px,
  $line-height: 18px,
  $text-transform: none,
  $font-family: $msv-primary-font-family
) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  text-transform: $text-transform;
}

@mixin font-content(
  $font-weight: $msv-font-weight-normal,
  $font-size: 14px,
  $line-height: 18px,
  $text-transform: none
) {
  @include slate-font($font-weight, $font-size, $line-height, $text-transform);
}

@mixin font-content-t($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 12px, 16px);
}

@mixin font-content-s($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight);
}

@mixin font-content-s-uppercase($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 14px, 18px, uppercase);
}

@mixin font-content-s-height-l($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 14px, 24px);
}

@mixin font-content-m($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 16px, 21px);
}

@mixin font-content-m-underline($font-weight: $msv-font-weight-normal) {
  text-decoration-line: underline;
  @include font-content($font-weight, 16px, 21px);
}

@mixin font-content-l-strikethrough($font-weight: $msv-font-weight-normal) {
  text-decoration-line: line-through;
  @include font-content($font-weight, 18px, 22px);
}

@mixin font-content-l($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 18px, 24px);
}

@mixin font-content-xl($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 24px, 32px);
}

@mixin font-content-xxl($font-weight: $msv-font-weight-normal) {
  @include font-content($font-weight, 40px, 60px);
}

@mixin slate-display($font-size: 24px, $line-height: 32px, $font-family: $msv-font-assistant) {
  @include slate-font($msv-font-weight-normal, $font-size, $line-height, uppercase, $font-family);
}

@mixin font-display-xs() {
  @include slate-display();
}

@mixin font-display-s() {
  @include slate-display(36px, 46px);
}

@mixin font-display() {
  letter-spacing: 0.05em;
  @include slate-display(100px, 134px, $font-cinzel);
}

@mixin font-display-l() {
  letter-spacing: 0.05em;
  @include slate-display(160px, 215px, $font-cinzel);
}

@mixin font-glyph-standard() {
  font-size: 24px;
}

@mixin font-glyph-small() {
  font-size: 16px;
}

@mixin make-container-padding($paddings: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $padding in $paddings {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-left: $padding;
      padding-right: $padding;
    }
  }
}

@mixin basicTruncation {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin basic-outline-offset(
  $outline-width: 1px,
  $outline-style: dashed,
  $outline-color: $black01,
  $outline-offset: -1px
) {
  outline: $outline-width $outline-style $outline-color;
  outline-offset: $outline-offset;
}

@mixin font-awesome-icon($icon: "") {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: $icon;
  display: inline;
}

@mixin screen-reader() {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

// Form elements

@mixin error-message() {
  @include font-content-m($font-weight-bold);
  display: block;
  color: $red;
}

@mixin form-input-label() {
  @include font-content-l();
  display: block;
  margin-bottom: 4px;
}

@mixin form-input-checkbox() {
  height: 18px;
  width: 18px;
}

@mixin form-input-el() {
  @include font-content-m();
  height: 32px;
  padding: 4px 8px;
  background-clip: border-box;
}

@mixin form-input-el-error() {
  border: 1px dashed $red01;
  border-left: 3px solid $red01;
}

@mixin form-input-error-message() {
  @include error-message();
  margin-bottom: 4px;
  &:before {
    @include font-awesome-icon($alert-icon);
    margin-right: 8px;
  }
}

@mixin alert {
  background-color: $pink;
  border: 1px solid $red;
  max-width: 512px;
  padding: 8px;
}

@mixin alert-title {
  @include error-message();
  margin-bottom: 10px;
  margin-top: 2px;
  &:before {
    @include font-awesome-icon($alert-icon);
    margin-right: 8px;
  }
}

@mixin button-default() {
  @include font-content-l();
  padding: 12px 20px;
  height: 48px;
  min-width: 160px;
}

@mixin button-icon {
  width: 48px;
  height: 48px;
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
  position: $position;

  &:focus {
    outline: 1px dashed $white01;

    &:#{$selector} {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      outline: 1px dashed $black01;
      outline-offset: $offset;
    }
  }
}

// Underline animation for header / footer links
@mixin underlineOnHover {
  &:hover {
    &:after {
      width: 100%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    border-top: 2px solid #f8971d;
    width: 0;
    transition: all 0.3s cubic-bezier(0.68, -0.2, 0.3, 1.55);
    transform: translateX(-50%);
  }
}

// Scrollbar mixin
@mixin scrollbarStyle {
  scrollbar-width: thin; // Firefox

  // Chrome, Safari
  &::-webkit-scrollbar {
    width: 14px;
    background-color: $white03;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 8px;
    background-color: $gray10;
    border: 3px solid $white03;
  }
}
