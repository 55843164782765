$font-heading-size: 38px;
$font-heading-line-height: 118%;
$font-heading-letter-spacing: -1%;
$font-heading-weight: 500;
$font-subheading-1-size: 30px;
$font-subheading-1-line-height: 110%;
$font-subheading-1-letter-spacing: 1%;
$font-subheading-1-weight: 400;
$font-subheading-2-size: 22px;
$font-subheading-2-line-height: 120%;
$font-subheading-2-letter-spacing: 0%;
$font-subheading-2-weight: 700;
$font-subheading-3-size: 18px;
$font-subheading-3-line-height: 125%;
$font-subheading-3-letter-spacing: 1%;
$font-subheading-3-weight: 500;
$font-caption-size: 14px;
$font-caption-line-height: 140%;
$font-caption-letter-spacing: 5%;
$font-caption-weight: 600;
$font-mobile-heading-size: 26px;
$font-mobile-heading-line-height: 118%;
$font-mobile-heading-letter-spacing: -1%;
$font-mobile-heading-weight: 600;
$font-mobile-subheading-1-size: 22px;
$font-mobile-subheading-1-line-height: 120%;
$font-mobile-subheading-1-letter-spacing: 1%;
$font-mobile-subheading-1-weight: 400;
$font-mobile-subheading-2-size: 20px;
$font-mobile-subheading-2-line-height: 120%;
$font-mobile-subheading-2-letter-spacing: 0%;
$font-mobile-subheading-2-weight: 700;
$font-mobile-subheading-3-size: 18px;
$font-mobile-subheading-3-line-height: 125%;
$font-mobile-subheading-3-letter-spacing: 1%;
$font-mobile-subheading-3-weight: 500;
$font-mobile-caption-size: 14px;
$font-mobile-caption-line-height: 140%;
$font-mobile-caption-letter-spacing: 5%;
$font-mobile-caption-weight: 600;
$font-body-size: 16px;
$font-body-line-height: 140%;
$font-body-letter-spacing: 0%;
$font-body-weight: 400;
$font-mobile-body-size: 14px;
$font-mobile-body-line-height: 140%;
$font-mobile-body-letter-spacing: 0%;
$font-mobile-body-weight: 400;
$font-body-bold-size: 16px;
$font-body-bold-line-height: 140%;
$font-body-bold-letter-spacing: 0%;
$font-body-bold-weight: 600;
$font-mobile-body-bold-size: 14px;
$font-mobile-body-bold-line-height: 140%;
$font-mobile-body-bold-letter-spacing: 0%;
$font-mobile-body-bold-weight: 600;
$font-link-size: 11px;
$font-link-line-height: 100%;
$font-link-letter-spacing: 3%;
$font-link-weight: 500;
$font-small-size: 11px;
$font-small-line-height: 130%;
$font-small-letter-spacing: 4%;
$font-small-weight: 500;
$font-button-size: 13px;
$font-button-line-height: 100%;
$font-button-letter-spacing: 4%;
$font-button-weight: 700;
$font-pill-size: 8px;
$font-pill-line-height: 100%;
$font-pill-letter-spacing: 30%;
$font-pill-weight: 700;
$font-subtext-size: 13px;
$font-subtext-line-height: 100%;
$font-subtext-letter-spacing: 2%;
$font-subtext-weight: 400;
$font-subtext-bold-size: 13px;
$font-subtext-bold-line-height: 100%;
$font-subtext-bold-letter-spacing: 2%;
$font-subtext-bold-weight: 600;

$breakpoint-mobile-small: 400px;
$breakpoint-mobile: 500px;
$breakpoint-tablet: 1000px;
$breakpoint-desktop: 1300px;

$msv-breakpoint-xs: 0;
$msv-breakpoint-s: 0;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-lg: 1366px;
$msv-breakpoint-xl: 1440px;

$site-max-width: 1440px;

// =============================================================================
// Margin & padding helper fn
// =============================================================================
@each $screen in ("tablet-only-", "desktop-only-", "") {
    @each $space in (0, 10, 15, 20, 30, 40, 50, 80, 100, 120, 150, 180, 200) {
        .#{$screen}mg-#{$space} {
            margin: #{$space}px;
        }
        .#{$screen}pd-#{$space} {
            padding: #{$space}px;
        }
        .#{$screen}mh-#{$space} {
            margin-left: #{$space}px;
            margin-right: #{$space}px;
        }
        .#{$screen}mv-#{$space} {
            margin-bottom: #{$space}px;
            margin-top: #{$space}px;
        }
        .#{$screen}ph-#{$space} {
            padding-left: #{$space}px;
            padding-right: #{$space}px;
        }
        .#{$screen}pv-#{$space} {
            padding-bottom: #{$space}px;
            padding-top: #{$space}px;
        }
        @each $side in (top, right, bottom, left) {
            .#{$screen}mg-#{str-slice($side, 0, 1)}#{$space} {
                margin-#{$side}: #{$space}px;
            }
            .#{$screen}pd-#{str-slice($side, 0, 1)}#{$space} {
                padding-#{$side}: #{$space}px;
            }
        }
    }
}
