.msc-delivery-methods {
    display: flex;
    flex-direction: column;
    margin-bottom: 19px;
    width: 100%;
}

.msc-delivery-method {
    &__option {
        align-items: center;
        background-color: $green01;
        border-radius: 5px;
        color: $white01;
        cursor: pointer;
        display: flex;
        font-size: 13px;
        font-weight: 600;
        height: 50px;
        padding: 16px 20px;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &__input {
        display: none;
    }

    &__radio-outer {
        align-items: center;
        border: solid 1px $white01;
        border-radius: 9px;
        display: flex;
        height: 18px;
        justify-content: center;
        width: 18px;
        margin-right: 28px;
    }

    &__radio-inner {
        background-color: $white01;
        border-radius: 4px;
        display: none;
        height: 8px;
        width: 8px;
    }

    // Rule for 'toggling' visual radio element when actual adjacent input element is checked.
    &__input:checked + &__radio-outer > &__radio-inner {
        display: block;
    }

    &__icon {
        font-size: 16px;
        margin-right: 10px;
    }

    &__selected-store {
        color: $gray08;
        display: flex;
        font-weight: 600;
        visibility: hidden;

        &--show {
            visibility: visible;
        }

        &-label {
            color: $green01;
            padding-right: 5px;
        }

        &-details {
            align-items: flex-start;
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        &-detail {
            white-space: pre-line;

            &--org-name {
                font-weight: 700;
            }
        }
    }

    &__change-store {
        background: transparent;
        border: 0;
        color: $gray08;
        cursor: pointer;
        margin-top: 5px;
        padding: 0;
        text-decoration: underline;
        width: auto;
    }
}
