// desktop
$cookie-compliance_container-padding-l: 60px;
$cookie-compliance_container-padding-m: 20px;
$cookie-compliance_container-max-width: $site-max-width;
$cookie-compliance_content-width:100%;
$cookie-compliance_accept-btn-margin-left: 48px;
$cookie-compliance_accept-btn-width: 160px;

// mobile 
$cookie-compliance_accept-btn-width-mobile: 243px;
$cookie-compliance_accept-content-margin-bottom-mobile:24px;


.ms-cookie-compliance {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9001;
  background-color: $green01;
  color: $white01;
  min-height: 74px;
  padding: 10px 0;

  &__container {
    display: flex;
    font-weight: normal;
    margin-bottom: 0;
    max-width: $cookie-compliance_container-max-width;
    margin: 0 auto;

    @include for-tablet {
        padding-left: $cookie-compliance_container-padding-m;
        padding-right: $cookie-compliance_container-padding-m;
    }

    @include for-desktop {
      padding-left: $cookie-compliance_container-padding-l;
      padding-right: $cookie-compliance_container-padding-l;
    }
  }

  &__content {
    width:$cookie-compliance_content-width;
    font-size: 13px;
    line-height: 1.31;
    font-weight: normal;
  }

  &__accept-button {
    float: right;
    margin-left: $cookie-compliance_accept-btn-margin-left;
    width: $cookie-compliance_accept-btn-width;
    height: 34px;
    color: $white;
    background-color: $orange01;
    text-transform: uppercase;
    border-radius: 21px;
    font-size: 13px;
  }

  &__cta-layer .link {
    text-decoration: underline;
    color: $white01;
  }
}

@include for-tablet {
  .ms-cookie-compliance {

    &__content {
      margin-bottom: $cookie-compliance_accept-content-margin-bottom-mobile;
    }
    
    &__accept-button {
      float: none;
      margin: 0;
      width: $cookie-compliance_accept-btn-width-mobile;
    }

    &__container {
      align-items: center;
      flex-direction: column;
    }
  }
}