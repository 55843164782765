$msv-primary-font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
$msv-font-weight-bold: bold;
$msv-font-weight-bolder: bolder;
$msv-font-weight-400: 400;
$msv-font-weight-500: 500;
$msv-font-weight-600: 600;
$msv-font-weight-700: 700;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-heavy: 600;
$msv-text-size: 14px;
$msv-text-line-height: 20px;
$msv-font-size-m: 16px;

// Generic font sizes for adventure works theme
$msv-font-size-xs: 12px;
$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-ml: 20px;
$msv-font-size-l: 24px;
$msv-font-size-xl: 36px;
$msv-font-size-xxl: 48px;

// Generic line heights for adventure works theme
$msv-line-height-xs: 16px;
$msv-line-height-s: 20px;
$msv-line-height-m: 24px;
$msv-line-height-l: 28px;
$msv-line-height-xl: 44px;
$msv-line-height-xxl: 63px;
$msv-line-height-xxxl: 80px;

// Generic desktop title font sizes for adventure works theme
$msv-title-font-size-xxs: 16px;
$msv-title-font-size-xs: 14px;
$msv-title-font-size-s: 24px;
$msv-title-font-size-m: 36px;
$msv-title-font-size-m-s: 32px;
$msv-title-font-size-l: 48px;
$msv-title-font-size-xl: 100px;
$msv-title-font-size-xxl: 140px;

// Generic desktop title line heights for adventure works theme
$msv-title-line-height-xs: 24px;
$msv-title-line-height-s: 28px;
$msv-title-line-height-m-s: 40px;
$msv-title-line-height-m: 44px;
$msv-title-line-height-m-l: 48px;
$msv-title-line-height-l: 56px;
$msv-title-line-height-xl: 120px;
$msv-title-line-height-xxl: 200px;

// Generic tablet title font sizes for adventure works theme
$msv-tablet-title-font-size-xs: 20px;
$msv-tablet-title-font-size-s: 24px;
$msv-tablet-title-font-size-m: 32px;
$msv-tablet-title-font-size-l: 48px;
$msv-tablet-title-font-size-xl: 60px;
$msv-tablet-title-font-size-xxl: 80px;

// Generic tablet title line heights for adventure works theme
$msv-tablet-title-line-height-xs: 24px;
$msv-tablet-title-line-height-s: 28px;
$msv-tablet-title-line-height-m: 40px;
$msv-tablet-title-line-height-l: 56px;
$msv-tablet-title-line-height-xl: 72px;
$msv-tablet-title-line-height-xxl: 100px;

// Generic mobile title font size for adventure works theme
$msv-mobile-title-font-size-xs: 20px;
$msv-mobile-title-font-size-s: 24px;
$msv-mobile-title-font-size-m: 32px;
$msv-mobile-title-font-size-l: 36px;
$msv-mobile-title-font-size-xl: 40px;
$msv-mobile-title-font-size-xxl: 48px;

// Generic mobile title line heights for adventure works theme
$msv-mobile-title-line-height-xs: 24px;
$msv-mobile-title-line-height-s: 28px;
$msv-mobile-title-line-height-m: 40px;
$msv-mobile-title-line-height-l: 48px;
$msv-mobile-title-line-height-xl: 50px;
$msv-mobile-title-line-height-xxl: 60px;

$msv-heading-text-size: 20px;
$msv-heading-line-height: 28px;

$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-l: 18px;
$msv-font-size-xl: 24px;
$msv-font-size-xxl: 48px;
$msv-font-path: "../../../webfonts";

$msv-font-family-nothing-you-could-do: "Nothing You Could Do";
$msv-font-family-beth-ellen: "BethEllen";

@mixin lineHeightFromFontSize($font-size) {
    @if $font-size==$msv-text-size {
        line-height: $msv-text-line-height;
    }
}

// Style presets
:root {
    // Body
    --msv-body-font-size-s: #{$msv-font-size-s};
    --msv-body-font-size-m: #{$msv-font-size-m};
    --msv-body-font-size-l: #{$msv-font-size-l};
    --msv-body-font-size-xl: #{$msv-font-size-xl};
    --msv-body-font-size-xxl: #{$msv-font-size-xxl};

    // Headings H2
    --msv-h2-font-size-l: #{$msv-title-font-size-m};
    --msv-h2-font-size-m: #{$msv-title-font-size-m-s};
    --msv-h2-font-size-s: #{$msv-title-font-size-m-s};
    --msv-h2-line-height-l: #{$msv-title-line-height-m};
    --msv-h2-line-height-m: #{$msv-title-line-height-m-s};
    --msv-h2-line-height-s: #{$msv-title-line-height-m-s};

    // Headings H3
    --msv-h3-font-size-l: #{$msv-title-font-size-s};
    --msv-h3-font-size-m: #{$msv-title-font-size-s};
    --msv-h3-font-size-s: #{$msv-title-font-size-s};
    --msv-h3-line-height-l: #{$msv-title-line-height-s};
    --msv-h3-line-height-m: #{$msv-title-line-height-s};
    --msv-h3-line-height-s: #{$msv-title-line-height-s};

    // Headings H4
    --msv-h4-font-size-l: #{$msv-title-font-size-s};
    --msv-h4-font-size-m: #{$msv-title-font-size-s};
    --msv-h4-font-size-s: #{$msv-title-font-size-xs};
    --msv-h4-line-height-l: #{$msv-title-line-height-s};
    --msv-h4-line-height-m: #{$msv-title-line-height-s};
    --msv-h4-line-height-s: #{$msv-title-line-height-s};

    // Headings H5
    --msv-h5-font-size-l: #{$msv-title-font-size-xs};
    --msv-h5-font-size-m: #{$msv-title-font-size-xs};
    --msv-h5-font-size-s: #{$msv-title-font-size-xs};
    --msv-h5-line-height-l: #{$msv-title-line-height-xs};
    --msv-h5-line-height-m: #{$msv-title-line-height-xs};
    --msv-h5-line-height-s: #{$msv-title-line-height-xs};

    // Headings H6
    --msv-h6-font-size-l: #{$msv-title-font-size-xxs};
    --msv-h6-font-size-m: #{$msv-title-font-size-xxs};
    --msv-h6-font-size-s: #{$msv-title-font-size-xxs};
    --msv-h6-line-height-l: #{$msv-title-line-height-xs};
    --msv-h6-line-height-m: #{$msv-title-line-height-xs};
    --msv-h6-line-height-s: #{$msv-title-line-height-xs};
}

@font-face {
  font-family: $msv-font-family-nothing-you-could-do;
  src: url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype"),
  url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: $msv-font-family-nothing-you-could-do;
  src: url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype"),
  url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: $msv-font-family-beth-ellen;
  src: url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype"),
  url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype");
}

@font-face {
  font-family: $msv-font-family-beth-ellen;
  src: url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype"),
  url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@mixin proxima($font-weight, $font-file) {
    @font-face {
        font-family: proxima-nova;
        src: url($font-file) format("opentype");
        font-weight: $font-weight;
        font-style: normal;
    }
}

@include proxima(100, "#{$msv-font-path}/proximanova-thin.otf");
@include proxima(300, "#{$msv-font-path}/proximanova-light.otf");
@include proxima(400, "#{$msv-font-path}/proximanova-regular.otf");
@include proxima(600, "#{$msv-font-path}/proximanova-semibold.otf");
@include proxima(700, "#{$msv-font-path}/proximanova-bold.otf");
@include proxima(800, "#{$msv-font-path}/proximanova-extrabold.otf");
@include proxima(900, "#{$msv-font-path}/proximanova-black.otf");



// H1 (desktop) BOLD, font-size = 48px & line-height = 56px

@mixin font-heading-h1-l() {
  @include msv-rubik-bold(
          $font-weight: $msv-font-weight-700,
          $font-style: $msv-font-weight-normal,
          $font-size: 48px,
          $line-height: 56px);
}

// H1 (tablet) BOLD, font-size = 48px & line-height = 56px

@mixin font-heading-h1-m() {
  @include msv-rubik-bold(
          $font-weight: $msv-font-weight-700,
          $font-style: $msv-font-weight-normal,
          $font-size: var(--msv-h1-font-size-m),
          $line-height: var(--msv-h1-line-height-m));
}

// H1 (small) BOLD, font-size = 36px & line-height = 48px

@mixin font-heading-h1-s() {
  @include msv-rubik-bold(
          $font-weight: $msv-font-weight-700,
          $font-style: $msv-font-weight-normal,
          $font-size: 36px,
          $line-height: 48px);
}

// H2 (desktop) BOLD, font-size = 36px & line-height = 44px

@mixin font-heading-h2-l() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h2-font-size-l),
            $line-height: var(--msv-h2-line-height-l));
}

// H2 (tablet) BOLD, font-size = 32px & line-height = 40px

@mixin font-heading-h2-m() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h2-font-size-m),
            $line-height: var(--msv-h2-line-height-m));
}

// H2 (mobile) BOLD, font-size = 32px & line-height = 40px

@mixin font-heading-h2-s() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h2-font-size-s),
            $line-height: var(--msv-h2-line-height-s));
}

// H3 (desktop) BOLD, font-size = 24px & line-height = 28px

@mixin font-heading-h3-l() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h3-font-size-l),
            $line-height: var(--msv-h3-line-height-l));
}

// H3 (tablet) BOLD, font-size = 24px & line-height = 28px

@mixin font-heading-h3-m() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h3-font-size-m),
            $line-height: var(--msv-h3-line-height-m));
}

// H3 (mobile) BOLD, font-size = 24px & line-height = 28px

@mixin font-heading-h3-s() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h3-font-size-s),
            $line-height: var(--msv-h3-line-height-s));
}

// H4 (desktop) MEDIUM, font-size = 24px & line-height = 28px

@mixin font-heading-h4-l() {
    @include msv-rubik-medium(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h4-font-size-l),
            $line-height: var(--msv-h4-line-height-l));
}

// H4 (tablet) MEDIUM, font-size = 24px & line-height = 28px

@mixin font-heading-h4-m() {
    @include msv-rubik-medium(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h4-font-size-m),
            $line-height: var(--msv-h4-line-height-m));
}

// H4 (mobile) MEDIUM, font-size = 24px & line-height = 28px

@mixin font-heading-h4-s() {
    @include msv-rubik-medium(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h4-font-size-s),
            $line-height: var(--msv-h4-line-height-s));
}

// H5 (desktop) MEDIUM, font-size = 20px & line-height = 24px

@mixin font-heading-h5-l() {
    @include msv-rubik-medium(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h5-font-size-l),
            $line-height: var(--msv-h5-line-height-l));
}

// H5 (tablet) MEDIUM, font-size = 20px & line-height = 24px

@mixin font-heading-h5-m() {
    @include msv-rubik-medium(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h5-font-size-m),
            $line-height: var(--msv-h5-line-height-m));
}

// H5 (mobile) MEDIUM, font-size = 20px & line-height = 24px

@mixin font-heading-h5-s() {
    @include msv-rubik-medium(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h5-font-size-s),
            $line-height: var(--msv-h5-line-height-s));
}

// H6 (desktop) BOLD, font-size = 16px & line-height = 24px

@mixin font-heading-h6-l() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h6-font-size-l),
            $line-height: var(--msv-h6-line-height-l));
}

// H6 (tablet) BOLD, font-size = 16px & line-height = 24px

@mixin font-heading-h6-m() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h6-font-size-m),
            $line-height: var(--msv-h6-line-height-m));
}

// H6 (mobile) BOLD, font-size = 16px & line-height = 24px

@mixin font-heading-h6-s() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-h6-font-size-s),
            $line-height: var(--msv-h6-line-height-s));
}

// Body BOLD medium, font-size = 16px & line-height = 24px

@mixin font-body-bold-m() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-body-font-size-m),
            $line-height: var(--msv-body-line-height-m));

    color: var(--msv-body-font-color);
}

// Body BOLD small, font-size = 14px & line-height = 20px

@mixin font-body-bold-s() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-500,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-body-font-size-s),
            $line-height: var(--msv-body-line-height-s));

    color: var(--msv-body-font-color);
}

// Body BOLD extra small, font-size = 12px & line-height = 16px

@mixin font-body-bold-xs() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-body-font-size-xs),
            $line-height: var(--msv-body-line-height-xs));

    color: var(--msv-body-font-color);
}

// Body REGULAR medium, font-size = 16px & line-height = 24px

@mixin font-body-regular-m() {
    @include msv-rubik-regular(
            $font-weight: $msv-font-weight-400,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-body-font-size-m),
            $line-height: var(--msv-body-line-height-m));

    color: var(--msv-body-font-color);
}

// Body REGULAR small, font-size = 14px & line-height = 20px

@mixin font-body-regular-s() {
    @include msv-rubik-regular(
            $font-weight: $msv-font-weight-400,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-body-font-size-s),
            $line-height: var(--msv-body-line-height-s));

    color: var(--msv-body-font-color);
}

// Body REGULAR extra small, font-size = 12px & line-height = 16px

@mixin font-body-regular-xs() {
    @include msv-rubik-regular(
            $font-weight: $msv-font-weight-400,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-body-font-size-xs),
            $line-height: var(--msv-body-line-height-xs));

    color: var(--msv-body-font-color);
}

// Special call-outs, font-size = 100px & line-height = 120px

@mixin font-callout-bold-l() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-callout-font-size-l),
            $line-height: var(--msv-callout-line-height-l));

    color: var(--msv-body-font-color);
}

// Special call-outs, font-size = 60px & line-height = 72px

@mixin font-callout-bold-m() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-callout-font-size-m),
            $line-height: var(--msv-callout-line-height-m));

    color: var(--msv-body-font-color);
}

// Special call-outs, font-size = 40px & line-height = 50px

@mixin font-callout-bold-s() {
    @include msv-rubik-bold(
            $font-weight: $msv-font-weight-700,
            $font-style: $msv-font-weight-normal,
            $font-size: var(--msv-callout-font-size-s),
            $line-height: var(--msv-callout-line-height-s));

    color: var(--msv-body-font-color);
}

@mixin msv-rubik-regular($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-primary-font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-rubik-bold($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-primary-font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-rubik-medium($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-primary-font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}
