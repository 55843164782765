$msv-checkout-margin-bottom: 28px;
$msv-checkout-margin-top: 28px;
$msv-checkout-margin-left-right: auto;

$msv-checkout-title-font-size: 38px;
$msv-checkout-title-line-height: 41px;

$msv-checkout-plain-container-padding-left: 0px;

$msv-checkout-main-max-width: calc(100% - 450px);
$msv-checkout-side-width: 450px;

$msv-checkout-standard-margin-padding: 32px;

$msv-checkout-main-panel-padding-right: 40px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 20px;
$msv-checkout-guided-card-padding-bottom: 25px;
$msv-checkout-guided-card-padding-left: 32px;
$msv-checkout-guided-card-padding-right: 32px;
$msv-checkout-guided-card-border-top-width: 2px;
$msv-checkout-guided-card-body-margin-top: 12px;
$msv-checkout-guided-card-footer-margin-top: 40px;
$msv-checkout-button-height: 32px;
$msv-checkout-side-control-margin-top: 20px;
$msv-checkout-side-control-margin-bottom: 20px;
$msv-checkout-side-control-first-margin-top: 0px;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-save-button-margin-left: 10px;
$msv-checkout-active-color: $green01;
$msv-checkout-inactive-color: $gray10;
$msv-checkout-shipping-image-width: 80px;
$msv-checkout-shipping-margin-right: 12px;
$msv-checkout-input-dimensions: 18px;
// checkout__lines styling
$msv-checkout-line-items-background-color: $gray06;
$msv-checkout-line-items-padding: 40px;
$msv-checkout-line-image-size: 108px;
$msv-checkout-line-font-style: $msv-primary-font-family;
$msv-checkout-line-font-size: 14px;
$msv-checkout-line-line-height: 20px;
$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-size: 22px;
$msv-checkout-line-heading-font-weight: 600;
$msv-checkout-line-heading-line-height: 28px;
$msv-checkout-edit-link-color: $blue01;
$msv-checkout-payment-instrument-error-max-width: 512px;
$msv-checkout-payment-instrument-error-padding: 8px;
$msv-checkout-payment-instrument-error-title-margin-bottom: 10px;
$msv-checkout-payment-instrument-error-margin-top: 2px;
$msv-checkout-payment-instrument-message-margin-buttom: 0px;
$msv-checkout-guided-card-title-font-size: 20px;
$msv-checkout-guided-card-title-line-height: 28px;
$msv-checkout-guided-card-title-step-width: 30px;
$msv-checkout-guided-card-title-text-padding-left: 12px;
$msv-checkout-order-note-title-font-size: 16px;
$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;
$msv-checkout-pick-up-at-store-margin-top: 20px;
$msv-checkout-pick-up-at-store-margin-bottom: 20px;

// Mobile styles
$msv-mobile-checkout-min-width: 320px;
$msv-mobile-checkout-max-width: 100%;
$msv-mobile-checkout-padding-zero: 0px;
$msv-mobile-checkout-margin: 0px;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0px;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;

// Alert
$msv-checkout-error-message-padding: 18px;
$msv-checkout-error-message-margin-bottom: 20px;
$msv-checkout-error-message-width: 100%;
$msv-checkout-icon-margin-right: 8px;

.ms-checkout {
  padding-bottom: $msv-checkout-margin-bottom;
  padding-top: $msv-checkout-margin-top;
  margin-left: $msv-checkout-margin-left-right;
  margin-right: $msv-checkout-margin-left-right;
  font-size: $msv-text-size;

  .msc-cart-line__compact .msc-cart-line__quantity .quantity-label {
    line-height: inherit;
  }

  .back-btn {
    font-weight: bold;
    @include add-icon($msv-carretLeft);

    &::before {
      padding-right: 10px;
    }
  }

  input,
  select {
    color: $gray08;
    border: 1px solid $white02;

    &[type="text"], &[type="number"] {
      border-radius: 7px;
      font-size: 16px;
      font-weight: normal;
      min-height: 40px;
      padding-left: 25px;
      line-height: 26px;
    }

    // remove spinner button on the text input
    &[type="number"] {
      -moz-appearance:textfield; /* Firefox */
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &__input-label {
    color: $gray08;
    font-size: 13px;
    line-height: 26px;
    text-transform: uppercase;

    input {
      display: block;
      width: 100%;
    }
  }

  &__head {
    margin-top: 30px;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__error-message {
    width: $msv-checkout-error-message-width;

    background-color: $red01;
    border: 1px solid $red01;
    color: $white01;
    padding: $msv-checkout-error-message-padding;
    margin-bottom: $msv-checkout-error-message-margin-bottom;

    &:before {
      @include msv-icon();
      content: $msv-IncidentTriangle;
      margin-right: $msv-checkout-icon-margin-right;
    }
  }

  .msc-btn {
    height: $msv-checkout-button-height;
  }

  .fl-checkbox__option {
    margin-bottom: 8px;
  }

  &-section-container {
    padding-left: $msv-checkout-plain-container-padding-left;

    &__item.hidden {
      display: none;
    }
  }

  &__title {
    color: $green01;
    font-style: normal;
    font-weight: $msv-font-weight-600;
    font-size: $msv-checkout-title-font-size;
    line-height: $msv-checkout-title-line-height;
    align-items: center;
    padding-bottom: $msv-checkout-guided-card-padding-bottom;
  }

  &__main {
    flex: auto;
    max-width: $msv-checkout-main-max-width;
    padding-right: $msv-checkout-main-panel-padding-right;
  }

  &__main-control {
    .ms-checkout__btn-place-order {
      margin-right: 0px;
      width: 100%;
      border-radius: 25px;
      color: $white;
      background-color: $orange01;
      border: 1px solid transparent;
      text-transform: uppercase;

      &:disabled {
        background-color: $gray11;
        cursor: not-allowed;
      }
    }
    .ms-checkout__order-note-label{
      font-style: normal;
      font-weight: $msv-font-weight-600;
      font-size: $msv-checkout-order-note-title-font-size;
      line-height: $msv-checkout-guided-card-title-line-height;
      color: $green01;
      margin-left: calc(#{$msv-checkout-guided-card-title-text-padding-left} + #{$msv-checkout-guided-card-title-step-width});
    }
    .ms-checkout-order-note__input-text{
      height: 100px;
      width: calc(100% - 72px);
      margin: 15px 0 0 0;
      padding-left: 25px;
      color: #6a6a6a;
      overflow: hide;
      border: 1px solid #e0e0e0;
      border-radius: 7px;
      margin-left: calc(#{$msv-checkout-guided-card-title-step-width});
      resize: none;

      @include for-tablet{
        width: 100%;
        margin-left: 0px;
      }
    }
    .ms-checkout-order-note-limit-ctn{
      display:flex;
      flex-direction: row-reverse;
      margin-right: 42px;
      margin-bottom: 25px;

      @include for-tablet{
        width: 100%;
        margin-left: 0px;
      }
    }
  }

  &__side-control-first {
    display: block;
    background-color: $gray06;
    justify-content: center;
    margin-top: $msv-checkout-side-control-first-margin-top;
    padding-bottom: $msv-checkout-standard-margin-padding;

    .ms-checkout__btn-place-order,
    .ms-checkout__btn-keep-shopping {
      width: $msv-checkout-side-control-place-order-button-width;
      margin: $msv-checkout-side-control-first-margin-auto;
    }
  }

  &__side-control-second {
    display: none;
  }

  &__main-control {
    display: flex;
    flex-direction: column;
  }

  &__bulk-email {
    font-weight: 600;
    color: $gray08;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    padding: 0 32px;

    & input {
      color: $gray08;
      border: 1px solid $white02;
      min-width: 18px;
      min-height: 18px;
      margin-left: 0px;
    }

    & label {
      margin-left: 10px;
    }
  }

  &__btn-place-order {
    &.is-busy {
      &:before {
        @include msv-icon();
        content: $msv-Spinner;
        margin-right: $msv-checkout-icon-margin-right;
        -webkit-animation: spin 1s steps(8) infinite;
        animation: spin 1s steps(8) infinite;
      }
      cursor: progress;
    }
  }

  &__btn-keep-shopping {
    @include secondary-button();
  }

  &__side {
    width: $msv-checkout-side-width;
  }

  &__guided-form {
    margin-bottom: $msv-checkout-guided-form-margin-bottom;
  }

  &-payment-instrument__error {
    background-color: $red01;
    border: 1px solid $red01;
    max-width: $msv-checkout-payment-instrument-error-max-width;
    padding: $msv-checkout-payment-instrument-error-padding;

    &-title {
      margin-bottom: $msv-checkout-payment-instrument-error-title-margin-bottom;
      margin-top: $msv-checkout-payment-instrument-error-margin-top;
      &:before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        margin-right: $msv-checkout-icon-margin-right;
      }
      display: block;
      color: $white01;
    }

    &-message {
      display: block;
      color: $black01;
      margin-bottom: $msv-checkout-payment-instrument-message-margin-buttom;
      color: $white01;
    }
  }

  &__guided-card {
    border-top: $msv-checkout-guided-card-border-top-width solid $msv-checkout-inactive-color;
    padding-bottom: $msv-checkout-guided-card-padding-bottom;
    padding-top: $msv-checkout-guided-card-padding-top;
    color: $msv-checkout-inactive-color;

    &-header {
      display: flex;
    }

    &:last-child{
      border-bottom: $msv-checkout-guided-card-border-top-width solid $msv-checkout-inactive-color;
    }

    &.hidden {
      display: none;
    }

    &.updating:not(.closed),
    &.active {
      color: $msv-checkout-active-color;
      text-decoration: none;
    }

    &.closed &-title-step,
    &.ready &-title-step {
      background: $gray10;
      border: 2px solid $gray10;
    }

    &-title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: $msv-font-weight-600;
      font-size: $msv-checkout-guided-card-title-font-size;
      line-height: $msv-checkout-guided-card-title-line-height;
    }

    &-title-step {
      width: $msv-checkout-guided-card-title-step-width;
      border-radius: 50%;
      font-size: 12px;
      height: 30px;
      background: $green01;
      border: 2px solid $green01;
      color: $white01;
      text-align: center;
    }

    &-title-text {
      padding-left: $msv-checkout-guided-card-title-text-padding-left;
    }

    &-body {
      margin-top: $msv-checkout-guided-card-body-margin-top;
      padding-left: $msv-checkout-guided-card-padding-left;
      padding-right: $msv-checkout-guided-card-padding-right;

      &.hidden {
        display: none;
      }
    }

    &-btn-cancel,
    &-btn-save,
    .shareholder-place-order {
      @include secondary-button();
      background-color: $orange01;
      border-radius: 25px;
      border: 1px solid $orange01;
      color: $white01;
      min-height: $msv-checkout-button-height;
      padding: 0;
      width: 100%;
      text-transform: uppercase;

      &.is-submitting {
        &:before {
          @include msv-icon();
          content: $msv-Spinner;
          margin-right: $msv-checkout-icon-margin-right;
          -webkit-animation: spin 1s steps(8) infinite;
          animation: spin 1s steps(8) infinite;
        }
        cursor: progress;
      }
    }

    &-btn-edit {
      @include button-link();
      padding: $msv-checkout-edit-btn-padding;
      margin-left: $msv-checkout-edit-btn-margin-left;
      background-color: $orange01;
      border-radius: 25px;
      border: 1px solid $orange01;
      color: $white01;
      padding-left: 25px;
      padding-right: 25px;
      text-transform: uppercase;

      &:focus {
        text-decoration: none;
        outline: none;
      }
    }

    &-footer {
      margin-bottom: 25px;
      margin-top: $msv-checkout-guided-card-footer-margin-top;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__side-control {
    &-first,
    &-second {
      margin-bottom: $msv-checkout-side-control-margin-bottom;
    }
  }

  &__line-items {
    border: 1px solid $gray10;
    font-size: $msv-checkout-line-font-size;
    font-style: $msv-checkout-line-font-style;
    padding: $msv-checkout-line-items-padding;

    &-edit-cart-link {
      @include button-link($gray03);
      padding: $msv-checkout-edit-btn-padding;
      margin-left: auto;
      font-size: 12px;
      text-decoration: underline;
    }

    &-header {
      margin-bottom: $msv-checkout-line-header-margin-bottom;

      .ms-checkout__line-items-heading {
        color: $green01;
        display: inline;
        font-size: $msv-checkout-line-heading-font-size;
        font-weight: 700;
        line-height: $msv-checkout-line-heading-line-height;
      }

      .ms-checkout__line-items-edit-cart-link {
        float: right;
      }
    }

    .ms-checkout__line-item:not(:last-child) {
      margin-bottom: 2px;
    }

    &-delivery-group {
      .ms-checkout__line-items-group-title {
        font-weight: $msv-checkout-line-heading-font-weight;
      }

      .ms-checkout__pick-up-at-store {
        margin-top: $msv-checkout-pick-up-at-store-margin-top;
        margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

        .ms-checkout__store-label {
          color: $green01;
          font-size: $msv-checkout-line-heading-font-size;
          font-weight: 700;
          line-height: $msv-checkout-line-heading-line-height;
        }

        .ms-checkout__store-location {
          font-weight: $msv-font-weight-600;
          white-space: pre-line;
          color: $black02;
          font-size: 14px;
          line-height: 20px;
          margin-top: 20px;
        }

        .ms-checkout__change-store-location {
          color: $gray08;
          margin-top: 5px;
          font-weight: $msv-font-weight-600;
          float: right;
          text-decoration: underline;
          font-size: 12px;
        }
      }
    }
  }

  @include for-tablet {
    &__main {
      min-width: $msv-mobile-checkout-min-width;
      max-width: $msv-mobile-checkout-max-width;
      padding-right: $msv-mobile-checkout-padding-zero;
    }

    &__side {
      width: $msv-mobile-checkout-max-width;
    }

    &__guided-card {
      &-body {
        padding-left: $msv-mobile-checkout-padding-zero;
        padding-right: $msv-mobile-checkout-padding-zero;
      }
      &-btn-save {
        width: $msv-mobile-checkout-max-button-width;
      }
      &-btn-cancel {
        width: $msv-mobile-checkout-max-button-width;
      }
    }

    &__body {
      width: $msv-mobile-checkout-max-button-width;
    }
    margin-right: $msv-mobile-checkout-margin;
    margin-left: $msv-mobile-checkout-margin;

    &__main-control,
    &__side-control-first {
      padding-bottom: 50px;
    }

    &__side-control-second {
      display: block;
      .ms-checkout__btn-keep-shopping,
      .ms-checkout__btn-place-order {
        width: $msv-mobile-checkout-max-button-width;
      }
      .ms-checkout__btn-place-order {
        margin-top: $msv-mobile-checkout-place-order-margin-top;
      }

      .ms-checkout__btn-keep-shopping {
        margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
      }
    }
  }
}
.ms-checkout-terms-and-conditions__container{
  display: flex;
  div{
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  input[type='checkbox']{
    min-width: $msv-checkout-input-dimensions;
    min-height: $msv-checkout-input-dimensions;
    margin-left: 0px;
  }
}
.ms-checkout-terms-and-conditions__error-title{
  display: none;
} 
.ms-checkout-terms-and-conditions__error-message{
  font-family: $msv-alert-font-family;
  font-weight: $msv-font-weight-bold;
  font-size: $msv-alert-font-size;
  line-height: $msv-alert-line-height;
  text-transform: none;
  border-radius: $msv-alert-border-radius;
  position: relative;
  padding: $msv-alert-padding;
  margin-bottom: $msv-alert-margin-bottom;
  border: $msv-alert-border;
  color: $white01;
  background-color: $msv-alert-danger-background-color;
  border-color: $msv-alert-danger-background-color;
  margin-top: $msv-alert-line-margin-top;
  &:before {
    margin-right: $msv-alert-icon-margin;
    @include msv-icon();
    content: $msv-IncidentTriangle;
  }
}