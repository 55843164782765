// Import bootstrap css
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/common";

.row {
    margin: 0 !important;
}

div[class^="col"] {
    padding: 0;
    margin: 0;
}

// Container width
body .container {
    padding-left: 12px;
    padding-right: 12px;

    &.ph-0 {
        padding-left: 0;
        padding-right: 0;
    }

    &.tablet-only-ph-0 {
        @include for-tablet {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.desktop-only-ph-0 {
        @include for-desktop {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .col-md {
        @include for-desktop {
            padding-right: 16px;
        }
        @include for-tablet {
            flex-basis: initial;

            .ms-content-block__image img {
                width: auto;
            }
        }
    }

    &.last-content-block-full-width .col-md {
        &:last-child,
        &:nth-last-child(2) {
            padding-right: 0px;
        }
    }

    max-width: $site-max-width;
    width: 100%;
}
