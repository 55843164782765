.farmlands-account-modal {
    padding: 16px;

    &__account-icon {
        color: $green01;
        font-size: 30px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }

    &__account-heading {
        color: $green01;
        font-size: 20px;
        font-weight: bold;
        margin-block: unset;
        padding: 17px 0 25px;
        text-align: center;
    }
}
