$hero-link-to-link-spacing: 24px;

.farmlands-button-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__cta {
      text-align: center;
      padding-top: 29px;
      padding-bottom: 47px;
      min-width: 164px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-transform: uppercase;
    }
}

  .orange-button .farmlands-button-block__cta {
    .msc-cta__primary {
      background-color: $orange01;
      border: none;
      color: $white01;
    }
  }

  .green-button .farmlands-button-block__cta {
    .msc-cta__primary {
      background-color: transparent;
      border: 2px solid $green01;
      color: $green01;
    }
  }

  .farmlands-button-block.green-button  .farmlands-button-block__cta,
  .farmlands-button-block.orange-button .farmlands-button-block__cta {
  .msc-cta__primary {
    border-radius: 20px;
    height: auto;
    padding-right: $hero-link-to-link-spacing;
    padding-left: $hero-link-to-link-spacing;
    padding: 10px 36px;
    text-decoration: initial;
    font-size: 18px;
  }
}