.ms-product-specification {
    color: $black01;
    margin: 55px 55px 40px 55px;

    div>div {
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    &__table-row {
        th { 
            display: block;
            text-align: left;
            color: $gray08;
            @include font-content(600, 20px, 28px, none);
        }

        td {
            display: block;
            color: $gray08;
            @include font-content-s-height-l();
            font-size: 16px;
        }
    }
}