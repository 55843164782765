// font
$promo-banner-font-size: 16px;
$promo-banner-line-height: 20px;
$promo-banner-bottom-padding: 10px;
$promo-banner-top-padding: 10px;
$promo-banner-link-margin-left: 5px;

@mixin textAlignment($alignment) {
    .ms-promo-banner__content {
        justify-content: $alignment;
    }
}

.ms-promo-banner {
    background-color: $gray05;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $promo-banner-bottom-padding;
    padding-top: $promo-banner-top-padding;
    width: 100%;

    // Alignment modifiers
    @include for-tablet{
        flex-wrap: nowrap;
    }

    &.alignment__center {
      text-align: center;
    }
    &.alignment__left {
        @include textAlignment(flex-start);
    }
    &.alignment__right {
        @include textAlignment(flex-end);
    }
    &.alignment__center {
        @include textAlignment(center);
    }

    // Background color modifiers
    &.backgroundcolor__forestGreen {
        background-color: $green01;
    }
    &.backgroundcolor__orange {
        background-color: $orange01;
    }
    &.backgroundcolor__brightOrange {
        background-color: $orange04;
    }

    .msc-carousel__indicators {
        display: none;
    }

    .ms-promo-banner__content {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 10px;
        @include for-tablet{
        align-items: center;
        display: block;
        justify-content: center; 
        }
    }
    
    .ms-promo-banner__links {
        margin-left: $promo-banner-link-margin-left;
    }

    .ms-promo-banner__text,
    .ms-promo-banner__link {
        color: $white01;
        font-size: $promo-banner-font-size;
        font-style: normal;
        font-weight: $msv-font-weight-normal;
        line-height: $promo-banner-line-height;
    }

    .ms-promo-banner__link {
        border: 0px;
        text-decoration: underline;
    }

    .ms-promo-banner__carousel {
        width: 98%;

        @include for-mobile() {
            width: 91%;
        }
    }

    .ms-promo-banner__close-button {
        background-color: transparent;
        border: none;
        color: $white01;
        cursor: pointer;
        font-size: 20px;
        
        &:after {
            @include msv-icon();
            content: $msv-x-shape;
        }
        .ms-promo-banner__close-button__label {
            display: none;
        }
    }
}

@include for-tablet {

    .ms-promo-banner .ms-promo-banner__carousel {
        width: 95%;
    }
}
