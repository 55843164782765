.ms-checkout-shareholder-payment {
    color: $gray08;

    .fl-shareholder-title-container {
        align-items: center;
        display: flex;
        margin-bottom: 30px;
        margin-top: 25px;
    }

    .fl-shareholder-title {
        color: $green01;
        margin-left: 15px;
    }

    .fl-shareholder-contents {
        margin-left: 30px;
        margin-right: 30px;
    }

    .ms-content-block__text {
        @include add-icon($msv-Checkbox-Checked);
        margin-bottom: 30px;
        position: relative;
    
        &::before {
            color: $orange01;
            font-size: 24px;
            display: block;
            float: left;
        }
    
        h4 {
            margin-bottom: 15px;
        }
    
        .content {
            margin-left: 35px;
            max-width: 425px;
        }
    }
    
    .fl-shareholder-footer {
        margin-top: 55px;
        margin-bottom: 40px;

        p {
            color: $orange04;
            font-weight: 600;
            margin-left: 20px;
            margin-top: 7px;

            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    & > p {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.shareholder-warning {
    color: $orange04;
    width: 100%;

    a {
        color: inherit;
        text-decoration: underline;
    }
}