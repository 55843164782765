
.ms-subscribe {
  display:flex;
  justify-content: center;
  background-color: $white03;
  padding:25px 0 25px 0;
  box-sizing: border-box;

  &__details {
    display: flex;
    align-items: center;
    gap:45px;

    @include for-mobile {
      flex-wrap:wrap;
      gap:15px;
      justify-content: center;
      padding:0 10px;
    }

    &__text {
      color:$green01;
      font-size: $msv-font-size-m;
      font-weight:bold;
    }

    &__form {
      display: flex;
      align-items: center;
      gap:15px;

      @include for-mobile {
        flex-wrap:wrap;
        justify-content: center;
      }

      &__email {
        height: 35px;
        font-size: 15px;
        background-color: $white01;
        border: 1px solid $gray05;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 10px 40px 10px 15px;
        outline: none;
        color: $black01;

        @include for-desktop {
          min-width:300px;
        }

        @include for-mobile {
          width:100%
        }
      }

      &__submit {
        @include primary-button($orange01, $white01, var(--msv-buybox-primary-btn-border));
        border-radius: 15px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;
        height: 34px;
        min-width:170px;

        @include for-mobile {
          width:100%
        }
      }
    }
  }
}

