$msv-modal-padding: 20px;
$msv-modal-button-padding: 5px;
$msv-modal-z-index: 10010;

$msv-size-m: 360px;

$msv-header-height-offset: 75px;

.modal-open .msc-modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.msc-modal {
    align-items: center;
    display: flex !important; // Need to apply flex on open modal
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow: hidden;
    outline: 0;
    padding: 20px + $msv-header-height-offset 20px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $msv-modal-z-index;

    @include for-mobile {
        padding-top: 20px;
    }

    &.show &__dialog {
        transform: none;
    }

    &.fade &__dialog {
        transition: transform 0.3s ease-out;
    }

    &__dialog {
        outline: 0;
        pointer-events: none;
        position: relative;
    }

    &__content {
        color: $black01;
        background-clip: padding-box;
        background-color: $white01;
        display: flex;
        flex-direction: column;
        outline: 0;
        pointer-events: auto;
        position: relative;
        width: 100%;
    }

    &__header,
    &__body {
        margin-bottom: 22px;
    }

    &__close-button {
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;

        &:before {
            @include msv-icon();
            content: $msv-Cancel;
            font-size: 18px;

        }

        &:hover {
            cursor: pointer;
        }
    }
}

.msc-modal__backdrop {
    background-color: $black01;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $msv-modal-z-index - 1;

    &.show {
        opacity: 0.5;
    }
}

@media (prefers-reduced-motion: reduce) {
    .msc-modal.fade .msc-modal__dialog {
        transform: none;
    }
}
