.ms-breadcrumb
{
    padding-bottom: 40px;
    padding-top: 40px;
   .ms-breadcrumb_list
   {
       float: left;
       list-style: none;
       padding: 0;
       color: $gray03;
      
       .ms-breadcrumb-back-button
       {
           text-decoration: underline;
           cursor: pointer;
           float: left;
           margin-right: 10px;
           @include font-content-t($font-weight: $msv-font-weight-bold);
           @include add-icon($msv-ChevronLeft, before);
           &:before{
               padding-right: 5px;
           }
           
       }
   }
   .ms-breadcrumb_item
   {
       float: left;
       position: relative;
       margin:0;
       padding-right: 5px;
       @include font-content-t($font-weight: $msv-font-weight-600);
       a[data-no-click='true'] {
        cursor: default;
        pointer-events: none;
       }
      
       &.breadcrumb_item-current {
        @include font-content-t($font-weight: $msv-font-weight-bolder);
      
       }     

   }   
   &_link {
       color: $gray03;
       padding-right: 10px;
   }
}