$navigation-parent-menu-margin-left: 40px;
$navigation-parent-menu-margin-right: 40px;
$navigation-parent-menu-margin-top: 16px;
$navigation-parent-menu-margin-bottom: 16px;
$navigation-menu-list-margin-top: 12px;
$navigation-menu-list-min-width: 270px;
$navigation-sub-menu-padding-left: 32px;
$navigation-sub-menu-padding-right: 32px;
$navigation-menu-item-font-size: 16px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-item-height: 48px;
$navigation-menu-item-line-height: 45px;

//style presets
:root {
    --msv-nav-font-size: #{$navigation-menu-item-font-size};
    --msv-nav-title-font-size: #{$navigation-menu-item-font-size};
}

.pd-30 > .ms-nav {
    &.mobile-vp {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.ms-nav {
    color: $black01;
    width: 100%;
    &__list {
        z-index: 1000;
        &__item {
            font-size: var(--msv-nav-font-size);
            font-style: normal;
            white-space: nowrap;

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }
}

.ms-nav {
    &__list {
        display: block;
        flex-wrap: nowrap;
        &__mobile__container {
            border-bottom: 1px solid $gray05;
            padding-bottom: 13px;
            display: flex;
            @include for-tablet {
                border-bottom: none;
            }

            &__button {
                @include add-icon($msv-ChevronLeft);
                border: none;
                color: $black01;
                font-family: $msv-primary-font-family;
                font-size: 1.35em;
                padding: 20px 0;
                justify-content: left;
                width: 100%;

                &:not(.menu-level-2)::before {
                    padding-right: 20px;
                    content: $msv-arrow-left;
                }

                &.menu-level-2 {
                    &::before {
                        content: initial;
                    }
                    &::after {
                        content: $msv-carretUp !important;
                    }
                }

                &:focus {
                    outline-offset: -1px;
                }
            }
        }
        @include for-tablet {
            .parent &__item__button {
                font-size: 1.3em;
            }

            .child &__item__button {
                font-size: 1.15em;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &__item {
            &__image {
                display: none;
            }
            &__button {
                color: $black01;
                padding-left: 0;
                padding-right: 0;
                text-align: left;
                width: 100%;

                @include for-tablet {
                    color: $white01;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                &:after {
                    @include msv-icon();
                    content: $msv-carretRight;
                    display: inline-flex;
                    text-align: right;
                    position: absolute;
                    right: 20px;
                }
            }

            &__button,
            &__link {
                border: none;
                color: $black01;
                display: block;
                font-family: $msv-primary-font-family;

                &:focus {
                    outline-offset: -1px;
                }
            }
        }
    }
    > .ms-nav__list {
        width: 100%;
        outline: none;
        .ms-nav__list {
            height: 100vh;

            @include for-tablet {
                height: unset;
            }
        }
        > .ms-nav__list__item {
            > .ms-nav__list__item__button {
                @include for-tablet {
                    color: $black01;
                    background-color: $white01;
                }
            }
        }
    }
}

.ms-nav.child {
    @include for-desktop {
        position: absolute;
        top: 0;
    }
}

@include for-desktop {
    .ms-nav {
        display: flex;
        &__list {
            position: absolute;
            &__mobile__container {
                display: none;
            }

            &__item {
                &__button {
                    color: $black01;

                    @include for-tablet {
                        background-color: $green02;
                    }

                    &:after {
                        content: none;
                    }

                    &:focus {
                        border: none;
                        outline: none;
                    }
                }
            }
        }
        &__list {
            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                > .ms-nav__list__item {
                    font-weight: 600;
                    padding: 0;
                    line-height: 2;
                }

                .ms-nav__list {
                    position: relative;

                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: 0;
                    }
                }
            }
        }

        > .ms-nav__list {
            display: flex;
            position: relative;
            justify-content: space-evenly;
            min-width: 400px;

            .ms-nav__list {
                height: unset;
            }

            > .ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                    bottom: $navigation-parent-menu-margin-bottom;
                }
                > .ms-nav__list__item__button,
                .ms-nav__list__item__link {
                    @include for-tablet {
                        background-color: $green02;
                    }
                }

                .ms-nav__list__item__link {
                    color: $gray02;
                    justify-content: flex-start;
                    padding-left: $navigation-sub-menu-padding-left;
                    padding-right: $navigation-sub-menu-padding-right;
                    width: 100%;
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    // need to be placed after generic .ms-nav__list to overwrite the styles
    .green-button-first-menu > .ms-nav__list {
        justify-content: flex-start;
        min-width: inherit;
        flex-wrap: wrap;
    }
}

#main {
    .ms-nav {
        &__list {
            width: 100%;

            &__item {
                margin: 0;
                border: 2px solid transparent;
                border-radius: 20px;

                &__link {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-radius: 20px;

                    &:hover {
                        text-decoration: none;
                        background-color: $white03;
                    }
                }
            }
        }
        &.green-button-first-menu {
            .ms-nav__list__item {
                &:first-child {
                    border-color: $green01;
                    text-transform: uppercase;

                    .ms-nav__list__item__link {
                        color: $green01;
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    @include for-tablet {
        .ms-nav {
            padding-left: 50px;
            padding-right: 50px;

            .ms-nav__list__item__link {
                padding-left: 20px;
                margin-bottom: 10px;
                background-color: transparent;
                color: $black01;

                &:first-child {
                    border: 2px solid $green01;
                    color: $green01;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.ms-header__mobile-hamburger {
    &:focus {
        outline: none;
    }

    .drawer__button {
        background-color: $green02;
        color: $white01;
        font-size: 1.5em;
        padding: 25px 20px 25px 0;
        position: relative;
        justify-content: left;
        text-transform: uppercase;
        width: 100%;

        &[aria-expanded="false"] {
            &::after {
                content: "\25b6";
                padding-right: 10px;
                position: absolute;
                right: 0;
            }
            + .collapse {
                display: none;
            }
        }

        &[aria-expanded="true"] {
            margin-bottom: 10px;
            text-transform: none;

            &::before {
                content: "\2190";
                font-size: 1.5em;
                padding-right: 10px;
            }
        }
    }
}

// farmlands-navigation-menu styles
header {
    .ms-nav__list__item {
        font-size: 17px;
    }

    // First level nav
    .ms-nav > .ms-nav__list > .ms-nav__list__item {
        margin-left: 0;
        margin-right: 0;

        .ms-nav__list__item__button {
            width: auto;

            &[aria-expanded="true"] {
                ::after {
                    content: ">";
                }
            }
        }

        .ms-nav__submenu-icon {
            display: none;
            margin-left: 15px;
            &::after {
                @include msv-icon();
                color: $orange01;
                content: $msv-carretRight;
            }
        }

        // All nested lists
        & .ms-nav__list {
            display: block;
            background-color: $white01;
            top: 165px;
            position: fixed;

            // All nested items
            &__item {
                &__link {
                    display: flex;
                    padding: 0;
                    line-height: 2;
                    &:hover {
                        font-weight: 600;
                        text-decoration: none;
                        color: $green01;
                    }
                }
            }
        }

        //Second level nav
        & > .ms-nav__list {
            width: $navigation-menu-list-min-width;

            //Second level item
            & > .ms-nav__list__item {
                font-weight: 600;
                padding: 0;
                line-height: 2;

                // Third level nav (shown)
                & > .ms-nav__list {
                    border-left: 1px solid #e6e6e6;
                    padding-left: 50px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    left: $navigation-menu-list-min-width;
                    width: 550px;
                    position: absolute;
                    top: 0;
                    z-index: 10;

                    .ms-nav__list__item {
                        &__link {
                            display: inline;
                            position: relative;
                            @include underlineOnHover();
                        }
                    }
                }

                & > .ms-nav__list__item__link > .ms-nav__submenu-icon {
                    display: block;
                }
            }
        }
    }
}

.ms-nav__background-desktop {
    position: fixed;
    background-color: $white01;
    left: 0;
    height: 490px;
    width: 100%;
    display: flex;
    top: 165px;

    &--hide {
        background-color: rgba(50, 49, 48, 0.5);
    }
    @include for-tablet {
        display: none;
    }
}

.link-item-wrapper {
    padding: 20px;
}
