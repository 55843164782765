$product-collection-item-width: 258px;
$product-collection-font: $msv-primary-font-family;
$product-collection-heading-font-size: 30px;
$product-collection-heading-line-height: 33px;
$product-collection-heading-margin-bottom: 32px;
$product-collection-item-margin: 0 13px 0 0;
$product-collection-price-font-size: 16px;
$product-collection-price-line-height: 18px;
$product-collection-tooltip-left: 0px;
$product-collection-tooltip-top: 0px;
$product-collection-tooltip-inner-background-color: $black01;
$product-collection-tooltip-inner-font-size: 14px;
$product-collection-tooltip-inner-margin-top: -36px;
$product-collection-tooltip-inner-max-width: 200px;
$product-collection-tooltip-inner-padding: 4px 8px;

//style presets
:root {
  // heading
  --msv-product-collection-heading-font-size: var(--msv-body-font-size-xl);
  --msv-product-collection-heading-font-color: $black01;

  --msv-product-collection-text-size: var(--msv-body-font-size-m);
  --msv-product-collection-text-color: $black01;

  //flipper
  --msv-product-collection-flipper-bg: transparent;
  --msv-product-collection-flipper-font-color: $black01;
}

.container.product-collection-parent-container {
  @include for-desktop {
    max-width: 1246px;
  }
}

.ms-product-collection {
  margin-top: 52px;

  &__heading {
    @include font-content(
      $msv-font-weight-normal,
      $product-collection-heading-font-size,
      $product-collection-heading-line-height
    );
    color: $green01;
    margin-bottom: $product-collection-heading-margin-bottom;

    @include for-desktop {
      text-align: center;
    }

    @include for-mobile {
      width:75%;
      margin-bottom:20px;
    }
  }

  &__item {
    display: inline-block;
    height: 365px;
    max-width: none;
    margin: $product-collection-item-margin;
    overflow: hidden;
    text-align: left;
    vertical-align: top;
    width: $product-collection-item-width;
    background-color: $white01;

    &:last-child {
      margin-right: 0;
    }

    @include for-tablet {
      margin-bottom: 20px;
    }
  }

  &__items {
    @include for-desktop {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }



  .msc-ss-carousel {
    @include for-desktop {
      padding-left: 70px;
      padding-right: 70px;
    }
    .msc-flipper {
      color: $green01;
      top: -45px;

      &:hover {
        color: var(--msv-product-collection-flipper-font-color);
      }

      i::before {
        font-size: 24px;
      }
    }

    .msc-ss-carousel-strip {
      text-align: center;
    }

    @include for-tablet {
      .msc-ss-carousel__flipper {
        right: 50px;

        &--next {
          right: 0px;
        }
      }
    }

    @include for-desktop {
      &-item {
        flex: 1;
      }

      .msc-ss-carousel__flipper--next {
        right: 50px;
      }
    }
  }

  .msc-product {
    overflow: hidden;
    display: block;
    &__details {
      padding: 0 32px 24px;
    }

    &__image {
      padding: 10px;
    }

    &__title {
      @include font-content($msv-font-weight-normal, 13px, 27px);
      color: $black02;
      margin-top: 0;
      text-decoration: none;
      margin-bottom: 8px;
      white-space: initial;

      &:hover {
        text-decoration: underline;
      }
    }

    .msc-empty_image {
      @include image-placeholder(238px);
    }
  }

  .msc-price {
    color: $gray08;
    display: inline-block;
    font-family: $product-collection-font;
    font-size: $product-collection-price-font-size;
    font-weight: bold;
    line-height: $product-collection-price-line-height;
    text-transform: none;

    &__strikethrough {
      @include font-content-m(normal);
    }

    &__savings {
      color: $orange01;
    }
  }

  .msc-rating__count {
    border-bottom: none;
  }

  .msc-tooltip {
    display: block;
    left: $product-collection-tooltip-left;
    position: absolute;
    top: $product-collection-tooltip-top;

    &.msc-bs-tooltip-bottom {
      padding: $tooltip-arrow-height 0;

      .msc-tooltip-inner {
        margin-top: 0px;
      }
    }

    .msc-tooltip-inner {
      background-color: $product-collection-tooltip-inner-background-color;
      color: $white01;
      font-size: $product-collection-tooltip-inner-font-size;
      margin-top: $product-collection-tooltip-inner-margin-top;
      max-width: $product-collection-tooltip-inner-max-width;
      padding: $product-collection-tooltip-inner-padding;
      text-align: center;
    }
  }
}

//Styling to hide price(free) on product details page, related products module.
.hide-price {
  .msc-price {
    display: none;
  }
}

.round-corners {
  border-radius: 5px;
}

.gray-background {
  background-color: $white03;
  position: relative;
  z-index: 1;
}

.bg-swoosh {
  background-image: url(https://images-ap-prod.cms.commerce.dynamics.com/cms/api/nfldptnkgf/imageFileData/MFid4);
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative;

  @include for-desktop {
    padding-bottom: 70px;
    background-size: 100% 555px;
  }

  @include for-tablet {
    padding-bottom: 50px;
    background-size: 100% 50px;
  }
}

.product-collection-parent-container .ms-content-block.orange-button {
  // min-height: initial;

  .ms-content-block__details {
    display: block;
    position: relative;
  }

  .ms-content-block__cta {
    padding-top: 29px;
    padding-bottom: 47px;

    .msc-cta__primary {
      font-family: $msv-primary-font-family;
      background-color: $orange01;
      color: $white01;
      border: none;
      font-size: 13px;
      line-height: 15px;
    }
  }
}
