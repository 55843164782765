$msv-layout-h-padding-s: 16px;
$msv-layout-h-padding-l: 120px;
$msv-layout-v-padding-s: 16px;
$msv-layout-v-padding-l: 80px;
$msv-layout-page-clamp-width: $site-max-width;
$msv-layout-container-width: $site-max-width;
$ms-header__topbar-height: 75px;
$ms-header__secondarynav-height: 90px;
$ms-footer__list-item-height: 20px;
$Footer__top-container-height: 356px;
$Footer__bottom-container-height: 40px;

// Limit the max width of an element with margin on either side of the element
@mixin msv-layout-page-clamp {
  max-width: $msv-layout-page-clamp-width;
  margin: auto;
}

// Prevent content from touch the edge of the screen with padding on either side of the element
@mixin msv-layout-page-side-padding {
  padding-left: $msv-layout-h-padding-s;
  padding-right: $msv-layout-h-padding-s;

  @include for-desktop {
    padding-left: $msv-layout-h-padding-l;
    padding-right: $msv-layout-h-padding-l;
  }
}

@mixin msv-layout-content-block-padding {
  padding: $msv-layout-v-padding-s $msv-layout-h-padding-s;

  @include for-desktop {
    padding: $msv-layout-v-padding-l $msv-layout-h-padding-l;
  }
}

main{
  min-height: calc(100vh - #{$ms-header__topbar-height} - #{$ms-header__secondarynav-height} - #{$ms-footer__list-item-height} - #{$Footer__top-container-height} - #{$Footer__bottom-container-height});
}