$border-bottom: 1px solid $gray05;
$msv-order-history-padding-bottom: 58px;
$msv-order-history-order-information-margin-bottom: 13px;
$msv-order-history-header-padding-bottom: 20px;
$msv-order-history-header-border-bottom: $border-bottom;
$msv-order-history-order-count-margin-left: 10px;
$msv-order-history-sales-order-border-bottom: $border-bottom;
$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-sales-id-border-right: $border-bottom;
$msv-details-order-information-channel-name-margin-bottom: 8px;
$msv-order-history-sales-lines-margin-top: 8px;
$msv-order-history-sales-line-picture-margin-right: 12px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 77px;
$msv-order-history-sales-line-picture-height: 77px;
$msv-order-history-empty-image-width: 75px;
$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-created-date-border-right: $border-bottom;
$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-count-border-right: $border-bottom;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-order-history-group-margin-bottom: 20px;
$msv-order-history-group-delivery-heading-margin-right: 3px;
$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;
$msv-order-history-btn-more-margin-top: 20px;

.ms-order-history {
    color: $black01;
    padding-bottom: $msv-order-history-padding-bottom;

    &__heading {
        @include font-content-xl();
        color: $green01;
        font-size: 28px;
        font-weight: 600;
        display: inline-block;
    }

    &__header {
        align-items: center;
        border-bottom: $msv-order-history-header-border-bottom;
        display: flex;
        padding-bottom: $msv-order-history-header-padding-bottom;
    }

    &__order-count {
        color: $gray03;
        display: inline-block;
        font-size: 18px;
        margin-left: $msv-order-history-order-count-margin-left;
    }

    &__sales {
        &-order {
            padding: 20px 0;
            position: relative;
            border-bottom: $msv-order-history-sales-order-border-bottom;
        }

        &-status {
            display: none;
        }
    }

    &__order-information {
        margin-bottom: $msv-order-history-order-information-margin-bottom;
    }

    &__order-information-channel-name {
        color: $green01;
        display: block;
        font-size: $msv-heading-text-size;
        font-weight: $msv-font-weight-600;
        line-height: $msv-heading-line-height;
        margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
    }

    &__order-information-sales-id {
        padding-right: $msv-order-history-order-information-sales-id-padding-right;
        border-right: $msv-order-history-order-information-sales-id-border-right;
    }

    &__order-information-channel-reference-id {
        display: block;
    }

    &__sales-lines {
        margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        margin-top: $msv-order-history-sales-line-picture-margin-top;
        width: $msv-order-history-sales-line-picture-width;
        height: $msv-order-history-sales-line-picture-height;
        padding: 2px;
        border: 1px solid $gray06;
    }

    &__btn-order-details {
        @include secondary-button($orange01, $white01, $orange01);
        border-radius: 17px;
        padding: 0 20px;
        position: absolute;
        right: 0;
        top: 40px;

        &:hover {
            text-decoration: none;
        }

        @include for-tablet {
            width: 100%;
            position: unset;
        }
    }

    &__order-information-created-date {
        padding-right: $msv-order-history-order-information-created-date-padding-right;
        padding-left: $msv-order-history-order-information-created-date-padding-left;
        border-right: $msv-order-history-order-information-created-date-border-right;
    }

    &__order-information-count {
        padding-right: $msv-order-history-order-information-count-padding-right;
        padding-left: $msv-order-history-order-information-count-padding-left;
        border-right: $msv-order-history-order-information-count-border-right;
    }

    &__order-information-amount {
        padding-left: $msv-order-history-order-information-amount-padding-left;
    }

    &__groups {
        margin-bottom: $msv-order-history-group-margin-bottom;
    }

    &__group {
        margin-bottom: $msv-order-history-group-margin-bottom;

        &-delivery {
            &-heading {
                font-weight: $msv-font-weight-bold;
                margin-right: $msv-order-history-group-delivery-heading-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__address {
        display: none;
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
    }

    &__btn-more {
        @include primary-button();
        margin-top: $msv-order-history-btn-more-margin-top;

        &.is-busy {
            cursor: progress;

            &:before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: $msv-checkout-icon-margin-right;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }
        }
    }

    &__btn-keep-shopping {
        background-color: $orange01;
        border: 1px solid $orange01;
        border-radius: 17px;
        color: $white01;
        font-size: 13px;
        height: 34px;
        padding: 0 20px;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
        }

        @include for-tablet {
            width: 100%;
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-history-empty-image-width);
    }
}
