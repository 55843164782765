$content-block-min-height: 100px;
$hero-body-font-size: 16px;
$hero-body-line-height: 21px;
$hero-body-font-weight: normal;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 24px;
$hero-content-button-margin-top: 32px;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-font-weight: normal;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 24px;

:root {
  // heading
  --msv-content-block-heading-font-size: #{$hero-heading-font-size};
  --msv-content-block-heading-font-color: $green01;

  // Text theme
  --msv-content-block-textheme-dark: $green01;
  --msv-content-block-textheme-light: $white01;

  --msv-content-block-text-font-size: var(--msv-body-font-size-m);

  // primary button
  --msv-content-block-primary-btn-bg: $blue01;
  --msv-content-block-primary-btn-font-color: #00553d;
  --msv-content-block-primary-btn-border: $blue01;
}

@mixin overlay-text-on-image {
  .ms-content-block__details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
}
.ms-content-block {
  min-height: $content-block-min-height;

  .ms-content-block__link {
    display: flex;

    @include for-tablet  {
      justify-content: center;
    }
  }

  .ms-content-block__image img {
    @include for-tablet  {
      margin: 0 auto;
    }
  }

  &[data-m-layout='full-width'] {
    position: relative;

    .ms-content-block__image,
    .ms-content-block__image img {
      width: 100%;
    }

    .ms-content-block__details,
    &.textplacement__left .ms-content-block__details {
      text-align: left;
      align-items: flex-start;
    }

    &.textplacement__center .ms-content-block__details {
      align-items: center;
      text-align: center;
    }

    &.textplacement__right .ms-content-block__details {
      text-align: right;
      align-items: flex-end;
    }
    &.category-height {
      max-height: 350px;
    }

    .ms-content-block__title {
      color: $green01;
      font-weight: $msv-font-weight-normal;
      font-size: var(--msv-content-block-heading-font-size);
      line-height: $hero-heading-line-height;
      max-width: $hero-heading-max-length;
    }

    .ms-content-block__text {
      @include font-content($msv-font-weight-normal, var(--msv-content-block-text-font-size), $hero-body-line-height);
      color: $green01;
      max-width: $hero-body-max-length;
      p {
        color: $black01;
      }
    }

    &.texttheme__light .ms-content-block__title, &.texttheme__light .ms-content-block__text {
      color: $white01;
    }

    &.texttheme__dark .ms-content-block__title, &.texttheme__dark .ms-content-block__text  {
      color: $green01;
    }

    /* body should have top margin only when there's something above it */
    * + .ms-content-block__text {
      margin-top: $hero-content-text-margin-top;
    }

    .ms-content-block__cta {
      min-width: 164px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-transform: uppercase;
      .msc-cta__primary {
        height: 19px;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
      }
    }

    /* links should have top margin only when there's something above it */
    * + .ms-content-block__cta {
      margin-top: $hero-content-button-margin-top;
    }

    .ms-content-block__details {
      @include msv-layout-page-side-padding;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;

      .msc-cta__primary {
          // @include primary-button(var(--msv-content-block-primary-btn-bg), var(--msv-content-block-primary-btn-font-color), var(--msv-content-block-primary-btn-border));
          margin: $hero-link-to-link-spacing / 2;
          font-size: 14px;
          line-height: 20px;
        }

      .msc-cta__secondary {
        margin: $hero-link-to-link-spacing / 2;
      }
    }

    /**
    * Text over image by default only in modern browsers.
    * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
    */
    @supports (object-fit: cover) {
      .ms-content-block__image {
        object-fit: cover;
      }
    }

    /**
    * When the image is tall enough for IE 11 to display text on top of it.
    */
    @media screen and (min-width: 900px) {
      @include overlay-text-on-image;
    }
  }
}

@include for-tablet  {
  .ms-content-block {
    &[data-m-layout='full-width'] {
      &.texttheme__light .ms-content-block__title,
      &.texttheme__light .ms-content-block__text,
      &.texttheme__dark .ms-content-block__title,
      &.texttheme__dark .ms-content-block__text,
      .ms-content-block__title,
      .ms-content-block__text {
        color: $green01;
      }
    }
  }
}

@at-root.orange-button .ms-content-block__cta {
  .msc-cta__primary {
    background-color: $orange01;
    border: none;
    color: $white01;
  }
}

@at-root.white-button .ms-content-block__cta {
  .msc-cta__primary {
    color:$white01;
  }
}
@at-root.green01-button .ms-content-block__cta {
  .msc-cta__primary {
    color:$green01;
  }
}

.ms-content-block.green-button  .ms-content-block__cta,
.ms-content-block.orange-button .ms-content-block__cta {
  .msc-cta__primary {
    border-radius: 20px;
    height: auto;
    padding-right: $hero-link-to-link-spacing;
    padding-left: $hero-link-to-link-spacing;
    padding: 8px 36px;
    text-decoration: initial;
  }
}

.ms-content-block.green-button  .ms-content-block__cta {
  .msc-cta__primary {
    background-color: transparent;
    border: 2px solid $green01;
    color: $green01;
  }
}

.last-content-block-full-width .col-12:last-child {
  flex-basis: 100% !important;
}

.payment-section {
  @at-root body & {
    @include for-desktop {
      max-width: 1250px;
    }
  }

  .ms-content-block__text {
    font-size: 16px;
    line-height: 22px;
  }
  .texttheme__dark .ms-content-block__text {
    color: $green01;
  }

  .ms-content-block {
    min-height: auto;

    @include for-desktop {
      display: flex;
    }

    @include for-tablet{
      display:flex;
      flex-direction: column;
    }
  }

  .ms-content-block__cta {
    padding-top: 16px;
  }

  .col-md-3 {
    @include for-tablet {
      display: flex;
      flex-basis: calc(50% - 70px);
      margin: 25px 29px;
    }

    .ms-content-block__details {
      @include for-desktop {
        width: 160px;
      }

      @include for-tablet {
        padding-top: 20px;
      }
    }

    .ms-content-block__image {
      width: 75px;

      @include for-desktop {
        padding-right: 25px;
      }
      @include for-tablet {
        margin-right: 50%;
      }

      img {
        width: auto;
        height: 42.5px
      }
    }
  }
}

.msc-carousel {
  .ms-content-block__image,
  .ms-content-block__image img {
    height: 485px;
  }
}
