$msv-sign-up-border-left-color: $gray04;
$msv-sign-in-border-color: $gray06;
$msv-forgot-password-color: $gray03;
$msv-sign-in-social-accounts-border: 1px dashed $msv-sign-in-border-color;
$msv-sign-up-border: 1px solid $msv-sign-up-border-left-color;
$msv-sign-in-font-size: 14px;
$msv-sign-in-line-height: 20px;
$msv-sign-in-link-font-size: 12px;
$msv-sign-in-link-line-height: 26px;
$msv-sign-in-button-margin-bottom: 60px;
$msv-sign-in-button-margin-top: 20px;
$msv-sign-in-facebook-color: #3B5998;
$msv-sign-input-height: 32px;
$msv-sign-in-account-item-margin-top: 20px;
$msv-sign-in-input-margin-top: 4px;
$msv-sign-in-input-padding: 8px;
$msv-sign-in-section-spacing: 90px;
$msv-sign-in-section-spacing-m: 40px;
$msv-sign-in-social-accounts-padding: 40px 0;
$msv-sign-in-social-button-padding: 8px 16px;
$msv-sign-in-social-accounts-button-margin-bottom: 20px;
$msv-sign-in-social-accounts-text-margin-left: 16px;
$msv-sign-in-link-margin-top: 8px;
$msv-sign-in-sign-up-description-margin-top: 20px;
$msv-sign-in-sign-up-description-margin-bottom: 32px;
$msv-sign-in-message-padding: 20px;
$msv-sign-in-message-margin-top: 20px;
$msv-sign-in-error-border: 1px solid $white01;
$msv-sign-in-error-bg-color: $red01;
$msv-sign-in-container-margin: 50px 100px;
$msv-sign-in-container-margin-m: 20px;

.ms-sign-in {
  @include font-content-m();

  &__container {
    display: flex;
    margin: $msv-sign-in-container-margin;
  }
  
  &__social-account-picture {
    display: inline-block;
    vertical-align: middle;
  }

  &__sign-in-heading, &__sign-up-heading {
    @include font-content-xl();
  }

  &__sign-in-section {
    flex: 1;
    margin-right: $msv-sign-in-section-spacing;
  }

  &__sign-up-section {
    border-left: $msv-sign-up-border;
    flex: 1;
    padding-left: $msv-sign-in-section-spacing;
  }

  &__account-item {
    margin-top: $msv-sign-in-account-item-margin-top;

    &-input {
      @include form-input-el();
      margin-top: $msv-sign-in-input-margin-top;
      width: 100%;
    }
  }

  &__forget-password {
    display: inline-block;
    color: $msv-forgot-password-color;
    @include font-content-s();
    margin-top: $msv-sign-in-link-margin-top;
    text-decoration: underline;
  }

  &__sign-in-button {
    @include primary-button();
    margin-bottom: $msv-sign-in-button-margin-bottom;
    margin-top: $msv-sign-in-button-margin-top;
    width: 100%;
  }

  &__sign-up-link {
    @include primary-button();
    width: 100%;
  }

  &__social-accounts {
    border-top: $msv-sign-in-social-accounts-border;
    padding: $msv-sign-in-social-accounts-padding;

    button:last-child {
      margin-bottom: 0px;
    }
  }

  &__social-account {
    margin-bottom: $msv-sign-in-social-accounts-button-margin-bottom;
    width: 100%;

    &-FacebookExchange {
      background-color: $msv-sign-in-facebook-color;
      color: $white01;
      padding: $msv-sign-in-social-button-padding;
    }

    &-MicrosoftAccountExchange {
      background-color: $white01;
      border: 1px solid $black01;
      color: $black01;
      padding: $msv-sign-in-social-button-padding;
    }

    &-text {
      margin-left: $msv-sign-in-social-accounts-text-margin-left;
    }
  }

  &__sign-up-description {
    margin-top: $msv-sign-in-sign-up-description-margin-top;
    margin-bottom: $msv-sign-in-sign-up-description-margin-bottom;
  }

  &__page-error {
    background-color: $msv-sign-in-error-bg-color;
    border:  $msv-sign-in-error-border;
    color: $white01;
    display: none;
    margin-top: $msv-sign-in-message-margin-top;
    padding: $msv-sign-in-message-padding;
  }

  @include for-tablet {
    &__container {
      display: block;
      margin: $msv-sign-in-container-margin-m;
    }

    &__sign-in-section {
      margin-right: 0px;
    }

    &__sign-up-section {
      border-left: none;
      border-top: $msv-sign-up-border;
      padding-left: 0px;
      padding-top: $msv-sign-in-section-spacing-m;
    }
  }
}
