// ---------------------------------------------------------------------------
// Headers
// ---------------------------------------------------------------------------

h1, h2, h3, h4, h5, h6 {
    font-family: $msv-primary-font-family;
    margin-top: 0;
}

h1 {
    font-size: $rem28;
    line-height: $rem32;
    margin-bottom: 32px;

    @include for-tablet {
        font-size: $rem32;
        line-height: $rem20*2;
        margin-bottom: 40px;
    }
}

h2 {
    font-size: $rem24;
    line-height: $rem26;
    margin-bottom: 26px;

    @include for-tablet {
        line-height: $rem32;
        margin-bottom: 32px;
    }
}

h3 {
    font-size: $rem20;
    line-height: $rem24;
    margin-bottom: 24px;
}

h4, h5, h6 {
    font-size: $rem18;
    line-height: $rem22;
    margin-bottom: 22px;
}
