$msv-checkout-billing-address-margin: 21.5px;

$msv-checkout-input-margin-left: 0px;
$msv-checkout-input-dimensions: 18px;

$msv-checkout-shipping-address-label-margin-bottom: 12px;
$msv-checkout-shipping-address-checkbox-margin-left: 8px;

$msv-checkout-address-detail-margin-left: 0px;


.ms-checkout-billing-address {
  font-size: $msv-text-size;
  line-height: $msv-text-line-height;
  &__heading {
    margin-bottom: $msv-checkout-billing-address-margin;
    color: #00553d;
    font-size: 18px;
    font-weight: normal;
    margin-top: 26px;
    line-height: 26px;
  }

  .msc-address-detail {
    margin-top: $msv-checkout-billing-address-margin;
    margin-left: $msv-checkout-address-detail-margin-left;
  }

  &__shipping-address-label {
    display: flex;
    margin-bottom: $msv-checkout-shipping-address-label-margin-bottom;
  }

  &__input {
    min-width: $msv-checkout-input-dimensions;
    min-height: $msv-checkout-input-dimensions;
    margin-left: $msv-checkout-input-margin-left;
  }

  &__shipping-address-checkbox-text {
    margin-left: $msv-checkout-shipping-address-checkbox-margin-left;
    align-self: center;
    color: $gray08;
  }
}
