$secondary-nav-background-color: $white01;
$favourite-svg-url: "https://files-ap-prod.cms.commerce.dynamics.com/cms/api/qwvspcbgds/binary/MF4RY";
$shopping-basket-svg-url: "https://files-ap-prod.cms.commerce.dynamics.com/cms/api/qwvspcbgds/binary/MF4S1";
$search-svg-url: "https://files-ap-prod.cms.commerce.dynamics.com/cms/api/gwwrrjnmnw/binary/MF2I4";

.disable-scroll {
    overflow: hidden;
}

.ms-header {
    font-family: $msv-primary-font-family;
    padding: 0;

    &__account-links-container {
        background-color: $green01;
        padding: 10px 30px 28px;
    }

    &__account-links {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    &__account-icon {
        color: $green01;
        font-size: 30px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }

    &__account-heading {
        color: $green01;
        font-size: 20px;
        font-weight: bold;
        margin-block: unset;
        padding: 17px 0 25px;
        text-align: center;

        @include for-desktop {
            padding: 5px 0 10px;
        }
    }

    &__account-info-wrapper {
        display: inline-flex;
    }

    &__account-info-dropdown {
        display: table;
        margin-right: 10px;
    }

    &__account-name-dropdown,
    &__account-number-dropdown,
    &__select-account,
    &__active-account-text {
        color: $green01;
        font-size: 13px;
        font-weight: 400;

        @include for-desktop {
            font-size: 14px;
        }
    }

    &__account-name-dropdown,
    &__account-number-dropdown {
        display: block;
    }

    &__account-number-dropdown {
        padding-top: 3px;
    }

    &__active-circle {
        width: 5px;
        height: 5px;
        background: $green06;
        margin-top: 7px;
        border-radius: 50%;
        margin-right: 3px;
    }

    &__select-account,
    &__active-account-text {
        display: inline-flex;
        float: right;
    }

    &__select-account {
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    &__account-selector {
        background-color: $white;
        max-height: 350px;
        overflow-y: scroll;
        padding: 12px;
        width: 100%;

        @include for-desktop {
            padding: 24px;
        }

        &--hide {
            padding: 0;
        }
    }

    &__account-item {
        padding: 10px;
        display: flex;
        justify-content: space-between;

        @include for-desktop {
            padding: 25px;
        }

        &:not(:first-of-type):not(:last-of-type) {
            border-bottom: 1px solid #ebebeb;
        }
    }

    &__active-account {
        background-color: #f6f6f6;
    }

    &__login-icon {
        font-size: 16px;
        margin-top: 2px;
    }

    &__more-accounts {
        color: $green01;
        font-size: 12px;
        font-weight: 400;
        padding: 20px 0;
        text-align: center;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    &__modal-overlay {
        background-color: rgba(1, 41, 30, 0.5);
        height: 100vh;
        left: 0;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;
    }

    &__close-popover-button {
        background-color: transparent;
        color: $green01;
        position: relative;
        float: right;

        &--white {
            color: $white01;
        }

        @include for-desktop {
            display: none;
        }
    }

    &__footer-link-container {
        margin-bottom: 15px;
        padding: 0;
        text-align: center;

        a {
            color: $white01;
            font-size: 13px;
            font-weight: bold !important;
            padding: 0 !important;
            text-transform: uppercase;
        }
    }

    &__container-nav {
        width: 43%;
    }

    &__wrapper {
        position: fixed;
        top: 0;
        transform: translateY(0px);
        transition: transform 0.3s ease, height 0.3s ease, opacity 0.3s ease, border-color 0.3s;
        width: 100%;
        z-index: 10000;

        &--hidden {
            // Only apply transform in desktop view
            @include for-desktop {
                -webkit-transform: translateY(-75px) !important;
                transform: translateY(-75px) !important;
            }
        }

        &--fixed {
            transform: none;
        }
    }

    &__container {
        position: relative;
    }

    &__container-topbar {
        background-color: $green01;
        width: 100%;
    }

    &__container-secondarynav {
        background-color: $white01;
        box-shadow: 0px 10px 5px -6px rgba(0, 0, 0, 0.1);
        position: relative;
        width: 100%;
    }

    &__topbar {
        align-items: center;
        background-color: $green01;
        display: flex;
        height: 75px;
        margin: 0 auto;
        max-width: $site-max-width;
        padding: 0 12px;

        @include for-mobile {
            padding: 12px 24px;
        }
    }

    &__logo {
        @include for-desktop {
            margin-right: 60px;
        }

        img {
            width: 82px;
        }
    }

    &__account-name {
        @include for-mobile {
            display: none;
        }

        text-transform: capitalize;
        color: $white01;
        align-self: center;
        font-weight: 600;
        font-size: 13px;
        margin-right: 12px;
    }

    &__topbar-nav {
        display: flex;
        margin-right: auto;
    }

    &__topbar-nav-mobile {
        background-color: $green01;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        border: 1px solid transparent;
        border-top-color: $green02;
        padding: 0px 25px;
        margin-top: 75px;
    }

    &__topbar-nav-mobile-wrapper {
        display: flex;
    }

    &__topbar-nav-mobile-icon {
        color: $white01;
        margin-right: 14px;
        align-self: center;
    }

    &__topbar-nav-mobile-item {
        color: $white01;
        display: flex;
        align-items: center;
        margin: 15px 0;
    }

    &__topbar-nav-mobile-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__topbar-nav-mobile-row {
        color: $white01;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        &:first-of-type {
            border-right: solid $gray05 1px;
        }
    }

    &__topbar-nav-mobile-row-container {
        background-color: $gray06;
        display: flex;
        height: 50px;
        width: 100%;
        border-bottom: solid $gray05 1px;
    }

    &__topbar-nav-mobile-search {
        margin-top: 20px;
        width: 100%;

        // Overwrite search scss for mobile menu view
        .ms-search {
            align-items: center;
            background-color: $white01;
            border: 1px solid $white02;
            border-radius: 5.5px;
            flex-direction: row-reverse;

            &__form {
                width: 100%;
            }

            &__form-control {
                border: none;
                line-height: 27px;
                padding-left: 20px;
                width: 100%;
            }

            &__form-submitSearch {
                border-left: solid 1px $white02;
            }

            &__form-cancelSearch {
                display: none;
            }
        }
    }

    &__topbar-account-wrapper {
        display: flex;
        align-items: center;
    }

    &__nav-icon {
        color: $white01;
        margin: 10px;
        cursor: pointer;

        @include for-desktop {
            display: none;
        }
    }

    &__topbar-nav-item {
        color: $white01;
        display: block;
        font-size: 18px;
        font-weight: 500;
        line-height: 125%;
        position: relative;
        text-decoration: none;

        &:not(:last-child) {
            margin-right: 48px;
        }

        &:hover {
            text-decoration: none;
        }

        @include underlineOnHover();

        @include for-tablet {
            display: none;
        }
    }

    &__contactus-container {
        position: relative;

        &:hover {
            .ms-header__contactus-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__contactus-icon {
        display: flex;
        align-items: center;

        @include for-mobile {
            display: none;
        }
    }

    &__contactus-icon-img {
        margin-right: 26px;
    }

    &__contactus-tooltip {
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 5%;
        font-weight: 600;
        visibility: hidden;
        background-color: #fff;
        color: $green01;
        text-align: center;
        padding: 6px 14px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        white-space: nowrap;
        position: absolute;
        z-index: 3;
        top: 35px;
        left: -30px;
        opacity: 0;
        transition: opacity 0.3s;

        @include for-tablet {
            top: 38px;
            left: -70%;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border: 5px solid transparent;
            border-bottom-color: #fff;
        }
    }

    // &__signout-button {
    //     align-items: center;
    //     background-color: transparent;
    //     border: 1px solid $white01;
    //     border-radius: 60px;
    //     color: $white01;
    //     cursor: pointer;
    //     display: flex;
    //     font-size: 13px;
    //     font-weight: 700;
    //     height: 33px;
    //     justify-content: center;
    //     letter-spacing: 4%;
    //     line-height: 100%;
    //     margin: 0 auto;
    //     text-transform: uppercase;
    //     width: 186px;
    // }

    // &__signout-button-text {
    //     color: $white01;
    //     font-size: 13px;
    //     font-weight: bold !important;
    //     margin-right: 10px;
    //     padding: 0 !important;
    //     text-transform: uppercase;
    // }

    // &__signin-button:hover {
    //     text-decoration: none;
    // }

    // &__signin-button-text {
    //     align-items: center;
    //     background: #f8971d;
    //     border: none;
    //     border-radius: 60px;
    //     color: $white01;
    //     cursor: pointer;
    //     display: flex;
    //     font-family: inherit;
    //     font-size: 13px;
    //     font-weight: 700;
    //     height: 39px;
    //     justify-content: center;
    //     line-height: 100%;
    //     text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    //     width: 118px;
    //     text-transform: uppercase;
    // }

    // &__account-info {
    //     display: flex;

    //     .ms-header__profile-button {
    //         background-color: $orange01;
    //         color: $white01;
    //         text-transform: uppercase;
    //         font-weight: 600;
    //         border-radius: 16px;
    //         width: 32px;
    //     }

    //     .ms-header__account-info-content {
    //         z-index: 1001;
    //         background-color: $white01;
    //         // to override OOB login-dropdown menu position
    //         top: 21px !important;

    //         @include for-mobile {
    //             left: 5% !important;
    //             top: 75px !important;
    //             transform: none !important;
    //             width: 90%;
    //         }

    //         @include for-desktop {
    //             left: -20px !important;
    //         }

    //         .msc-popover-inner {
    //             display: flex;
    //             flex-direction: column;
    //         }

    //         a {
    //             font-weight: 600;
    //             color: $white01;
    //             text-decoration: none;

    //             @include for-desktop {
    //                 padding: 20px;
    //             }
    //         }
    //     }
    // }

    &__secondarynav {
        align-items: center;
        background-color: $secondary-nav-background-color;
        display: flex;
        height: 90px;
        justify-content: space-between;
        margin: 0 auto;
        max-width: $site-max-width;
        padding: 0 12px;

        @include for-tablet {
            display: none;
        }
    }

    &__secondarynav-header {
        color: $green01;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.19;

        &:hover {
            text-decoration: none;
        }
    }

    &__container_right {
        align-items: center;
        display: flex;
    }

    &__secondarynav-menu-bar {
        color: $green01;
        font-size: 37px;
        font-weight: 600;
        line-height: 1.19;
        margin-right: auto;
    }

    // Overwrite for modal styles
    &__modal {
        .msc-modal {
            z-index: 9991;
            left: 0;
            padding: 0;

            &__dialog {
                max-width: 100%;
            }

            &__content {
                padding: 0;
            }

            &__backdrop {
                z-index: 9990;
            }
        }
    }

    // Overwrite navigation scss
    .ms-nav {
        &__list {
            display: flex;
            list-style: none;
            padding: 0;
            outline: none;

            // Second level navigation nesting
            .ms-nav {
                &__list {
                    position: absolute;
                }
            }
        }
        
    
        &__list__item__button,
        &__list__item__link-top {
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: normal;
            line-height: 1.2;
            margin: 0 15px;
            outline: none;
            padding: 0;
            position: relative;
            text-align: center;
            text-transform: uppercase;

            &[aria-expanded="true"] {
                color: $green01;

                &:after {
                    width: 100%;
                }
            }

            &:hover {
                color: $green01;
            }

            @include underlineOnHover();
        }

        &__list__item__link-top {
            display: flex;
            margin-left: 0;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

// Overwrite search scss
.ms-search {
    position: relative;

    &__label,
    &__form-cancelSearch {
        display: none;
    }

    &__autoSuggest {
        background: $white01;
        border-radius: 0 0 5px 5px;
        position: relative;
        width: 100%;

        &:not(:empty) {
            border-top: 1px solid $white02;
        }

        @include for-desktop {
            border-radius: 5px;
            margin-top: 0px;
            position: absolute;
            width: 250px;

            &:not(:empty) {
                border: 1px solid $white02;
            }
        }

        li {
            padding: 2px 5px;

            &:not(:last-child) {
                border-bottom: 1px solid $white02;
            }
        }
    }

    picture {
        flex-basis: 40%;

        @include for-mobile {
            min-width: 20%;
            max-width: 20%;
        }

        @include for-desktop {
            min-width: 30%;
            max-width: 30%;
        }
    }

    &__searchForm {
        position: relative;
    }

    &__form-submitSearch {
        background: url($search-svg-url);
        background-size: cover;
        border: none;
        cursor: pointer;
        height: 36px;
        position: absolute;
        right: 5px;
        top: calc(50% - 18px);
        width: 36px;

        &:focus {
            outline: none;
        }
    }

    &__form-control {
        width: 250px;
        height: 39px;
        border-radius: 5.5px;
        border: solid 1px $white02;
        background-color: $white01;
        font-size: 14px;
        line-height: 1.21;
        padding: 0 40px 0 10px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-style: italic;
        }
    }
}

.msc-autoSuggest {
    &__screen-reader {
        display: none;
    }

    &__productResults-title,
    &__categoryResults-title {
        border-top: 1px solid $white02;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 5px !important;
        text-align: center;
    }

    &__productResults-item {
        align-items: center;
        display: flex;
        position: relative;
        width: 100%;
    }

    &__productResults-item-img {
        height: 50px;
        padding-right: 5px;
    }

    &__productResults-item-price {
        padding-left: 10px;
    }
}

// Overwrite wishlist scss
.msc-wishlist-icon {
    @include for-tablet {
        margin-right: 20%;
    }

    &__text {
        padding-left: 110px;
        color: $green01;
        font-weight: 700;

        @include for-desktop {
            display: none;
        }
    }
}

// Overwrite cart icon scss
.ms-cart-icon-wrapper {
    display: flex;
    align-items: center;
}

.ms-cart-icon {
    &__count {
        display: none;
    }
}

.msc-cart-icon {
    padding: 0 5px 0 0;

    &__count {
        @include for-tablet {
            color: $green01;

            &::before {
                font-weight: 700;
                content: "VIEW CART ";
            }
        }
    }
}

.ms-header-spacer {
    height: 165px;

    @include for-tablet {
        height: 75px;
    }
}

.RefreshPagesModal {
    padding: 20px 10px;
    position: relative;
    z-index: 3;

    @include for-desktop {
        padding: 30px;
    }

    &__header {
        color: $green01;
        font-size: 22px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 15px;
        text-align: center;
    }

    &__text {
        color: #01291e;
        font-size: 14px;
        text-align: center;

        @include for-desktop {
            padding: 0 20%;
        }
    }
}

@include for-desktop {
    .Header-container-secondary__search-icon {
        border: none;
        top: 1px;
        line-height: 45px !important;
    }
}
