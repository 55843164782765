$msv-store-selector-phone-section-padding-top: 5px;
$msv-dialog-width: 650px;
$msv-modal-element-margin-bottom: 30px;

.farmlands-pdp-store-locator .msc-modal__content {
  padding: 60px 80px 30px;

  &-input {
    flex-grow: 1;
    border: 1px solid $gray04;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    padding-left: 38px;

    &::placeholder {
      font-style: italic;
    }
  }

  &-location-icon {
    color: $gray04;
    font-size: 25px;
    left: 7px;
    position: absolute;
    top: 7px;
  }
}

.ms-store-select {
  color: $gray02;

  &__modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 80px 30px;

    @include for-tablet {
      padding: 40px 20px 20px;
    }
  }

  &__cancel,
  &__submit {
    border: 0;
    cursor: pointer;
    font-size: 13px;
    justify-content: center;
    min-height: 34px;
    padding: 9px 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;

    @include for-mobile {
      padding: 9px 30px;
    }
  }

  &__cancel {
    color: $green01;
    outline: 0;
  }

  &__submit {
    background-color: $orange01;
    border-radius: 20px;
    color: $white01;
    display: flex;

    &:disabled,
    &[disabled] {
      background-color: $gray05;
      color: $gray02;
      cursor: initial;
    }
  }

  &__found-locations {
    margin-bottom: 20px;
  }

  &__stores-header {
    color: $green01;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: $msv-modal-element-margin-bottom;
  }

  &__search-in-progress {
    border-top-color: $green01;
  }

  &__search {
    &-form {
      display: flex;
      margin-bottom: 30px;

      @include for-mobile {
        flex-direction: column;
        align-items: center;
      }
    }

    &-input-container {
      flex: auto;
      display: flex;
      height: 40px;
      position: relative;

    }

    &-input {
      flex-grow: 1;
      border: 1px solid $gray04;
      border-right: 0;
      border-radius: 5px 0 0 5px;
      padding-left: 38px;

      &::placeholder {
        font-style: italic;
      }

      @include for-sm-mobile {
        max-width: 200px;
        margin-right: 0px;
      }
    }

    &-location-icon {
      color: $gray04;
      font-size: 25px;
      left: 7px;
      position: absolute;
      top: 7px;
    }

    &-button {
      align-items: center;
      background-color: $white01;
      border: 1px solid $gray04;
      border-left: 0;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      display: flex;

      &:disabled,
      &[disabled] {
        cursor: initial;
      }

      @include for-sm-mobile {
        max-width: 40px;
        padding: 0px;
        margin-left:0;
      }

      &-icon {
        color: $gray04;
        font-size: 35px;
      }
    }

    &-submit-button {
      margin-left: 10px;
      padding: 9px 20px;

      @include for-mobile {
        margin-left: 0;
        margin-top: 10px;
        min-width: 150px;
      }
    }

    .search-btn-disabled {
      background-color: $gray05;
    }
  }

  &__divider {
    border-top: 1px solid $gray05;
    margin-bottom: 24px;
  }

  &__store-hours-details {
    color: $gray08;
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.27;
    margin-top: 5px;

    .ms-store-select__store-hours-day {
      flex: 0 0 40%;
    }

    .ms-store-select__store-hours-time {
      flex: 0 0 60%;
    }
  }

  &__locations {
    height: 38vh;
    max-height: 400px;
    overflow-y: scroll;
    @include scrollbarStyle;

    @include for-tablet {
      max-height: 280px;
    }
  }

  &__location {
    min-height: 90px;

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &-line-item {
      cursor: pointer;
      display: flex;
      width: 100%;
    }

    &-line-item-input {
      display: none;
    }

    &-line-item-radio-outer {
      align-items: center;
      border: 1px solid $green01;
      background-color: transparent;
      border-radius: 9px;
      display: flex;
      height: 18px;
      justify-content: center;
      min-width: 18px;
      margin-right: 10px;
      margin-top: 2px;
    }

    &-line-item-radio-inner {
      display: none;
      border-radius: 5px;
      background-color: $green01;
      height: 10px;
      width: 10px;
    }

    // Rule for 'toggling' visual radio element when actual adjacent input element is checked.
    &-line-item-input:checked + &-line-item-radio-outer > &-line-item-radio-inner {
      display: block;
    }

    &-line-item-store-name {
      font-size: 13px;
      font-weight: 600;
      color: $green01;
    }

    &-item-store-distance {
      color: $gray08;
      font-size: 11px;
      font-weight: 600;
      line-height: 1.27;
      width: 110px;
    }

    &-line-item-content {
      display: flex;
      flex-direction: column;
    }

    &-line-item-store-availability {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      padding-left: 25px;
      padding-right: 45px;

      @include for-tablet {
        padding-right: 10px;
      }

      @include for-mobile {
        padding-right: 0px;
      }
    }

    &-line-item-label {
      height: 26px;
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      padding-left: 5px;
      font-size: 13px;

      @include for-tablet {
        padding-right: 10px;
      }
    }

    &-line-item-hasStock {
      color: $green01;
    }

    &-line-item-OoS {
      color: $red02;
    }

    &-line-item-lowStock {
      color: $orange01;
    }

    &-line-distance-label,
    &-line-stock-status {
      color: $green01;
      font-size: 11px;
      font-weight: 600;
      position: relative;
    }

    &-line-stock-icon {
      color: $green01;
      position: absolute;
      left: -19px;
      font-size: 14px;
      top: 3px;
    }

    &-line-shop-details {
      font-size: 14px;
      color: $gray03;
      max-width: 220px;

      @include for-mobile {
        max-width: 150px;
      }
    }

    &-line-shop-address {
      &-section {
        color: $gray08;
        display: flex;
        font-size: 11px;
        font-weight: 600;
        line-height: 1.27;
      }
    }

    &-line-shop-phone {
      &-section {
        color: $gray08;
        display: flex;
        font-size: 11px;
        font-weight: 600;
        line-height: 1.27;
      }
    }
  }

  &__submit-spinner {
    border-top-color: $white01;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }
}

// Store Selector Modal overwrite
.msc-modal {
  &__dialog {
    max-width: $msv-dialog-width;
    width: 100%;
  }

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    color: $green01;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}

.farmlands-pdp-store-locator .ms-store-select__location-line-item {
  cursor: auto;
}

.farmlands-pdp-store-locator {
  .msc-modal__content {
    @include for-tablet {
      padding: 60px 10px 30px 20px;
    }
  }

  .ms-store-select__stores-header {
    @include for-tablet {
      font-size: 22px;
    }
  }

}
