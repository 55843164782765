// ---------------------------------------------------------------------------
// Font sizes
// ---------------------------------------------------------------------------

$rem1: 0.0625rem;
$rem2: 0.125rem;
$rem3: 0.1875rem;
$rem4: 0.25rem;
$rem5: 0.3125rem;
$rem6: 0.375rem;
$rem7: 0.4375rem;
$rem8: 0.5rem;
$rem9: 0.5625rem;
$rem10: 0.625rem;
$rem11: 0.6875rem;
$rem12: 0.75rem;
$rem13: 0.8125rem;
$rem14: 0.875rem;
$rem15: 0.9375rem;
$rem16: 1rem;
$rem17: 1.0625rem;
$rem18: 1.125rem;
$rem19: 1.1875rem;
$rem20: 1.25rem;
$rem21: 1.3125rem;
$rem22: 1.375rem;
$rem23: 1.4375rem;
$rem24: 1.5rem;
$rem25: 1.5625rem;
$rem26: 1.625rem;
$rem27: 1.6875rem;
$rem28: 1.75rem;
$rem29: 1.8125rem;
$rem30: 1.875rem;
$rem31: 1.9375rem;
$rem32: 2rem;
