$msv-btn-min-width: 160px;
$msv-btn-side-padding: 12px;
$msv-btn-height: 32px;
$msv-btn-border-radius: 2px;

.msc-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: $msv-btn-border-radius;
  font-family: $msv-primary-font-family;
  font-weight: $msv-font-weight-600;
  height: $msv-btn-height;
  padding: 0 $msv-btn-side-padding;
  text-decoration: none;
  
  &:not(:disabled) {
    cursor: pointer;
}

  &.msc-btn__wide {
    min-width: $msv-btn-min-width;
  }
}

button {
  background-color: $white01;
}