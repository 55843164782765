$msv-details-heading-margin-bottom: 20px;
$msv-details-text-margin-right: 5px;

$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-order-information-channel-name-margin-bottom: 12px;
$msv-details-order-information-channel-address-margin-bottom: 12px;

$msv-details-keep-shopping-btn-margin-bottom: 20px;

$msv-details-group-border: 1px solid $black01;
$msv-details-group-padding: 20px 0px;
$msv-details-group-pickup-border: 1px solid $gray05;
$msv-details-group-delivery-items-text-color: $gray03;
$msv-details-group-delivery-width: 340px;
$msv-details-group-delivery-address-padding-top: 10px;

$msv-details-sales-lines-width: calc(100% - 340px);
$msv-details-sales-status-left-desktop: 130px;
$msv-details-sales-status-left-mobile: 0px;
$msv-details-sales-status-top-desktop: -50px;
$msv-details-sales-status-top-mobile: -90px;
$msv-details-sale-line-padding: 20px 0;
$msv-details-sale-line-image-size: 147px;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;

$msv-order-empty-image-width: 147px;

$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-font-weight: 600;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;

$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-help-label-margin-right: 4px;
$msv-details-payment-methods-margin-top: 20px;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 4px;
$msv-details-payment-methods-line-margin-bottom: 8px;
$msv-details-payment-methods-loyalty-gift-card-amount-margin-left: 4px;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;

$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;

$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;

$msv-msc-cart-line-width: 100%;

.ms-order-details {
    color: $black01;
    margin-bottom: 250px;

    &__heading {
        @include font-content-xl();
        color: $green01;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: $msv-details-heading-margin-bottom;
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;

        &-channel-name {
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: $msv-font-weight-600;
            line-height: $msv-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
        }

        &-channel-address {
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: $msv-font-weight-600;
            line-height: $msv-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-address-margin-bottom;
            margin-top: -$msv-details-order-information-channel-name-margin-bottom;
        }

        &-channel-reference-id,
        &-receipt-email {
            display: block;
            font-weight: 600;
        }

        &-sales-id {
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid $black01;
        }

        &-created-date,
        &-count {
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid $black01;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;
        @include secondary-button();
    }

    &__sales-lines {
        border-top: 1px solid $gray04;
        flex: 3;
        margin-top: 20px;

        &:first-child {
            border-top: none;
        }
    }

    &__groups {
        border-bottom: 1px solid $gray04;
    }

    &__group {
        display: flex;
        flex-wrap: wrap;

        &-delivery {
            flex: 1;

            &-heading {
                font-size: $msv-heading-text-size;
                line-height: $msv-heading-line-height;
                font-weight: $msv-font-weight-600;
                margin-right: $msv-details-text-margin-right;
            }

            &-total-items {
                @include font-content-l();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }

        &:first-child {
            .ms-order-details__sales-lines,
            .ms-order-details__address {
                border-top: none;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__tracking-info {
        display: block;
    }

    &__sales-line {
        padding: $msv-details-sale-line-padding;
        position: relative;

        .msc-cart-line {
            width: $msv-msc-cart-line-width;

            &__product {
                flex: none;
            }

            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }

            &__content-wrapper {
                padding: 0;
            }

            &__product-title {
                font-size: $msv-text-size;
                line-height: $msv-text-line-height;
            }

            &__quantity {
                bottom: 0;
                color: $gray08;
                left: 0;
                margin-left: 0;

                &-label {
                    display: none;
                }

                .quantity-label {
                    font-size: $msv-text-size;
                    line-height: $msv-text-line-height;
                    font-weight: 600;
                }

                .quantity-value {
                    margin-left: $msv-details-text-margin-right;
                    display: inline;
                    color: $gray03;
                }
            }

            &__product-price {
                right: 0;
                bottom: 0;
                width: unset;
                text-align: unset;
                margin-left: auto;

                .msc-price__strikethrough,
                .msc-price__actual {
                    font-size: $msv-text-size;
                    line-height: $msv-text-line-height;
                }
            }
        }

        &-btn-buy-it-again {
            @include primary-button($orange01, $white01, $orange01);
            border-radius: 17px;
            bottom: 20px;
            font-size: 13px;
            position: absolute;
            right: 0;
            text-transform: uppercase;
            width: $msv-details-sale-line-buy-again-btn-width;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__order-summary {
        float: right;
        width: $msv-details-footer-block-width;
        margin-top: $msv-details-order-summary-spacing;

        &-heading {
            color: $green01;
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-order-summary-heading-margin-bottom;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
        }

        &-line-total-amount {
            border-top: 1px solid $gray04;
            padding-top: $msv-details-order-summary-line-spacing;
            margin-bottom: $msv-details-order-summary-line-spacing;
            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-600;
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-600;
            }
        }

        &-price {
            color: $orange01;
            float: right;
            font-weight: 600;
        }
    }

    &__help {
        float: left;
        margin-top: $msv-details-help-margin-top;

        &-label {
            margin-right: $msv-details-help-label-margin-right;
        }

        &-title {
            color: $green01;
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            text-decoration: underline;
        }
    }

    &__payment-methods {
        float: left;
        width: $msv-details-footer-block-width;
        margin-top: $msv-details-payment-methods-margin-top;
        margin-right: $msv-details-payment-methods-margin-right;

        &-title {
            color: $green01;
            font-weight: $msv-font-weight-600;
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-margin-bottom;
        }

        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: $msv-details-payment-methods-loyalty-gift-card-amount-margin-left;
        }
    }

    &__address {
        padding-top: $msv-details-group-delivery-address-padding-top;
        flex: 1;

        &-header {
            font-weight: bold;
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @include for-tablet {
        margin-bottom: 0;

        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;
        }

        &__sales-status {
            float: right;
            left: $msv-details-sales-status-left-mobile;
            top: $msv-details-sales-status-top-mobile;
        }

        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            width: 100%;
            padding-right: 0;
        }

        &__order-summary {
            margin-top: 40px;
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;
        }

        &__sales-line-btn-buy-it-again {
            bottom: 0;
            margin-top: 40px;
            position: relative;
        }
    }
}
