.farmlands-promotion-block {
    display: flex;
    background-color: $white03;
    width: 100%;
    padding: 18px 35px;

    a {
        color: $white01;
        cursor: pointer;

        &:hover {
            color: $orange01;
        }
    }

    &__info-wrapper {
        display: flex;
    }

    &__image-container {
        margin-right: 20px;
        align-self: center;
        margin-bottom: 5px;
        max-width: 36px;
        max-height: 36px;
    }

    &__image {
        max-height: 100%;
        max-width: 100%;
        height: auto;
    }

    &__title {
        color: $green01;
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
    }

    &__text {
        color: $gray08;
        font-size: 11px;
        font-weight: 600;
    }

    &:not(:first-child) {
        margin-top: 5px;
    }

    // Styling for "Green" module layout configuration in sitebuilder
    &[data-m-layout="green"] {
        background-color: $green01;
        justify-content: space-between;

        @include for-tablet {
            flex-direction: column;
        }

        & .farmlands-promotion-block {
            &__title {
                color: $white01;
                font-size: 14px;
                text-transform: uppercase;
            }

            &__details {
                width: 100%;
            }

            &__text {
                color: $white01;
                font-weight: 400;

                ul {
                    padding-inline-start: 15px;
                    margin-block: 1em 0;
                }
            }

            &__buttons {
                min-width: 180px;

                @include for-tablet {
                    width: 100%;
                    padding-top: 10px;
                }
            }

            &__button {
                display: flex;
                justify-content: center;

                &--primary {
                    background-color: $orange01;
                    border-radius: 21px;
                    height: 34px;
                    text-transform: uppercase;
                    font-size: 13px;
                    align-items: center;
                    font-weight: 600;
                }

                &--secondary {
                    margin-top: 10px;

                    a {
                        font-size: 12px;
                        text-decoration: underline;
                    }
                }

                a {
                    color: $white01;
                }
            }
        }
    }
}
