$carousel-control-width: 36px;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;
$carousel-indicator-width: 12px;
$carousel-indicator-height: 12px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 6px;
$carousel-indicator-transition: opacity 0.6s ease;
$carousel-indicator-margin-left-right: 6px;
$carousel-caption-width: 70%;
$carousel-control-icon-size: 21px;
$carousel-control-icon-width: 20px;
$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-transition-base: all 0.2s ease-in-out;
$carousel-transition-fade: opacity 0.15s linear;
$carousel-transition-collapse: height 0.35s ease;
$carousel-enable-transitions: true;
$carousel-enable-prefers-reduced-motion-media-query: true;
$msv-carousel-indicator-color: $white01;
$msv-carousel-indicator-fill-color: $white01;
$msv-carousel-flipper-color: $white01;
$msv-carousel-flipper-hover-color: $black01;

@mixin carousel-transition($transition...) {
	@if $carousel-enable-transitions {
		@if length($transition) == 0 {
			transition: $carousel-transition-base;
		} @else {
			transition: $transition;
		}
	}

	@if $carousel-enable-prefers-reduced-motion-media-query {
		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
	}
}

.screen-reader-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.msc-carousel {
	position: relative;
	align-items: center;
	display: flex;

	.pointer-event {
		touch-action: pan-y;
	}

	.msc_image {
		height: auto;

		&--desktop {
			display: flex;
			@include for-mobile {
				display: none;
			}
		}

		&--mobile {
			display: none;
			@include for-mobile {
				display: flex;
			}
		}
	}

	.farmlands-carousel-content-block {
		display: flex;
		flex-direction: column;

		&__title {
			font-size: 48px;
			line-height: 4rem;
			@include for-tablet {
				font-size: 20px;
			}
		}

		&__text {
			font-size: 20px;
			@include for-tablet {
				font-size: 15px;
			}
		}

		&__details {
			&--desktop {
				flex-direction: column;
				position: absolute;
				top: 0;
				height: 100%;
				padding: 0 120px;
				justify-content: center;
				display: flex;

				@include for-mobile {
					display: none;
				}

				h1 {
					font-size: $rem28;
					line-height: $rem32;
					margin-bottom: 32px;

					@include for-tablet {
						font-size: $rem32;
						line-height: $rem20*2;
						margin-bottom: 40px;
					}
				}

				h2 {
					font-size: $rem24;
					line-height: $rem26;
					margin-bottom: 26px;

					@include for-tablet {
						line-height: $rem32;
						margin-bottom: 32px;
					}
				}

				h3 {
					font-size: $rem20;
					line-height: $rem24;
					margin-bottom: 24px;
				}

				h4, h5, h6 {
					font-size: $rem18;
					line-height: $rem22;
					margin-bottom: 22px;
				}
			}

			&--mobile {
				flex-direction: column;
				padding: 30px 40px 40px;
				text-align: center;
				align-items: center;
				display: none;
				@include for-mobile {
					display: flex;
				}
			}

			.msc-cta__primary {
				background-color: $orange01;
				border: none;
				color: $white01;
				border-radius: 20px;
				display: flex;
				height: auto;
				width: fit-content;
				padding-right: 24px;
				padding-left: 24px;
				padding: 8px 36px;
				text-decoration: initial;
				margin-top: 32px;
			}
		}
	}

	&__dismiss {
		background: $black01;
		border-color: $black01;
		color: $white01;
		position: absolute;
		top: 0;
		right: 0;
		&:before {
			@include msv-icon();
			content: $msv-Cancel;
			font-size: 20px;
			position: unset;
			text-align: center;
			vertical-align: text-bottom;
			visibility: visible;
		}
	}

	&__inner {
		position: relative;
		width: 100%;
		overflow: hidden;
		@include clearfix();
	}

	&__item {
		height: 100%;
		position: relative;
		display: none;
		float: left;
		width: 100%;
		margin-right: -100%;
		backface-visibility: hidden;
		@include carousel-transition($carousel-transition);
	}

	&__item {
		&.active,
		&__next,
		&__prev {
			display: block;
			text-decoration: none;
		}
	}

	&.carousel-fade {
		&__item {
			opacity: 0;
			transition-property: opacity;
			transform: none;
		}

		.msc-carousel__item.active,
		.msc-carousel__item__next.msc-carousel__item__left,
		.msc-carousel__item__prev.msc-carousel__item__right {
			z-index: 1;
			opacity: 1;
		}

		.active.msc-carousel__item__left,
		.active.msc-carousel__item__right {
			z-index: 0;
			opacity: 0;
			@include carousel-transition(0s $carousel-transition-duration opacity);
		}
	}

	&__control__prev,
	&__control__next {
		color: $msv-carousel-flipper-color;
		width: $carousel-control-width;
		height: $carousel-control-width;
		top: calc(50% - 24px);
		background-color: $orange01;
		border-radius: 30px;
		transition: none;
		position: absolute;
		bottom: 0;
		z-index: 16;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		opacity: $carousel-control-opacity;
		@include carousel-transition($carousel-control-transition);

		// Hover/focus state
		@include hover-focus {
			text-decoration: none;
			outline: 1px dashed $black01;
			opacity: $carousel-control-hover-opacity;
			&::before {
				outline: 1px dashed $msv-carousel-flipper-hover-color;
			}
			&__icon {
				outline: 0;
				&::before {
					outline: 0;
				}
			}
			.msc-carousel__control__prev__icon,
			.msc-carousel__control__next__icon {
				color: $msv-carousel-flipper-hover-color;
			}
		}

		&::before {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: "";
			outline-offset: 1px;
		}
	}

	&__control__prev {
		left: 0;
		justify-content: flex-start;
		margin-left: 5px;

		&__icon {
			@include add-icon($msv-ChevronLeft);
			margin-left: 6px;
		}
	}

	&__control__next {
		justify-content: flex-end;
		margin-right: 5px;
		right: 0;

		&__icon {
			@include add-icon($msv-ChevronRight);
			margin-right: 6px;
		}
	}

	&__caption {
		color: $msv-carousel-indicator-color;
		position: absolute;
		right: (100% - $carousel-caption-width) / 2;
		bottom: 20px;
		left: (100% - $carousel-caption-width) / 2;
		z-index: 10;
		padding-top: 20px;
		padding-bottom: 20px;
		text-align: center;
	}

	&__indicators {
		position: absolute;
		right: 0;
		bottom: 1rem;
		left: 0;
		z-index: 15;
		display: flex;
		justify-content: center;
		padding-left: 0; // override <ol> default
		// Use the .carousel-control's width as margin so we don't overlay those
		margin-right: $carousel-indicator-margin-left-right;
		margin-left: $carousel-indicator-margin-left-right;
		list-style: none;

		li {
			@include carousel-transition($carousel-indicator-transition);
			@include vfi-psuedo(after, 3px);
			border-radius: 50%;
			border: 1px solid $msv-carousel-indicator-color;
			background: transparent;
			opacity: 1;
			box-sizing: content-box;
			flex: 0 1 auto;
			width: $carousel-indicator-width;
			height: $carousel-indicator-height;
			margin-right: $carousel-indicator-spacer;
			margin-left: $carousel-indicator-spacer;
			text-indent: -999px;
			cursor: pointer;
			background-clip: padding-box;
		}

		.active {
			background: $msv-carousel-indicator-fill-color;
			opacity: 1;
		}
	}

	&__control__prev__icon,
	&__control__next__icon {
		display: inline-block;
		width: $carousel-control-icon-width;
		font-size: $carousel-control-icon-size;
		background: no-repeat 50% / 100% 100%;
	}
}

.msc-carousel__item__next:not(.msc-carousel__item__left),
.active.msc-carousel__item__right {
	transform: translateX(100%);
}

.msc-carousel__item__prev:not(.msc-carousel__item__right),
.active.msc-carousel__item__left {
	transform: translateX(-100%);
}
