$msv-flyout-msc-cart-line-product-image-size: 120px;
$msv-flyout-msc-cart-line-product-empty-image-size: 80px;
$msv-flyout-padding: 20px;
$msv-flyout-cart-icon-subtotal-padding: 20px 0;
$msv-flyout-cart-icon-btn-checkout-margin-right: 10px;
$mini-cart-width: 400px;
$subtotal-border: 1px solid $gray10;
$msv-flyout-empty-img-border: 1px solid $gray10;

.ms-cart-icon {
    &__flyout-container {
        background: $white01;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        display: block;
        font-family: $msv-primary-font-family;
        margin: 0;
        opacity: 0;
        position: absolute;
        width: $mini-cart-width;
        z-index: 1060;

        &.show {
            opacity: 1;
            padding: $msv-flyout-padding;
        }
    }

    &__flyout-title {
        color: $green01;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
    }

    &__cart-lines {
        max-height: 380px;
        overflow: auto;
        margin-right: -15px;
        margin-top: 10px;
        padding-right: 15px;
        @include scrollbarStyle;
    }

    &__subtotal {
        border-top: $subtotal-border;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 10px;
        padding: $msv-flyout-cart-icon-subtotal-padding;
        text-align: right;
    }

    &__btn-checkout {
        @include primary-button($orange01, $white01, $orange01);
        margin-bottom: 20px;
    }

    &__btn-gotocart {
        @include secondary-button($white01, $green01, $green01);
    }

    &__btn-checkout,
    &__btn-gotocart {
        border-radius: 16px;
        font-size: 13px;
        text-transform: uppercase;
        width: 100%;

        &:hover {
            text-decoration: none;
        }
    }

    // Overwriting compact styles for cart items
    .msc-cart-line {
        &__content-wrapper {
            padding: 15px 0 15px 5px;
        }

        &__product-image {
            border: 0;
            height: 70px;
            min-width: 70px;
            width: 70px;

            img {
                height: 68px;
                width: 68px;
            }
        }
    }

    .shareholder-warning {
        margin-bottom: 20px;
    }
}
